import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import Tooltip from "./Tooltip";

import { RiMenuFill } from "react-icons/ri/";
import { MdEdit } from "react-icons/md";

import { Link } from "react-router-dom";

import {
  requestWholeSalers,
  getVersions,
  setDraftVersion,
} from "../helpers/requests";
import { insertPopup } from "../helpers/functions";

import { SET_COMMON_PROP } from "../actions/CommonActions";
import { SET_VERSION, SET_REGISTRY_VERSIONS } from "../actions/VersionsActions";

import { connect } from "react-redux";

const canEdit = true; //process.env.NODE_ENV === "development";

const tabsStyles = {
  minHeight: 36,
  maxHeight: 36,
  height: 36,
  color: "#fff",
};

const initialRequesting = { loading: null, params: null };

const overlayId = "header-overlay";

/**
 * Header navigation component with registryVersion select menu.
 *
 * @component
 * @param {Object} props props for the component
 * @param {Object} props.registryVersion - selected registryVersion
 * @param {Object} props.registryVersions - all versions
 * @param {Object} props.allTabs - routes list
 * @param {Object} props.tabValue - value for mui tabs
 * @param {Object} props.SET_VERSION - redux action for setting selected registryVersion
 * @param {Object} props.SET_REGISTRY_VERSIONS - redux action to setting versions
 * @returns JSX
 */
function Header({
  registryVersion,
  registryVersions,
  allTabs,
  tabValue,
  SET_COMMON_PROP,
  SET_VERSION,
  SET_REGISTRY_VERSIONS,
}) {
  const { t } = useTranslation();
  const [requesting, setRequesting] = useState(initialRequesting);

  const closePopover = (elId, overlayId) => {
    const el = document.getElementById(elId);
    const overlay = document.getElementById(overlayId);
    el &&
      Object.assign(el.style, {
        visibility: "hidden",
      });
    overlay &&
      Object.assign(overlay.style, {
        display: "none",
      });
  };

  const closePopovers = () => {
    closePopover("version-menu", overlayId);
    closePopover("nav-menu", overlayId);
  };

  const onMenuMouseOver = (elId, elIdToClose) => () => {
    closePopover(elIdToClose);
    insertPopup("bottom", elId + "-btn", elId, elId + "-arrow", overlayId);
  };

  useEffect(() => {
    getVersions(SET_REGISTRY_VERSIONS, undefined, SET_VERSION, t);
    requestWholeSalers(SET_COMMON_PROP);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _setDraftVersion = async (versionId, callback) => {
    const draftCreated = await setDraftVersion(versionId, t);
    setRequesting(initialRequesting);

    if (draftCreated) {
      SET_VERSION({ registryVersion: versionId, editing: versionId });
    }
  };

  useEffect(() => {
    if (requesting.loading === "draft") {
      _setDraftVersion(requesting.params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requesting]);

  const onVersionSelect = (versionKey, edit) => () => {
    closePopovers();
    if (edit) {
      setRequesting({ loading: "draft", params: versionKey });
    } else {
      SET_VERSION({ registryVersion: versionKey });
    }
  };

  const editingCurrentVersion =
    typeof registryVersion === "string" && registryVersion.includes("draft");
  const versionNum =
    typeof registryVersion === "string" ? registryVersion.split("_")[0] : "";
  return (
    <>
      <AppBar position="fixed">
        <Toolbar className="header">
          <Tabs
            sx={tabsStyles}
            value={tabValue}
            variant="scrollable"
            scrollButtons="auto"
            // textColor="secondary"
            // indicatorColor="secondary"
          >
            {allTabs
              .sort((a, b) =>
                t(a).localeCompare(t(b), undefined, {
                  numeric: true,
                  sensitivity: "base",
                })
              )
              .map((x) => (
                <Tab
                  key={x}
                  sx={tabsStyles}
                  label={t(x)}
                  value={x}
                  component={Link}
                  to={x}
                />
              ))}
          </Tabs>

          <div
            id={overlayId}
            className="overlay transparent"
            onClick={closePopovers}
          />

          <Stack direction="row" alignItems="center">
            <div
              id="version-menu-btn"
              // onMouseOver={onMenuMouseOver("version-menu", "nav-menu")}
              onClick={onMenuMouseOver("version-menu", "nav-menu")}
              className="version-menu-btn"
            >
              <Stack spacing={1} direction="row" alignItems="center">
                <Typography variant="body">
                  {t("version") + " " + versionNum}
                </Typography>
                {editingCurrentVersion && <MdEdit />}
              </Stack>
            </div>
            <div
              id="version-menu"
              className="popover navigation-content-body version-menu"
            >
              <div id="version-menu-arrow" className="arrow"></div>
              {registryVersions?.versions ? (
                <Stack spacing={1}>
                  {Object.keys(registryVersions.versions)
                    .reverse()
                    .map((versionKey) =>
                      versionKey.includes("_") ? null : (
                        <Stack
                          key={"VersionSelect" + versionKey}
                          direction="row"
                          alignItems="center"
                          spacing={1}
                        >
                          <Tooltip
                            tip={`${t("browse")}: v. ${versionKey}\n${
                              registryVersions.versions[versionKey].nodeText
                                .comment ?? ""
                            }`}
                          >
                            <div
                              className="navigation-item version-btn"
                              onClick={onVersionSelect(versionKey, false)}
                            >
                              {"v." + versionKey}
                            </div>
                          </Tooltip>
                          {canEdit && (
                            <Tooltip
                              tip={`${t("edit")}: v. ${versionKey}\n${
                                registryVersions.versions[versionKey].nodeText
                                  .comment ?? ""
                              }`}
                            >
                              <div
                                className="navigation-item version-btn"
                                onClick={onVersionSelect(
                                  versionKey + "_draft",
                                  true
                                )}
                              >
                                <MdEdit className="font-size-21" />
                              </div>
                            </Tooltip>
                          )}
                        </Stack>
                      )
                    )}
                </Stack>
              ) : null}
            </div>

            <div className="text-padding-8" />

            <div
              id="nav-menu-btn"
              // onMouseOver={onMenuMouseOver("nav-menu", "version-menu")}
              onClick={onMenuMouseOver("nav-menu", "version-menu")}
              className="navigation-menu-btn"
            >
              <RiMenuFill className="font-size-21" />
            </div>
            <div
              id="nav-menu"
              className="popover navigation-content-body nav-menu"
            >
              <div id="nav-menu-arrow" className="arrow" />
              <Stack spacing={1}>
                {allTabs.map((option, i) => (
                  <Link
                    key={option}
                    to={option}
                    className="navigation-item"
                    onClick={closePopovers}
                  >
                    {t(option)}
                  </Link>
                ))}
              </Stack>
            </div>
          </Stack>
        </Toolbar>
      </AppBar>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    wholeSalers: state.common.wholeSalers,
    registryVersion: localStorage.getItem("registryVersion"),
    registryVersions: state.versions.registryVersions,
  };
};

export default connect(mapStateToProps, {
  SET_COMMON_PROP,
  SET_VERSION,
  SET_REGISTRY_VERSIONS,
})(Header);
