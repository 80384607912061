import React, {
  useCallback,
  useMemo,
  useRef,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import orderBy from "lodash.orderby";
import scrollbarSize from "dom-helpers/scrollbarSize";
import useScrollOnEdges from "../helpers/useScrollOnEdges";

import { MultiGrid } from "react-virtualized";

import ClipLoader from "react-spinners/ClipLoader";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";

import { MdSave } from "react-icons/md";
import {
  RiInsertColumnLeft,
  RiInsertColumnRight,
  RiDeleteColumn,
  RiInsertRowBottom,
  RiInsertRowTop,
  RiDeleteRow,
} from "react-icons/ri";

import EditTreeControls from "./EditTreeControls";

import useRequesting from "../helpers/useRequesting";
import { requestNodes } from "../helpers/requests";
import { request } from "../helpers/api";
import { insertPopup } from "../helpers/functions";

import { connect } from "react-redux";
import {
  SET_TABLE_CELLS,
  ADD_CELLS,
  REPLACE_CELL,
  SET_TABLE,
  REPLACE_TABLE,
  ADD_ROW,
  ADD_COLUMN,
  DELETE_ROW,
  DELETE_COLUMN,
} from "../actions/InputActions";
import { SET_NODES } from "../actions/ItemsActions";
import { UPDATE_NODE, CLEAR_TREE, REPLACE_TREE } from "../actions/TreeActions";
import { toast } from "react-toastify";

const initialRowCount = 501;
const initialColumnCount = 26;
const rowHeight = 17;
const overlayId = "table-overlay";

const getColName = (_) =>
  _ < 0 ? "" : getColName(_ / 26 - 1) + String.fromCharCode((_ % 26) + 65);

const getRowCount = (editable, values) => {
  if (editable) {
    return values.rowCount >= initialRowCount
      ? values.rowCount + 10
      : initialRowCount;
  } else {
    return values.rowCount;
  }
};

function CellRenderer(props) {
  const {
    searchNode,
    clickedSearchNode,
    rows,
    columnIndex,
    rowIndex,
    style,
    tableRowCount,
    tableColCount,
    selectedCells,
    copiedCells,
  } = props;
  const colNum = columnIndex;
  const rowNum = rowIndex;
  const cellData = rows[rowNum - 1][columnIndex];
  const id = `${rowNum}_${colNum}`;

  return (
    <span
      id={id}
      className={
        "noselect grid-input grid-cell" +
        (cellData?.value
          ? cellData?.id
            ? " product-cell"
            : " missing-id"
          : "") +
        (rowNum > tableRowCount || colNum > tableColCount
          ? " overflow-cell"
          : "") +
        (searchNode === cellData?.id || clickedSearchNode === cellData?.id
          ? " highlighted-cell"
          : "") +
        (selectedCells.current.has(id) ? " selected-cell" : "") +
        (copiedCells.current.has(id) ? " copied-cell" : "")
      }
      style={style}
      onDoubleClick={props.onDoubleClick(rowNum, colNum, cellData)}
      onMouseDown={props.onMouseDown(rowNum, colNum, cellData)}
    >
      {cellData?.value ?? cellData?.nodeText?.name ?? ""}
    </span>
  );
}

function TableHeaderRenderer(props) {
  const { editable, style, tableColCount } = props;
  const colNum = props.columnIndex - 1;
  const isRealCol = editable && colNum < tableColCount;
  return (
    <div
      id={"Col" + props.columnIndex}
      className={
        "indicator-cell grid-cell noselect" + (isRealCol ? "" : " overflow-td")
      }
      style={style}
      onClick={isRealCol ? props.handleColumnSelect(colNum) : undefined}
      onContextMenu={isRealCol ? props.handleColMenuOpen(colNum) : undefined}
    >
      <span>{getColName(colNum)}</span>
      {props.columnIndex !== 0 ? (
        <div
          className="resizer"
          onMouseDown={props.handleColResizerMouseDown(colNum + 1)}
        />
      ) : null}
    </div>
  );
}

function RowIndicator(props) {
  const { editable, rowIndex, style, tableRowCount } = props;
  const rowNum = rowIndex;
  const isRealRow = editable && rowNum < tableRowCount + 1;
  return (
    <div
      id={"Row" + rowNum}
      className="indicator-cell grid-cell noselect"
      style={style}
      onClick={isRealRow ? props.handleRowSelect(rowNum) : undefined}
      onContextMenu={isRealRow ? props.handleRowMenuOpen(rowNum) : undefined}
    >
      {rowIndex}
    </div>
  );
}

// TODO handle version compare
// function getCellDiff(_old, _new) {
//   if (
//     _old.type !== _new.type ||
//     _old.value !== _new.value ||
//     _old.nodeType !== _new.nodeType
//   ) {
//     return "changed";
//   } else {
//     return null;
//   }
// }

function TableContentEditMenu(props) {
  return (
    <div
      className="navigation-item"
      onClick={props.disabled ? null : props.onClick}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
      >
        <Typography variant="button">{props.title}</Typography>
        {props.loading ? (
          <ClipLoader size={14} speedMultiplier={0.6} />
        ) : (
          props.icon
        )}
      </Stack>
    </div>
  );
}

const parseColLen = (colLen) => colLen.split(",").map((x) => parseInt(x));

const initialActions = { index: -1, latestState: null, data: [] };
/**
 * TableComponent
 * @param {*} props
 * @returns
 */
function TableComponent(props) {
  const editable =
    !props.disableEditing &&
    typeof props.registryVersion === "string" &&
    props.registryVersion.includes("draft");
  const { t } = useTranslation();

  let location = useLocation();
  let searchParams = new URLSearchParams(location.search);
  const searchNode = searchParams.get("node");
  const clickedSearchNode = searchParams.get("clicked");

  const actions = useRef(initialActions);
  const startRowIndex = useRef(null);
  const startCellIndex = useRef(null);
  const [canEdgeScroll, setCanEdgeScroll] = useState(false);
  const isMouseDown = useRef(false);
  const colResizer = useRef({ el: null, x: 0 });
  const [contentToEdit, setContentToEdit] = useState({
    anchor: null,
    type: "row",
  });
  const [closing, setClosing] = useState(false);
  const [rows, setRows] = useState([]);
  const mainGrid = useRef(null);
  const selectedCells = useRef(new Set());
  const copiedCells = useRef(new Set());
  const moveEvent = useRef({ type: "" });
  const inputRef = useRef(null);
  const clickedCell = useRef(null);
  const cellToModify = useRef(null);
  const scrollValues = useRef({
    scrollTop: 0,
    scrollLeft: 0,
  });
  const cellToSelectAfterScroll = useRef();
  const getEdgeScrollingProps = useScrollOnEdges({
    canAnimate: canEdgeScroll, // (boolean) can scroll?
    scrollSpeed: 5, // (number)
    edgeSize: 46, // (number) offset size from edges to start scroll animation
  });
  const isAllowedToMove = useRef(false);
  const oldEditCellValue = useRef("");
  const upToDateCells = useRef();
  const upToDateTable = useRef();

  const values = props.values;
  const rowCount = rows.length;

  useEffect(() => {
    upToDateCells.current = props.cells;
  }, [props.cells]);

  useEffect(() => {
    upToDateTable.current = props.values;
  }, [props.values]);

  // TODO add limit for actions
  const pushToActions = useCallback(
    (payload) => {
      actions.current.registryVersion = props.registryVersion;
      actions.current.index = actions.current.data.length;
      actions.current.data.push({
        action: JSON.stringify(payload),
        prevTable: JSON.stringify({
          ...upToDateTable.current,
          cells: upToDateCells.current,
        }),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.registryVersion, actions]
  );

  const undo = () => {
    if (actions.current.index > -1) {
      if (actions.current.index === actions.current.data.length - 1) {
        actions.current.latestState = JSON.stringify({
          ...upToDateTable.current,
          cells: upToDateCells.current,
        });
      }
      props.REPLACE_TABLE({
        table: JSON.parse(
          actions.current.data[actions.current.index].prevTable
        ),
        registryVersion: actions.current.registryVersion,
      });
      actions.current.index = actions.current.index - 1;
    }
  };

  const redo = () => {
    if (actions.current.index !== actions.current.data.length - 1) {
      if (actions.current.index === actions.current.data.length - 2) {
        props.REPLACE_TABLE({
          table: JSON.parse(actions.current.latestState),
          registryVersion: actions.current.registryVersion,
        });
        actions.current.latestState = null;
        actions.current.index = actions.current.index + 1;
      } else {
        props.REPLACE_TABLE({
          table: JSON.parse(
            actions.current.data[actions.current.index + 2].prevTable
          ),
          registryVersion: actions.current.registryVersion,
        });
        actions.current.index = actions.current.index + 1;
      }
    }
  };

  const isActiveTab = () =>
    props.tabModel?.current.getActiveTabset()?.getSelectedNode()?.getId() ===
    props.tabId;

  const [requesting, requestParams, setRequesting] = useRequesting({
    onRequest: {
      TABLE_EDIT: (params, prop, onDone) => {
        const _body = {
          ...params.body,
          tableId: props.nodeId,
        };
        request(params.url, params.method, _body, {
          registryVersion: props.registryVersion,
        })
          .then((res) => {
            if (res.status === 200 || res.status === 204) {
              const action =
                params.action === "UPDATE_NODE" || params.action === "SET_TABLE"
                  ? params.action
                  : "REPLACE_TABLE";
              const payload = {
                ...params.body,
                table: res.data,
                root: props.reduxRoot,
                tableId: props.nodeId,
                registryVersion: props.registryVersion,
              };
              pushToActions({ type: action, payload });
              props[action](payload);
              if (params.callback) params.callback(res.data);
            }
          })
          .finally(() => {
            closePopovers();
            onDone();
          });
      },
    },
  });

  useEffect(() => {
    // fetch newest node
    if (props.nodeId && props.registryVersion) {
      cleanUpClipboard(true);
      actions.current = initialActions;

      requestNodes([props.nodeId], props.registryVersion).then((res) => {
        if (res.status >= 200 && res.status < 300) {
          props.SET_TABLE({
            table: res.data[0],
            registryVersion: props.registryVersion,
          });
          request("nodes/cells", "get", null, {
            registryVersion: props.registryVersion,
            id: res.data[0].id,
          }).then((cellsRes) => {
            if (cellsRes.status === 200) {
              props.SET_TABLE_CELLS({
                cells: Array.isArray(cellsRes.data.cells)
                  ? cellsRes.data.cells
                  : [],
                tableId: res.data[0].id,
                registryVersion: props.registryVersion,
              });
            } else {
              props.resetTab(props.tabId);
            }
          });
        } else {
          toast.error(
            `${t("failedToFetch")}: v.${
              typeof props.registryVersion === "string"
                ? props.registryVersion.split("_")[0]
                : ""
            } ${props.nodeId}`
          );
          props.resetTab(props.tabId);
        }
      });
    } else if (props.values) {
      props.SET_TABLE({
        table: props.values,
        registryVersion: props.registryVersion,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.nodeId, props.registryVersion]);

  const saveCells = () => {
    setRequesting({
      loading: "TABLE_EDIT",
      params: {
        url: "nodes/addCells",
        method: "PUT",
        action: "REPLACE_TABLE",
        body: {
          cells: props.cells.reduce((acc, _cell) => {
            let val = _cell.value ?? _cell.nodeText?.name ?? null;
            val = typeof val === "string" ? val.trim() : val;
            if (val) {
              acc.push({
                row: _cell.row,
                col: _cell.col,
                missing: _cell.missing,
                value: val,
              });
            }
            return acc;
          }, []),
        },
      },
    });
  };

  const getCellsWithIdSet = (set) => {
    let _cells = [];
    // set has id string e.g. `${rowNum}_${colNum}`
    set.forEach((x) => {
      const splitId = x.split("_");
      const el = document.getElementById(x);
      if (el) {
        _cells.push({
          cellEl: document.getElementById(x),
          rowNum: parseInt(splitId[0]),
          colNum: parseInt(splitId[1]),
        });
      }
    });
    return _cells;
  };

  const removeFromElArrClassList = (arr, classNames) => {
    arr.forEach((x) => {
      x.cellEl.classList.remove(...classNames);
    });
  };

  const addToElArrClassList = (arr, classNames) => {
    arr.forEach((x) => {
      x.cellEl.classList.add(...classNames);
    });
  };

  const cleanUpClipboard = (clearClipboardData) => {
    // cleanup
    let _selectedCells = getCellsWithIdSet(selectedCells.current);
    let _copiedCells = getCellsWithIdSet(copiedCells.current);

    removeFromElArrClassList(_selectedCells, ["copied-cell", "selected-cell"]);
    removeFromElArrClassList(_copiedCells, ["copied-cell", "selected-cell"]);
    selectedCells.current.clear();
    copiedCells.current.clear();
    moveEvent.current = { type: "" };
    // clear clipboard by adding empty string in it
    if (clearClipboardData && navigator.clipboard) {
      navigator.clipboard.writeText("");
    }
  };

  const handlePaste = (event) => {
    if (isActiveTab()) {
      if (!editable) toast.info("Version is not editable");
      else {
        event.preventDefault();
        let paste = (event.clipboardData || window.clipboardData).getData(
          "text"
        );
        // handle paste into table
        let _selectedCells = getCellsWithIdSet(selectedCells.current);

        if (_selectedCells.length > 0) {
          // handle excel adding \r\n always to the end of string
          if (paste.endsWith("\r\n")) {
            paste = paste.substring(0, paste.length - 2);
          }
          paste = paste.replace(/(?:\\[rn]|[\r\n]+)+/, "\n");

          _selectedCells = orderBy(
            _selectedCells,
            ["rowNum", "colNum"],
            ["asc", "asc"]
          );

          let _cells = paste
            .split("\n")
            .map((x) => x.split("\t"))
            .reduce((prev, cur, rowIndex) => {
              cur.forEach((x, cellIndex) => {
                _selectedCells[0].cellEl.value = x;
                prev.push({
                  row: _selectedCells[0].rowNum + rowIndex,
                  col: _selectedCells[0].colNum + cellIndex,
                  value: x,
                });
              });
              return prev;
            }, []);

          if (moveEvent.current.type === "cut") {
            moveEvent.current.selectedCells.forEach((x) => {
              _cells.push({ value: "", row: x.rowNum, col: x.colNum });
            });
          }

          props.ADD_CELLS({
            cells: _cells,
            tableId: props.nodeId,
            registryVersion: props.registryVersion,
          });
          cleanUpClipboard(false);
        }
      }
    }
  };

  const addSelectedCellsToClipboard = async (event) => {
    if (isActiveTab()) {
      event.preventDefault();

      // TODO handle shift
      const _copiedCells = getCellsWithIdSet(copiedCells.current);
      removeFromElArrClassList(_copiedCells, ["copied-cell"]);

      copiedCells.current = new Set(selectedCells.current);

      const _selectedCells = getCellsWithIdSet(selectedCells.current);
      removeFromElArrClassList(_selectedCells, ["selected-cell"]);
      addToElArrClassList(_selectedCells, ["copied-cell"]);

      let textRows = [];

      const tmpTable = document.createElement("table");
      const tmpTbody = document.createElement("tbody");

      moveEvent.current.selectedCells = [];

      let foundCells = {};

      copiedCells.current.forEach((x) => {
        const splitId = x.split("_");
        const rowNum = parseInt(splitId[0]);
        const colNum = parseInt(splitId[1]);

        if (!foundCells[rowNum]) foundCells[rowNum] = {};
        let _text = "";

        const el = document.getElementById(x);

        if (el) {
          _text = el.textContent;
        } else {
          const found = props.cells.find(
            (_cell) => _cell.row === rowNum && _cell.col === colNum
          );
          _text = found?.value ?? found?.nodeText?.name ?? "";
        }

        foundCells[rowNum][colNum] = _text;
      });

      Object.keys(foundCells).forEach((row) => {
        const tmpRow = document.createElement("tr");
        let texts = [];

        Object.keys(foundCells[row]).forEach((col) => {
          const cellText = foundCells[row][col];
          moveEvent.current.selectedCells.push({
            cellText,
            rowNum: row,
            colNum: col,
          });
          texts.push(cellText);
          const tmpTd = document.createElement("td");
          const tmpText = document.createTextNode(cellText);
          tmpTd.appendChild(tmpText);
          tmpRow.appendChild(tmpTd);
        });

        textRows.push(texts.join("\t"));
        tmpTbody.appendChild(tmpRow);
      });

      tmpTable.appendChild(tmpTbody);

      try {
        if (window.ClipboardItem) {
          navigator.clipboard.writeText(textRows.join("\n"));

          var textType = "text/plain";
          var textBlob = new Blob([textRows.join("\n")], { type: textType });
          var htmlType = "text/html";
          var htmlBlob = new Blob([tmpTable.outerHTML], { type: htmlType });

          var data = [
            new window.ClipboardItem({
              [textType]: textBlob,
              [htmlType]: htmlBlob,
            }),
          ];
          navigator.clipboard.write(data).then(async (x) => {
            // navigator.clipboard
            //   .read()
            //   .then((res) => console.warning("clipboard read res", res));
            // const clipboardItems = await navigator.clipboard.read();
            // for (const clipboardItem of clipboardItems) {
            //   for (const type of clipboardItem.types) {
            //     const blob = await clipboardItem.getType(type);
            //     // we can now use blob here
            //     var myReader = new FileReader();
            //     myReader.onload = function (event) {
            //       console.warning("clipboard read res", myReader.result);
            //     };
            //     myReader.readAsText(blob);
            //   }
            // }
          });
        } else if (navigator.clipboard) {
          navigator.clipboard.writeText(textRows.join("\n"));
        } else {
          toast.error("Copying to clipboard failed, UNSECURE ORIGIN");
          // selectElementContents(tmpTable);
        }
      } catch (error) {
        toast.error("Copying to clipboard failed");
      }
      tmpTable.remove();
    }
  };

  // TODO autocomplete with basenode doesnt work with full code
  // TODO autocomplete popover for input
  const handleCopy = (event) => {
    if (isActiveTab()) {
      moveEvent.current.type = "copy";
      addSelectedCellsToClipboard(event);
    }
  };

  const handleCut = (event) => {
    if (isActiveTab()) {
      moveEvent.current.type = "cut";
      addSelectedCellsToClipboard(event);
    }
  };

  const selectCell = useCallback((rowNum, colNum) => {
    // deselect everything
    const _selectedCells = getCellsWithIdSet(selectedCells.current);
    removeFromElArrClassList(_selectedCells, ["selected-cell"]);
    selectedCells.current.clear();
    const newCellEl = document.getElementById(rowNum + "_" + colNum);
    clickedCell.current = newCellEl;
    selectedCells.current.add(rowNum + "_" + colNum);
    newCellEl.classList.add("selected-cell");
    startCellIndex.current = colNum;
    startRowIndex.current = rowNum;

    const input = document.getElementById("cellInput");
    const inputIsVisible = input.style.visibility === "visible";
    // if input is visible, blur it
    if (inputIsVisible) {
      input.blur();
    }
  }, []);

  const _mouseUpHandler = (e) => {
    if (isActiveTab()) {
      setCanEdgeScroll(false);
      isMouseDown.current = false;

      if (colResizer.current.el) {
        const resizeMarker = document.getElementById("resizeMarker");
        Object.assign(resizeMarker.style, {
          display: "none",
        });
        // Determine how far the mouse has been moved
        const dx = e.clientX - colResizer.current.x;

        setRequesting({
          loading: "TABLE_EDIT",
          params: {
            url: "nodes/resizeColumn",
            method: "PUT",
            action: "SET_TABLE",
            body: { col: colResizer.current.col, dx: dx },
          },
        });

        colResizer.current.el.classList.remove("resizing");
        colResizer.current = { el: null };
      }
    }
  };

  const _mouseMoveHandler = function (e) {
    if (isActiveTab()) {
      if (colResizer.current.el) {
        const resizeMarker = document.getElementById("resizeMarker");
        Object.assign(resizeMarker.style, {
          left: `${e.clientX - 2}px`,
        });
      }
      if (isMouseDown.current) {
        const elementUnderPoint = document.elementFromPoint(
          e.clientX,
          e.clientY
        );
        const target = elementUnderPoint && elementUnderPoint.closest("span");
        if (target) {
          selectedCells.current.forEach((elId) => {
            const _el = document.getElementById(elId);
            if (_el) {
              _el.classList.remove("selected-cell");
            }
          });
          selectedCells.current.clear();
          // const selected = document.querySelectorAll(".selected-cell");
          // for (let i = 0; i < selected.length; i++) {
          //   const el = selected[i];
          //   el.classList.remove("selected-cell"); // deselect everything
          // }
          const splitId = target.id.split("_");
          selectTo(splitId[0], splitId[1]);
        }
      }
    }
  };

  const handleColResizerMouseDown = (col) => (ev) => {
    const resizeMarker = document.getElementById("resizeMarker");
    // set mouse position, el and col to ref we can use them in global mouseUpHandler
    colResizer.current = { el: ev.target, x: ev.clientX, col };
    // Calculate the current width of column
    // const styles = window.getComputedStyle(col);
    // w = parseInt(styles.width, 10);
    Object.assign(resizeMarker.style, {
      left: `${ev.clientX - 2}px`,
      display: "block",
    });

    ev.target.classList.add("resizing");
  };

  const handleColMenuOpen = useCallback(
    (colIndex) => (event) => {
      event.preventDefault();
      event.stopPropagation();
      if (editable) {
        const col = colIndex + 1;
        setContentToEdit({
          anchor: event.currentTarget,
          type: "col",
          col,
        });
        insertPopup(
          "bottom",
          "Col" + col,
          "tableContentEdit",
          "tableContentEditArrow",
          overlayId
        );
      }
    },
    [editable]
  );

  const handleRowMenuOpen = useCallback(
    (row) => (event) => {
      event.preventDefault();
      event.stopPropagation();
      setContentToEdit({ anchor: event.currentTarget, type: "row", row: row });
      insertPopup(
        "right",
        "Row" + row,
        "tableContentEdit",
        "tableContentEditArrow",
        overlayId
      );
    },
    []
  );

  const handleCellInputBlur = useCallback(
    (e) => {
      if (editable) {
        const { rowNum, colNum, missing } = cellToModify.current;

        const payload = {
          cell: {
            row: rowNum,
            col: colNum,
            nodeText: { name: e.target.value },
            value: undefined,
            type: 14,
            missing: missing,
          },
          registryVersion: props.registryVersion,
          tableId: props.nodeId,
        };
        pushToActions({ type: "REPLACE_CELL", payload });
        props.REPLACE_CELL(payload);

        const inputLabel = document.getElementById("inputLabel");
        inputLabel?.remove();
        const input = document.getElementById("cellInput");
        input.value = "";
        input.style.visibility = "hidden";
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editable, props.REPLACE_CELL, props.nodeId, props.registryVersion]
  );

  const closePopovers = () => {
    const el = document.getElementById("tableContentEdit");
    const overlay = document.getElementById(overlayId);
    Object.assign(el.style, {
      visibility: "hidden",
    });
    Object.assign(overlay.style, {
      display: "none",
    });
  };

  useEffect(() => {
    if (closing) {
      if (!requesting) {
        closePopovers();
        setClosing(false);
      } else {
        setClosing(false);
      }
    }
  }, [closing, requesting]);

  const handleClose = () => {
    setClosing(true);
  };

  const selectTo = useCallback((rowNum, colNum) => {
    let cellIndex = colNum;
    let rowIndex = rowNum;

    let rowStart, rowEnd, cellStart, cellEnd;

    if (rowIndex < startRowIndex.current) {
      rowStart = rowIndex;
      rowEnd = startRowIndex.current;
    } else {
      rowStart = startRowIndex.current;
      rowEnd = rowIndex;
    }

    if (cellIndex < startCellIndex.current) {
      cellStart = cellIndex;
      cellEnd = startCellIndex.current;
    } else {
      cellStart = startCellIndex.current;
      cellEnd = cellIndex;
    }

    for (let i = rowStart; i <= rowEnd; i++) {
      for (let j = cellStart; j <= cellEnd; j++) {
        const id = i + "_" + j;
        selectedCells.current.add(id);
        const el = document.getElementById(id);
        if (el) el.classList.add("selected-cell");
      }
    }
  }, []);

  const handleCellInputFocus = (input, copyValue) => {
    // create input on top of clickedCell
    if (clickedCell.current) {
      if (input.style.visibility === "hidden") {
        let box = document.getElementById(clickedCell.current.id);
        if (box) {
          const splitId = box.id.split("_");
          cellToModify.current = {
            rowNum: splitId[0],
            colNum: splitId[1],
            missing: box.classList.contains("missing-id"),
          };
          const rect = box.getBoundingClientRect();
          const input = document.getElementById("cellInput");

          oldEditCellValue.current = box.textContent;
          if (copyValue) {
            input.value = box.textContent;
          }

          input.style.top = rect.top + "px";
          input.style.left = rect.left + "px";
          input.style.width = box.offsetWidth - 5 + "px";
          input.style.height = box.offsetHeight - 3 + "px";
          input.style.visibility = "visible";
          inputRef.current.focus();
        }
      }
    }
  };

  const handleCellDoubleClick = useCallback(
    (rowNum, colNum, cellData) => () => {
      if (editable) {
        const input = document.getElementById("cellInput");
        isAllowedToMove.current = false;
        handleCellInputFocus(input, true);
      }
    },
    [editable]
  );

  const clearSelection = useCallback(() => {
    const _selectedCells = getCellsWithIdSet(selectedCells.current);
    removeFromElArrClassList(_selectedCells, ["selected-cell"]);
    selectedCells.current.clear();
  }, [selectedCells]);

  const handleCellMouseDown = useCallback(
    (rowNum, colNum, cellData) => async (e) => {
      if (e.button === 0) {
        clickedCell.current = document.getElementById(rowNum + "_" + colNum);
        // fetch product or package if cell has value
        if (cellData?.id) {
          request("nodes", "get", null, {
            registryVersion: props.registryVersion,
            ids: [cellData.id],
          }).then((res) => {
            if (res.data) {
              props.SET_NODES({
                nodes: res.data,
                registryVersion: props.registryVersion,
              });
              props.REPLACE_TREE({
                root: "tmp",
                registryVersion: props.registryVersion,
                data: res.data,
              });
            }
          });
        }

        setCanEdgeScroll(true);
        isMouseDown.current = true;

        let cellEl = e.target;

        // deselect everything
        if (!e.shiftKey && !e.ctrlKey) {
          clearSelection();
        }

        if (e.shiftKey) {
          selectTo(rowNum, colNum);
        } else {
          selectedCells.current.add(rowNum + "_" + colNum);
          cellEl.classList.add("selected-cell");
          startCellIndex.current = colNum;
          startRowIndex.current = rowNum;
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.registryVersion, selectTo, isMouseDown]
  );

  useEffect(() => {
    if (props.values) {
      let rows = [];

      if (props.cells) {
        const _rowCount = getRowCount(editable, props.values);

        for (let i = 0; i < _rowCount; i++) {
          rows.push({});
        }

        props.cells.forEach((x) => {
          rows[x.row - 1][x.col] = x;
        });
      }

      setRows(rows);
    }
  }, [editable, props.values, props.cells]);

  const colCount = editable
    ? (values?.colCount ?? 0) >= initialColumnCount
      ? values?.colCount + 4
      : initialColumnCount
    : values?.colCount
    ? values.colCount
    : 0;

  const colLen = values?.colLen || "40";

  const colWidths = useMemo(() => {
    let _colWidths = [];
    let _colLenArr = parseColLen(colLen);
    for (let i = 0; i < colCount; i++) {
      _colWidths.push(_colLenArr[i] ?? 40);
    }
    return _colWidths;
  }, [colCount, colLen]);

  useEffect(() => {
    if (mainGrid.current) {
      // need to call this when colWidths change or grid wont update
      mainGrid.current._bottomLeftGrid &&
        mainGrid.current._bottomLeftGrid?.recomputeGridSize();
      mainGrid.current._bottomRightGrid &&
        mainGrid.current._bottomRightGrid?.recomputeGridSize();
      mainGrid.current._topLeftGrid &&
        mainGrid.current._topLeftGrid?.recomputeGridSize();
      mainGrid.current._topRightGrid &&
        mainGrid.current._topRightGrid?.recomputeGridSize();
    }
  }, [mainGrid, colWidths]);

  useEffect(() => {
    if (mainGrid.current) {
      // need to call this or grid wont display updated data
      mainGrid.current.forceUpdateGrids();
    }
  }, [props.cells]);

  const getColumnWidth = useCallback(
    (_data) => {
      return colWidths[_data.index - 1] ?? 40;
    },
    [colWidths]
  );

  const handleKeyDown = (e) => {
    if (isActiveTab()) {
      if (!e.ctrlKey) {
        if (editable && e.key !== "Shift" && e.key !== "Control") {
          const input = document.getElementById("cellInput");
          const inputIsVisible = input.style.visibility === "visible";
          // handle moving in grid with arrows
          if (e.key.startsWith("Arrow")) {
            if (inputIsVisible ? isAllowedToMove.current : true) {
              e.preventDefault();
              let cellEl = clickedCell.current;
              const splitId = cellEl.id.split("_");
              let rowNum = splitId[0];
              let colNum = splitId[1];

              const scrollValue = {
                scrollTop: scrollValues.current.scrollTop,
                scrollLeft: scrollValues.current.scrollLeft,
              };

              let needToScroll = false;
              let colChange = false;
              let goingBackwards = false;
              // ArrowLeft 37
              if (e.keyCode === 37) {
                colChange = true;
                goingBackwards = true;
                colNum--;
              }
              // ArrowUp 38
              else if (e.keyCode === 38) {
                goingBackwards = true;
                rowNum--;
              }
              // ArrowRight 39
              else if (e.keyCode === 39) {
                colChange = true;
                colNum++;
              }
              // ArrowDown 40
              else if (e.keyCode === 40) {
                rowNum++;
              }

              if (
                colNum > colCount ||
                rowNum > rowCount ||
                rowNum === 0 ||
                colNum === 0
              ) {
                return;
              }

              // if moving to left or right, calculate visible columns
              if (colChange) {
                const width = props.width - 40 - scrollbarSize();
                // mainGrid.current._topRightGrid.props.width - scrollbarSize();
                const scrollLeft = scrollValue.scrollLeft;
                let columnsVisible = [];
                let sum = 0;
                const windowStart = scrollLeft;
                const windowEnd = windowStart + width;
                // columns that are inside windowStart and windowEnd are visible
                colWidths.forEach((x, i) => {
                  if (sum >= windowStart && sum + x <= windowEnd) {
                    columnsVisible.push(i + 1);
                  }
                  sum += x;
                });

                // set scroll values
                if (!columnsVisible.includes(colNum)) {
                  needToScroll = true;
                  if (goingBackwards) {
                    scrollValue.scrollLeft =
                      scrollValue.scrollLeft - colWidths[colNum - 1];
                  } else {
                    scrollValue.scrollLeft =
                      scrollValue.scrollLeft + colWidths[colNum - 1];
                  }
                }
              }
              // if moving up or down calculate visible rows
              else {
                const height =
                  props.height - (editable ? 73 : 0) - scrollbarSize();
                const scrollTop = scrollValue.scrollTop;
                const windowStart = scrollTop;
                const windowEnd = windowStart + height;

                let rowStart = windowStart / rowHeight;
                // add one so it is a number not index
                rowStart++;
                let rowEnd;

                if (!Number.isSafeInteger(rowStart)) {
                  rowStart = rowStart + 1;
                  rowStart = Math.ceil(rowStart);
                }

                rowEnd = rowStart;

                while ((rowEnd + 1) * rowHeight < windowEnd) {
                  rowEnd++;
                }

                // set scroll values
                if (rowNum < rowStart || rowNum > rowEnd) {
                  needToScroll = true;
                  if (goingBackwards) {
                    scrollValue.scrollTop =
                      scrollValue.scrollTop - (rowHeight + 1);
                  } else {
                    scrollValue.scrollTop =
                      scrollValue.scrollTop + (rowHeight + 1);
                  }
                }
              }

              if (needToScroll) {
                // value shouldn't be less than 0
                scrollValue.scrollLeft = Math.max(0, scrollValue.scrollLeft);
                scrollValue.scrollTop = Math.max(0, scrollValue.scrollTop);

                cellToSelectAfterScroll.current = { rowNum, colNum };
                mainGrid.current._bottomLeftGrid.scrollToPosition(scrollValue);
                mainGrid.current._bottomRightGrid.scrollToPosition(scrollValue);
                mainGrid.current._topLeftGrid.scrollToPosition(scrollValue);
                mainGrid.current._topRightGrid.scrollToPosition(scrollValue);
              } else {
                selectCell(rowNum, colNum);
              }
            }
          } else if (inputIsVisible) {
            if (e.key === "Delete") {
              input.value = "";
              inputRef.current.blur();
            } else if (e.key === "Escape") {
              input.value = oldEditCellValue.current;
              inputRef.current.blur();
            } else if (e.key === "Enter") {
              inputRef.current.blur();
            }
          } else if (e.key === "Delete") {
          } else if (e.key === "Escape") {
          } else {
            isAllowedToMove.current = true;
            handleCellInputFocus(input, e.key === "Enter");
          }
        }
      }
    }
  };

  const handleKeyUp = (e) => {
    if (isActiveTab()) {
      if (e.keyCode === 90 && e.ctrlKey) {
        undo();
      } else if (e.keyCode === 89 && e.ctrlKey) {
        redo();
      } else if (e.key === "Delete") {
        if (editable) {
          // delete selected Cells
          let _selectedCells = getCellsWithIdSet(selectedCells.current);
          if (_selectedCells.length > 0) {
            props.ADD_CELLS({
              registryVersion: props.registryVersion,
              tableId: props.nodeId,
              cells: _selectedCells.map((x) => ({
                row: x.rowNum,
                col: x.colNum,
                value: "",
              })),
            });
          }
        }
      } else if (e.key === "Escape") {
        // clear selection
        cleanUpClipboard(true);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);
    document.addEventListener("cut", handleCut);
    document.addEventListener("copy", handleCopy);
    document.addEventListener("paste", handlePaste);
    document.addEventListener("mouseup", _mouseUpHandler);
    document.addEventListener("mousemove", _mouseMoveHandler);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
      document.removeEventListener("cut", handleCut);
      document.removeEventListener("copy", handleCopy);
      document.removeEventListener("paste", handlePaste);
      document.removeEventListener("mouseup", _mouseUpHandler);
      document.removeEventListener("mousemove", _mouseMoveHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editable, props.cells, colCount, rowCount]);

  const valuesColCount = values?.colCount;
  const valuesRowCount = values?.rowCount;

  const handleColumnSelect = useCallback(
    (colIndex) => (e) => {
      const colNum = colIndex + 1;
      if (!e.shiftKey) {
        if (!e.ctrlKey) {
          clearSelection();
        }
        startCellIndex.current = colNum;
        startRowIndex.current = 1;
        for (let i = 1; i <= valuesRowCount; i++) {
          const id = i + "_" + colNum;
          selectedCells.current.add(id);
          const el = document.getElementById(id);
          if (el) el.classList.add("selected-cell");
        }
      } else {
        selectTo(valuesRowCount, colNum);
      }
    },
    [selectTo, clearSelection, valuesRowCount]
  );

  const handleRowSelect = useCallback(
    (rowIndex) => (e) => {
      const rowNum = rowIndex + 1;
      if (!e.shiftKey) {
        if (!e.ctrlKey) {
          clearSelection();
        }
        startCellIndex.current = 1;
        startRowIndex.current = rowNum;
        for (let i = 1; i <= valuesColCount; i++) {
          const id = rowIndex + "_" + i;
          selectedCells.current.add(id);
          const el = document.getElementById(id);
          if (el) el.classList.add("selected-cell");
        }
      } else {
        selectTo(rowNum, valuesColCount);
      }
    },
    [selectTo, clearSelection, valuesColCount]
  );

  const _renderBodyCell = useCallback(
    (params) => {
      if (params.rowIndex < 1) {
        return (
          <TableHeaderRenderer
            {...params}
            key={params.key}
            editable={editable}
            colWidths={colWidths}
            colCount={colCount}
            tableColCount={valuesColCount}
            handleColumnSelect={handleColumnSelect}
            handleColMenuOpen={handleColMenuOpen}
            handleColResizerMouseDown={handleColResizerMouseDown}
          />
        );
      } else if (params.columnIndex < 1) {
        return (
          <RowIndicator
            {...params}
            key={params.key}
            editable={editable}
            rowCount={rowCount}
            tableRowCount={valuesRowCount}
            handleRowSelect={handleRowSelect}
            handleRowMenuOpen={handleRowMenuOpen}
          />
        );
      } else {
        return (
          <CellRenderer
            {...params}
            key={params.key}
            rows={rows}
            rowCount={rowCount}
            colCount={colCount}
            tableColCount={valuesColCount}
            tableRowCount={valuesRowCount}
            selectedCells={selectedCells}
            copiedCells={copiedCells}
            onMouseDown={handleCellMouseDown}
            onDoubleClick={handleCellDoubleClick}
            cells={props.cells}
            searchNode={searchNode}
            clickedSearchNode={clickedSearchNode}
          />
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      editable,
      props.cells,
      colWidths,
      rows,
      rowCount,
      colCount,
      valuesColCount,
      valuesRowCount,
    ]
  );

  const handleNameInputBlur = (e) => {
    if (props.nodeId) {
      setRequesting({
        loading: "TABLE_EDIT",
        params: {
          url: "nodes/edit",
          method: "PUT",
          action: "UPDATE_NODE",
          body: {
            node: {
              ...props.values,
              nodeText: { ...props.values.nodeText, name: e.target.value },
            },
          },
          callback: (data) => {
            props.onSave && props.onSave(data, props.tabId);
          },
        },
      });
    }
  };

  // {
  //   clientHeight,
  //   clientWidth,
  //   scrollHeight,
  //   scrollLeft,
  //   scrollTop,
  //   scrollWidth,
  // }
  const handleScroll = useCallback(
    (params) => {
      scrollValues.current = params;

      const input = document.getElementById("cellInput");

      if (cellToSelectAfterScroll.current) {
        selectCell(
          cellToSelectAfterScroll.current.rowNum,
          cellToSelectAfterScroll.current.colNum
        );
        cellToSelectAfterScroll.current = null;
      } else if (input.style.visibility === "visible") {
        if (!document.getElementById("inputLabel")) {
          const inputLabel = document.createElement("span");
          inputLabel.id = "inputLabel";
          inputLabel.classList.add("input-cell-label");
          inputLabel.style.top =
            input.style.top.substring(0, input.style.top.length - 2) -
            input.style.height.substring(0, input.style.height.length - 2) +
            "px";
          inputLabel.style.left = input.style.left;
          let box = clickedCell.current;
          const splitId = box.id.split("_");
          inputLabel.innerHTML = getColName(splitId[1] - 1) + splitId[0];
          document.getElementById("node-table").appendChild(inputLabel);
        }
      }
    },
    [selectCell]
  );

  if (values && props.cells) {
    // const height = props.height - (editable ? 56 : 0) - 40;
    const height = props.height - (editable ? 55 : 0);
    return (
      <div
        id="node-table"
        style={{ height: "100%", width: "100%", backgroundColor: "#90d197" }}
      >
        <div
          id="tableContainer"
          style={{
            height: props.height - (editable ? 36 : 0),
            width: props.width,
            maxHeight: props.height - (editable ? 36 : 0),
            maxWidth: props.width,
          }}
        >
          <div
            id="resizeMarker"
            className="noselect"
            style={{
              top: 65 + 25, //props.top ?? 0,
              left: 25,
              position: "fixed",
              width: 4,
              height: props.height,
              backgroundColor: "#808080",
              zIndex: 1001,
              display: "none",
            }}
          />

          <input
            ref={inputRef}
            id={"cellInput"}
            className={"noselect grid-input grid-cell product-cell"}
            style={{
              position: "fixed",
              // top: 400,
              // left: 500,
              visibility: "hidden",
              zIndex: 100000,
            }}
            type="text"
            spellCheck="false"
            onBlur={handleCellInputBlur}
          />

          {editable && props.nodeId ? (
            <Stack
              direction="row"
              sx={{
                height: 40,
                padding: 1,
                paddingRight: 0,
                width: "100%",
                backgroundColor: "#fff",
              }}
              justifyContent="space-between"
              alignItems="center"
            >
              <TextField
                variant="standard"
                label={t("name")}
                defaultValue={values.nodeText.name}
                onBlur={handleNameInputBlur}
                sx={{ width: 260 }}
                // onChange={handleNameChange}
              />
              <EditTreeControls
                node={values}
                treeRoot={props.treeRoot}
                reduxRoot={props.reduxRoot}
                // TODO handle callbacks => save to values (what if node is pending an edit)
                // TODO should moving node etc trigger a fetch from backend
                onDelete={() => props.resetTab(props.tabId)}
                onNodeMoved={(newPath) => {
                  const payload = {
                    table: { ...values, path: newPath },
                    registryVersion: props.registryVersion,
                  };
                  pushToActions({ type: "SET_TABLE", payload });
                  props.SET_TABLE(payload);
                }}
                // setNode={props.setNode}
              />
              <LoadingButton
                sx={{ marginRight: 2 }}
                color="basic"
                variant="contained"
                loading={!!requesting}
                loadingPosition="end"
                endIcon={<MdSave />}
                disabled={!!requesting}
                onClick={saveCells}
              >
                {t("save")}
              </LoadingButton>
            </Stack>
          ) : null}

          <div className={"GridRow"}>
            <MultiGrid
              ref={mainGrid}
              classNameBottomLeftGrid="LeftSideGrid"
              classNameBottomRightGrid="BodyGrid"
              classNameTopLeftGrid="HeaderGrid"
              classNameTopRightGrid="HeaderGrid"
              cellRenderer={_renderBodyCell}
              columnWidth={getColumnWidth}
              columnCount={colCount + 1}
              fixedColumnCount={1}
              fixedRowCount={1}
              rowHeight={rowHeight}
              rowCount={rowCount}
              height={height}
              width={props.width}
              onScroll={handleScroll}
              containerProps={getEdgeScrollingProps({
                style: {
                  height: height,
                  width: props.width,
                },
              })}
            />
          </div>

          {/* <ArrowKeyStepper
            columnCount={colCount + 1}
            rowCount={rowCount + 1}
            mode="cells"
          >
            {({ onSectionRendered, scrollToColumn, scrollToRow }) => (
            )}
          </ArrowKeyStepper> */}
          {/* <AutoSizer>
            {({ width, height }) => (
            )}
          </AutoSizer> */}

          <div id="tableContentEdit" className="popover">
            <div id="tableContentEditArrow" className="arrow"></div>
            <div className="navigation">
              <div className="navigation-content-body">
                {contentToEdit.type === "row" ? (
                  <>
                    <TableContentEditMenu
                      disabled={requesting}
                      title={t("addRowAbove")}
                      loading={
                        requestParams?.action === "ADD_ROW" &&
                        requestParams?.body.row === contentToEdit.row - 1
                      }
                      onClick={() => {
                        setRequesting({
                          loading: "TABLE_EDIT",
                          params: {
                            url: "nodes/addRow",
                            method: "PUT",
                            action: "ADD_ROW",
                            body: { row: contentToEdit.row - 1 },
                          },
                        });
                      }}
                      icon={<RiInsertRowTop size={24} />}
                    />
                    <TableContentEditMenu
                      disabled={requesting}
                      title={t("addRowBelow")}
                      loading={
                        requestParams?.action === "ADD_ROW" &&
                        requestParams?.body.row === contentToEdit.row
                      }
                      onClick={() => {
                        setRequesting({
                          loading: "TABLE_EDIT",
                          params: {
                            url: "nodes/addRow",
                            method: "PUT",
                            action: "ADD_ROW",
                            body: { row: contentToEdit.row },
                          },
                        });
                      }}
                      icon={<RiInsertRowBottom size={24} />}
                    />
                    <Divider />
                    <TableContentEditMenu
                      disabled={requesting || values.rowCount < 2}
                      title={t("deleteRow")}
                      loading={requestParams?.action === "DELETE_ROW"}
                      onClick={() => {
                        setRequesting({
                          loading: "TABLE_EDIT",
                          params: {
                            url: "nodes/deleteRow",
                            method: "PUT",
                            action: "DELETE_ROW",
                            body: { row: contentToEdit.row },
                          },
                        });
                      }}
                      icon={<RiDeleteRow size={24} />}
                    />
                  </>
                ) : (
                  <>
                    <TableContentEditMenu
                      disabled={requesting}
                      title={t("addColumnLeft")}
                      loading={
                        requestParams?.action === "ADD_COLUMN" &&
                        requestParams?.body.col === contentToEdit.col - 1
                      }
                      onClick={() => {
                        setRequesting({
                          loading: "TABLE_EDIT",
                          params: {
                            url: "nodes/addColumn",
                            method: "PUT",
                            action: "ADD_COLUMN",
                            body: { col: contentToEdit.col - 1 },
                          },
                        });
                      }}
                      icon={<RiInsertColumnLeft size={24} />}
                    />
                    <TableContentEditMenu
                      disabled={requesting}
                      title={t("addColumnRight")}
                      loading={
                        requestParams?.action === "ADD_COLUMN" &&
                        requestParams?.body.col === contentToEdit.col
                      }
                      onClick={() => {
                        setRequesting({
                          loading: "TABLE_EDIT",
                          params: {
                            url: "nodes/addColumn",
                            method: "PUT",
                            action: "ADD_COLUMN",
                            body: { col: contentToEdit.col },
                          },
                        });
                      }}
                      icon={<RiInsertColumnRight size={24} />}
                    />
                    <Divider />
                    <TableContentEditMenu
                      disabled={requesting || values.colCount < 2}
                      title={t("deleteColumn")}
                      loading={requestParams?.action === "DELETE_COLUMN"}
                      onClick={() => {
                        setRequesting({
                          loading: "TABLE_EDIT",
                          params: {
                            url: "nodes/deleteColumn",
                            method: "PUT",
                            action: "DELETE_COLUMN",
                            body: { col: contentToEdit.col },
                          },
                        });
                      }}
                      icon={<RiDeleteColumn size={24} />}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
          <div
            id={overlayId}
            className="overlay transparent"
            onClick={handleClose}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          backgroundColor: "#90d197",
        }}
      ></div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const registryVersion =
    ownProps.registryVersion || localStorage.getItem("registryVersion");
  return {
    registryVersion,
    values: state.tables[ownProps.nodeId]?.[registryVersion],
    cells: state.tables.cells[ownProps.nodeId]?.[registryVersion],
  };
};

export default connect(mapStateToProps, {
  SET_NODES,
  ADD_CELLS,
  REPLACE_TABLE,
  SET_TABLE,
  SET_TABLE_CELLS,
  CLEAR_TREE,
  REPLACE_TREE,
  REPLACE_CELL,
  UPDATE_NODE,
  ADD_ROW,
  ADD_COLUMN,
  DELETE_ROW,
  DELETE_COLUMN,
})(TableComponent);
