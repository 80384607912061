import React, { useEffect, useCallback, useState, useMemo } from "react";
// import CircularProgress from "@mui/material/CircularProgress";
import debounce from "lodash.debounce";
import { useHistory } from "react-router-dom";

import { requestSearch } from "../helpers/requests";
import { getName } from "../helpers/functions";

import { useCombobox } from "downshift";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import CircularProgress from "@mui/material/CircularProgress";

import AutoCompleteOption from "./AutoCompleteOption";

import {
  MdClose,
  MdOutlineExpandLess,
  MdOutlineExpandMore,
} from "react-icons/md";

import { useFloating, flip, size } from "@floating-ui/react-dom";

import cx from "classnames";

import { FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

const itemToString = (item) => {
  return typeof item === "string" ? item : item ? getName(item) : "";
};

function Row({ data, index, style }) {
  const {
    t,
    getItemProps,
    treeRoot,
    registryVersion,
    productsMap,
    nodesMap,
    selectedItem,
    highlightedIndex,
    items,
  } = data;
  const item = items[index];

  return (
    <div
      key={item.id}
      style={style}
      {...getItemProps({
        item,
        index,
      })}
    >
      <AutoCompleteOption
        t={t}
        className={cx(
          highlightedIndex === index && "bg-blue-300",
          selectedItem === itemToString(item) && "font-bold"
        )}
        // optionProps={optionProps}
        option={item}
        treeRoot={treeRoot}
        registryVersion={registryVersion}
        productsMap={productsMap}
        nodesMap={nodesMap}
      />
    </div>
  );
}

export default function AsyncAutocomplete(props) {
  const {
    t,
    label,
    value,
    // idProp,
    registryVersion,
    onSelect,
    disabled,
    treeRoot,
    productsMap,
    nodesMap,
    searchParams,
    filterResults,
  } = props;
  let history = useHistory();
  const [selectedItem, setSelectedItem] = useState(null);
  const [inputValue, setInputValue] = useState(
    value ? itemToString(value) : ""
  );
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState();

  useEffect(() => {
    if (value) {
      setInputValue(itemToString(value));
    }
  }, [value]);

  const fetch = useMemo(
    () =>
      debounce((searchText, callback) => {
        requestSearch(
          searchText,
          registryVersion,
          searchParams,
          callback,
          setLoading
        );
      }, 500),
    [searchParams, registryVersion]
  );

  const handleInputChange = useCallback(
    (event) => {
      if (disabled) return;
      const _inputValue = event.target.value;
      setInputValue(_inputValue);
      if (_inputValue && _inputValue.length > 3) {
        setLoading(true);
        fetch(_inputValue, (_items) => {
          setItems(filterResults ? filterResults(_items) : _items);
        });
      } else {
        setItems([]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetch]
  );

  const {
    isOpen,
    selectItem,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
    getInputProps,
    highlightedIndex,
  } = useCombobox({
    items,
    selectedItem,
    inputValue,
    initialSelectedItem: value,
    onSelectedItemChange: ({ selectedItem: newSelectedItem }) => {
      onSelect(newSelectedItem);
    },
    itemToString(item) {
      return itemToString(item);
    },
  });

  const { x, y, reference, floating, strategy } = useFloating({
    strategy: "fixed",
    placement: "bottom",
    middleware: [
      // shift(), // fixed modal doesn't work with shift
      flip(),
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width - 2}px`,
          });
        },
      }),
    ],
  });

  const hasItems = items.length > 0;
  const itemSize = 38;
  // items height + vertical scrollbar + 20
  const itemsHeight = items && hasItems ? items.length * itemSize + 20 : 0;
  return (
    <Box>
      <Box ref={reference}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
          <OutlinedInput
            fullWidth
            label={label}
            {...getInputProps({ refKey: "inputRef" })}
            value={inputValue}
            onChange={handleInputChange}
            sx={{
              borderBottomLeftRadius: isOpen && hasItems ? 0 : undefined,
              borderBottomRightRadius: isOpen && hasItems ? 0 : undefined,
              fieldset: {
                borderBottomStyle: isOpen && hasItems ? "none" : undefined,
              },
            }}
            endAdornment={
              disabled ? null : (
                <>
                  <IconButton {...getToggleButtonProps()}>
                    {isOpen ? <MdOutlineExpandLess /> : <MdOutlineExpandMore />}
                  </IconButton>
                  {loading ? (
                    <IconButton>
                      <CircularProgress color="inherit" size={24} />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={() => {
                        // clear url
                        if (props.clearUrlOnClear) {
                          history.replace({
                            search: "",
                          });
                        }
                        selectItem(null);
                        setInputValue("");
                        setItems([]);
                      }}
                    >
                      <MdClose />
                    </IconButton>
                  )}
                </>
              )
            }
          />
        </FormControl>
      </Box>

      <div {...getMenuProps()} style={{ position: "relative" }}>
        {isOpen && (
          <div
            ref={floating}
            style={{
              position: strategy,
              top: y ?? "",
              left: x ?? "",
              zIndex: 10,
              backgroundColor: "#fff",
              width: 450,
              borderStyle: "solid",
              borderWidth: items.length > 0 ? 1 : 0,
              borderColor: "rgb(144, 209, 151)",
              borderBottomLeftRadius: 4,
              borderBottomRightRadius: 4,
              borderTopStyle: "none",
              boxShadow: "rgba(0, 0, 0, 0.2) 0px 60px 40px -7px",
            }}
          >
            <AutoSizer disableHeight>
              {({ width }) => (
                <FixedSizeList
                  height={itemsHeight > 500 ? 500 : itemsHeight}
                  width={width - 2}
                  itemSize={itemSize}
                  itemCount={items.length}
                  itemData={{
                    t,
                    getItemProps,
                    treeRoot,
                    registryVersion,
                    productsMap,
                    nodesMap,
                    selectedItem,
                    highlightedIndex,
                    items,
                  }}
                >
                  {Row}
                </FixedSizeList>
              )}
            </AutoSizer>
          </div>
        )}
      </div>
    </Box>
  );
}
