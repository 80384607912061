import update from "immutability-helper";

function SET_NODES(state, action) {
  const { nodes, registryVersion } = action.payload;
  if (nodes.length > 0) {
    let _nodes = [];
    let _products = [];

    // separate nodes into products and nodes
    // products don't have versioning
    nodes.forEach((x) => {
      if (x.type === 3) {
        _products.push(x);
      } else {
        _nodes.push(x);
      }
    });
    return update(state, {
      nodeMaps: {
        [registryVersion]: {
          $autoMap: { $add: _nodes.map((x) => [x.id, x]) },
        },
      },
      productsMap: { $autoMap: { $add: _products.map((x) => [x.id, x]) } },
    });
  } else return state;
}
export default function nodesReducer(
  state = {
    nodeMaps: {},
    productsMap: new Map(),
    paths: {},
  },
  action
) {
  try {
    if (action.type === "SET_NODES") {
      return SET_NODES(state, action);
    } else if (action.type === "ADD_NODES") {
      return SET_NODES(state, action);
    } else if (action.type === "REPLACE_NODES") {
      const { nodes, registryVersion } = action.payload;
      if (nodes.length > 0) {
        const nodesUpdateObj = {};
        const productsUpdateObj = {};
        const nodesMap = state?.nodeMaps?.[registryVersion] ?? new Map();
        const productsMap = state?.productsMap ?? new Map();

        // separate nodes into products and nodes
        // products don't have versioning
        // only replace if found
        nodes.forEach((x) => {
          if (x.type === 3) {
            if (productsMap.get(x.id)) {
              productsUpdateObj[x.id] = { $set: x };
            }
          } else {
            if (nodesMap.get(x.id)) {
              nodesUpdateObj[x.id] = { $set: x };
            }
          }
        });

        return update(state, {
          nodeMaps: {
            [registryVersion]: {
              $autoMap: nodesUpdateObj,
            },
          },
          productsMap: { $autoMap: productsUpdateObj },
        });
      } else return state;
    } else if (action.type === "SET_PATHS") {
      const { nodeId, nodes, paths, registryVersion } = action.payload;
      return update(state, {
        paths: {
          [registryVersion]: {
            $auto: { [nodeId]: { $set: { nodes, pathsArray: paths } } },
          },
        },
      });
    }

    return state;
  } catch (error) {
    console.error(
      `Error: ${error}, Reducer: NodesReducer, Action: ${
        action.type
      }, Payload: ${
        action.payload ? JSON.stringify(action.payload) : "no payload"
      }`
    );
    return state;
  }
}
