export const itemForm = {
  data: [
    {
      title: "tagsForm1_1",
      rows: [
        [
          {
            key: "nodeText.lang",
            disabled: true,
            label: "language",
            type: "picker",
            options: ["FIN"],
            error: "",
            validation: "",
            mask: "",
          },
        ],
        [
          {
            key: "code",
            label: "code",
            type: "textField",
            error: "",
            validation: "",
            mask: "",
            fill: true,
          },
        ],
        [
          {
            label: "name",
            key: "nodeText.name",
            type: "textField",
            error: "",
            validation: "",
            mask: "",
            fill: true,
          },
        ],
        [
          {
            label: "suppliers",
            key: "suppliers",
            type: "suppliers",
            error: "",
            validation: "",
            mask: "",
            fill: true,
          },
        ],
      ],
    },
  ],
};
