import { useState, useEffect } from "react";

function getWindowDimensions() {
  return {
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight - 40,
  };
}

/**
 * @typedef {Object} WindowDimensions
 * @property {number} windowHeight - window height
 * @property {number} windowWidth - window width
 */

/**
 * Hook for getting window dimensions
 * @category Hooks
 * @returns {WindowDimensions} Object containing windowHeight and windowWidth
 */
export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}
