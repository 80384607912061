import axios from "axios";
import { toast } from "react-toastify";

// ! API MOCK START !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// import MockAdapter from "axios-mock-adapter";
// import queryString from "query-string";
// import mockTrees from "../__mocks__/mockTrees";
// import versionChanges1_2 from "../__mocks__/mockVersionChangesV1-2";
// import versionChanges1_3 from "../__mocks__/mockVersionChangesV1-3";
// import update from "immutability-helper";

// // This sets the mock adapter on the default instance
// var mock = new MockAdapter(axios, {
//   // delayResponse: 2000,
//   // onNoMatch: "passthrough",
//   onNoMatch: "throwException",
// });

// // arguments for reply are (status, data, headers)
// const mockVersions = {
//   publishedVersion: "2",
//   id: "versions",
//   versions: {
//     1: {
//       nodeText: {
//         comment: "Test registryVersion 1 comment",
//         nodeType: 12,
//       },
//     },
//     2: {
//       nodeText: {
//         comment: "Test registryVersion 2 comment",
//         nodeType: 12,
//       },
//     },
//     3: {
//       nodeText: {
//         comment: "Test registryVersion 3 comment",
//         nodeType: 12,
//       },
//     },
//   },
// };

// mock.onPut(/packages\/createFromExcelData.*/).reply(function (config) {
//   // data is array of arrays + column information
//   // {
//   //   data: [["row1col1", "row1col2"], ["row2col1", "", row2col3]...],
//   //   columns: ["code","itemCode"...],
//   // }
//   const query = config.params;
//   const data = JSON.parse(config.data);
//   console.log("packages/createFromExcelData data", { data });

//   let packages = {};

//   const codeIndex = data.columns.indexOf("code");
//   const nameIndex = data.columns.indexOf("name");
//   const itemCodeIndex = data.columns.indexOf("itemCode");
//   const itemAmountIndex = data.columns.indexOf("amount");
//   const itemUnitIndex = data.columns.indexOf("unit");

//   data.data.forEach((x) => {
//     const packageCode = x[codeIndex];
//     if (packageCode) {
//       packages[packageCode] = update(packages[packageCode] || {}, {
//         code: { $set: x[codeIndex] },
//         nodeText: { $set: x[nameIndex] },
//         items: {
//           $autoArray: {
//             $apply: (_items) => {
//               if (x[itemCodeIndex]) {
//                 return update(_items, {
//                   $push: [
//                     {
//                       code: x[itemCodeIndex],
//                       amount: x[itemAmountIndex],
//                       unit: x[itemUnitIndex],
//                     },
//                   ],
//                 });
//               } else {
//                 return _items;
//               }
//             },
//           },
//         },
//       });
//     }
//   });
//   const packagesList = Object.values(packages);
//   console.log("packages/createFromExcelData return", { packagesList });
//   return [200, packagesList];
// });
// mock.onGet(/nodes\/search.*/).reply(function (config) {
//   const query = config.params;
//   return [200, Object.values(mockTrees[query.registryVersion].nodes)];
// });
// mock.onGet(/nodes\/missingNodes.*/).reply(function (config) {
//   const query = config.params;
//   return [200, Object.values(mockTrees[query.registryVersion].nodes)];
// });

// mock.onPut(/nodes\/addCells.*/).reply(function (config) {
//   const query = config.params;
//   const data = JSON.parse(config.data);

//   console.log("addCells query", { query, config, data });
//   let table = mockTrees[query.registryVersion]?.nodes?.[data.tableId];
//   let cells = mockTrees[query.registryVersion]?.cells?.[data.tableId] || [];

//   console.log("addCells table", JSON.parse(JSON.stringify(table)));
//   console.log("addCells cells", JSON.parse(JSON.stringify(cells)));

//   if (table) {
//     let maxRow = table.rowCount;
//     let maxCol = table.colCount;

//     data.cells.forEach((cell) => {
//       if (cell.row > maxRow) maxRow = cell.row;
//       if (cell.col > maxCol) maxCol = cell.col;

//       console.log("addCells cell", cell);
//       const cellIndex = cells.findIndex(
//         (x) => x.col === cell.col && x.row === cell.row
//       );
//       const _cell = {
//         ...cell,
//         nodeText: { name: cell.value },
//         value: undefined,
//       };
//       if (cellIndex !== -1) {
//         cells = update(cells, {
//           [cellIndex]: {
//             $set: _cell,
//           },
//         });
//       } else {
//         cells = update(cells, { $push: [_cell] });
//       }
//     });

//     table = update(table, {
//       rowCount: { $set: maxRow },
//       colCount: { $set: maxCol },
//       colLen: {
//         $apply: (x) => {
//           let newColLenArr = [];
//           const splitColLen = x.split(",");
//           for (let i = 0; i < maxCol; i++) {
//             const colLen = splitColLen[i];
//             newColLenArr.push(colLen || "40");
//           }
//           return newColLenArr.join(",");
//         },
//       },
//     });
//     console.log("addCells returning", { ...table, cells: cells });
//     return [200, { ...table, cells: cells }];
//   } else {
//     return [400];
//   }
// });
// mock.onPut(/nodes\/move.*/).reply(function (config) {
//   const query = config.params;
//   const data = JSON.parse(config.data);

//   console.log("move query", { query, config, data });
//   let target = mockTrees[query.registryVersion]?.nodes?.[data.targetId];
//   console.log("move target", { target });
//   if (target) {
//     return [200, target.path + target.id + ","];
//   } else {
//     return [400];
//   }
// });

// mock.onDelete("packages/remove").reply(200);

// mock.onPut("packages/setPublishVersion").reply(200);

// mock.onGet("packages/upload/progress").reply(200, [
//   // {
//   //   processName: "XMLUpload",
//   //   message: "XMLUpload message",
//   //   progress: 21,
//   // },
//   // {
//   //   processName: "XLSXUpload",
//   //   message: "XLSXUpload message",
//   //   progress: 23,
//   //   error: "Error message",
//   // },
//   // {
//   //   processName: "VersionClone",
//   //   message: "XLSXUpload message",
//   //   progress: 23,
//   //   versionOnClone: 2,
//   // },
// ]);
// // when there is a draft
// //mock.onPost("packages/createDraft").reply(204);
// // 202 when there is no draft or server is still processing the draft
// mock.onPost("packages/createDraft").reply(204);
// // mock.onPost("packages/createDraft").reply(202);

// mock.onGet("packages/versions").reply(200, mockVersions);

// // TODO missing wholesalers mock
// // mock.onGet("packages/wholeSalers").reply(200, null);

// // TODO missing itemPaths mock
// // nodes/itemPaths?id=packets1&registryVersion=3_draft

// const getMockQuery = (config) => {
//   return config.params;
//   const search = config.url.substring(
//     config.url.indexOf("?"),
//     config.url.length
//   );
//   return queryString.parse(search);
// };
// const queryNodes = (config, returnAsObject) => {
//   const query = getMockQuery(config);
//   console.log("apiMock queryNodes " + config.url + " query", query, {
//     nodes: mockTrees[query.registryVersion].nodes,
//   });
//   const ids = typeof query.ids === "string" ? [query.ids] : query.ids;

//   let missingNodes = [];
//   let nodes = ids.reduce(
//     (nodes, id) => {
//       const node = mockTrees[query.registryVersion].nodes[id];
//       if (node) {
//         if (returnAsObject) {
//           nodes[node.code] = node;
//         } else {
//           nodes.push(node);
//         }
//       } else missingNodes.push(id);
//       return nodes;
//     },
//     returnAsObject ? {} : []
//   );
//   if (missingNodes.length > 0)
//     console.log("apiMock NODESQUERY missing node mocks", missingNodes);
//   console.log("apiMock " + config.url + " nodes", nodes);
//   return [200, nodes];
// };
// mock.onGet(/nodes\/itemPaths.*/).reply(function (config) {
//   const query = getMockQuery(config);
//   console.log("apiMock nodes/itemPaths query", query);
//   return [400];
// });
// mock.onGet(/nodes\/rootNodes.*/).reply(function (config) {
//   const query = getMockQuery(config);
//   console.log("apiMock nodes/rootNodes query", query);
//   return [200, mockTrees[query.registryVersion][query.root]];
// });
// mock.onGet(/nodes\/nodeItems.*/).reply((config) => queryNodes(config, true));
// mock.onGet(/nodes\/cells.*/).reply(function (config) {
//   const query = getMockQuery(config);
//   console.log("apiMock nodes/cells query", query);
//   return [200, { cells: mockTrees[query.registryVersion].cells[query.id] }];
// });
// mock.onGet(/nodes\/children.*/).reply(function (config) {
//   const query = getMockQuery(config);
//   console.log("apiMock nodes/children query", query, {
//     res: {
//       children: Object.values(mockTrees[query.registryVersion].nodes).filter(
//         (x) => x.path === query.path
//       ),
//     },
//   });
//   return [
//     200,
//     {
//       children: Object.values(mockTrees[query.registryVersion].nodes).filter(
//         (x) => x.path === query.path
//       ),
//     },
//   ];
// });
// mock.onGet(/nodes.*/).reply(queryNodes);

// // versionChanges start
// mock
//   .onGet("packages/versionChanges?root=packets&registryVersion=2")
//   .reply(200, versionChanges1_2);
// mock
//   .onGet("packages/versionChanges?root=packets&registryVersion=3")
//   .reply(200, versionChanges1_3);
// // versionChanges end

// // editing endpoints start
// mock.onPut("nodes/edit").reply(function (config) {
//   const data = JSON.parse(mock.history.put[mock.history.put.length - 1].data);
//   console.log("apiMock  nodes/edit PUT data", data);
//   return [200, data];
// });
// mock.onPost("nodes").reply(function (config) {
//   const query = getMockQuery(config);
//   const data = JSON.parse(mock.history.post[mock.history.post.length - 1].data);
//   console.log("nodes POST data", data);
//   return [
//     200,
//     data.nodeType === 1
//       ? {
//           code: data.code,
//           nodeType: 1,
//           orgNodeType: 0,
//           path: data.path,
//           id: data.code,
//           nodeText: {
//             lng: "FIN",
//             name: data.code,
//             nodeType: 0,
//             orgNodeType: 0,
//           },
//         }
//       : data.nodeType === 2
//       ? data.basePackage
//         ? {
//             ...mockTrees[query.registryVersion].nodes[data.basePackage],
//             id: data.code,
//             code: data.code,
//             path: data.path,
//           }
//         : {
//             id: data.code,
//             code: data.code,
//             path: data.path,
//             nodeType: 2,
//             items: [],
//             nodeText: {
//               lng: "FIN",
//               name: data.code,
//               nodeType: 12,
//             },
//           }
//       : data.nodeType === 6
//       ? {
//           nodeType: 6,
//           id: data.code,
//           path: data.path,
//           code: data.code,
//           colCount: 1,
//           rowCount: 1,
//           nodeText: {
//             lng: "FIN",
//             name: data.code,
//             nodeType: 0,
//             orgNodeType: 0,
//           },
//           colLen: "40",
//         }
//       : data.nodeType === 5
//       ? {
//           nodeType: 5,
//           itemType: 13,
//           code: data.code,
//           path: data.path,
//           amount: 1.0,
//         }
//       : null,
//   ];
// });
// mock.onDelete(/nodes\/delete.*/).reply(204);
// mock.onDelete("nodes/columns").reply(204);
// // editing endpoints end
// ! API MOCK END !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

//axios.defaults.timeout = 0;

export function fileUpload(url, body, setLoading, _handleErr) {
  axios({
    method: "post",
    url: url,
    data: body,
    headers: {
      "Content-Type": "multipart/form-data",
      //Credentials: "include",
      //Authorization: "Bearer " + token,
    },
    timeout: 0,
    //onUploadProgress: onUploadProgress,
  })
    .then(() => setLoading(false))
    .catch(_handleErr);
}

const ApiErrorToast = ({ toastProps, title, text }) => {
  return (
    <div>
      {title ? <h2>{title}</h2> : null}
      <p>{text}</p>
    </div>
  );
};

const handleErr = (noErrorToast) => (err) => {
  if (!noErrorToast && err?.response?.data?.displayMessage)
    toast.error(({ toastProps }) => (
      <ApiErrorToast
        toastProps={toastProps}
        title={err.response.data.displayHeader}
        text={err.response.data.displayMessage}
      />
    ));
  if (err.message === "Network Error") return err;
  else if (err.code === "ECONNABORTED") return "timeout";
  else return err;
};

const handleRes = (res) => {
  return res;
};

export const request = async (url, method, data, params, noErrorToast) => {
  return axios({
    method: method || "get",
    url: url,
    headers: {
      "content-type": "application/json",
    },
    data,
    params: {
      ...params,
      registryVersion:
        params?.registryVersion || localStorage.getItem("registryVersion"),
    },
  })
    .then(handleRes)
    .catch(handleErr(noErrorToast));
};
