import React, { useEffect, useState } from "react";
import update from "immutability-helper";

import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

import { MdRefresh, MdContentCopy, MdSort } from "react-icons/md";

import { request } from "../helpers/api";
import { onTextChange } from "../helpers/functions";

import Tooltip from "../components/Tooltip";
import FormComponent from "../components/FormComponent";
import { useTranslation } from "react-i18next";
import VirtualizedList from "../components/VirtualizedList";

import ConfirmDialog from "../components/ConfirmDialog";

import { connect } from "react-redux";
import { SET_COMMON_PROP } from "../actions/CommonActions";

import { toast } from "react-toastify";

import AutoSizer from "react-virtualized-auto-sizer";
import { Menu, MenuItem } from "@mui/material";

// TODO list doesn't update on actions
function SoftwareCompanyRegister(props) {
  const { t } = useTranslation();

  const initialInputs = {
    name: {
      type: "textField",
      label: t("companyName"),
      value: "",
      error: "",
    },
    companyId: {
      type: "textField",
      label: t("companyId"),
      value: "",
      error: "",
    },
    vatId: {
      type: "textField",
      label: t("vatId"),
      value: "",
      error: "",
      optional: true,
    },
    limitedData: {
      type: "checkbox",
      label: t("limitedData"),
      value: "",
      error: "",
      optional: true,
    },
  };

  const editInputs = {
    id: {
      label: "Id",
      type: "textField",
      value: "",
      error: "",
      readOnly: true,
    },
    ...initialInputs,
    apiKey: {
      hidden: true,
    },
  };

  const [inputs, setInputs] = useState(initialInputs);
  const [loading, setLoading] = useState("");
  const [editing, setEditing] = useState(false);
  const [dialog, setDialog] = useState({
    visible: false,
  });
  const [sort, setSort] = useState({ by: "none", order: "asc" });
  const [sortEl, setSortEl] = useState(null);

  const setDialogVisible = (visible) => {
    setDialog({
      ...dialog,
      visible,
    });
  };

  const handleClear = () => setInputs(initialInputs);

  const handleFinally = (clear = true) => {
    setLoading("");
    if (clear) {
      handleClear();
      if (editing) setEditing(false);
    }
  };

  const handleChange = (name, type, event, numeric) => {
    let _value;

    if (type === "datePicker") {
      _value = event;
    } else if (type === "checkbox") {
      _value = event.target.checked;
    } else {
      _value = onTextChange(event.target.value, numeric);
    }

    setInputs({
      ...inputs,
      [name]: {
        ...inputs[name],
        value: _value,
        error: "",
      },
    });
  };

  const validate = () => {
    let err = false;
    let _inputs = inputs;
    Object.entries(inputs).forEach(([key, val]) => {
      if (!val.optional && !val.value) {
        err = true;
        _inputs = update(_inputs, {
          [key]: { error: { $set: t("requiredErr") } },
        });
      }
    });

    if (err) {
      setInputs(_inputs);
    }
    return err;
  };

  const parseInputs = () => {
    return Object.entries(inputs).reduce((prev, [key, val]) => {
      prev[key] = val.value;
      return prev;
    }, {});
  };

  const parseClient = (client) =>
    Object.entries(client).reduce(
      (prev, [key, value]) => {
        if (prev[key]) {
          prev[key].value = value;
          // prev[key].readOnly = true;
        }
        return prev;
      },
      { ...editInputs }
    );

  const handleEdit = (client) => {
    setEditing(true);
    setInputs(parseClient(client));
  };

  const handleGet = () => {
    const doSort = (a, b) => {
      if (sort.by === "name") {
        if (sort.order === "asc") return a.name.localeCompare(b.name);
        else return b.name.localeCompare(a.name);
      }
      return 0;
    };
    request("softwareCompanies")
      .then((res) => {
        if (res.status === 200) {
          let data = sort.by === "none" ? res.data : res.data.sort(doSort);
          props.SET_COMMON_PROP({ data: data, prop: "softwareCompanies" });
          if (loading.includes("_")) {
            handleEdit(res.data.find((x) => x.id === loading.split("_")[1]));
          }
          setLoading("");
        } else if (res.status === 204) {
          props.SET_COMMON_PROP({ data: [], prop: "softwareCompanies" });
          setLoading("");
        } else if (res.status !== 204) {
          setLoading("");
        } else {
          setLoading("");
        }
      })
      .catch(() => setLoading(""));
  };

  const handleDelete = (_id) => {
    request("softwareCompanies/" + _id, "delete")
      .then((res) => {
        if (res.status === 204) {
          props.SET_COMMON_PROP({
            prop: "softwareCompanies",
            type: "removeFromArr",
            value: { id: _id },
            idProp: "id",
          });
        }
      })
      .finally(handleFinally);
  };

  const handleAdd = () => {
    if (!validate()) {
      request("softwareCompanies", "post", parseInputs())
        .then((res) => {
          if (res.status === 200) {
            props.SET_COMMON_PROP({
              prop: "softwareCompanies",
              type: "addToArr",
              value: res.data,
              idProp: "id",
            });
          }
        })
        .finally(handleFinally);
    } else {
      setLoading("");
    }
  };

  const handleEditPut = (_id) => {
    request("softwareCompanies/" + _id, "put", parseInputs())
      .then((res) => {
        if (res.status === 200) {
          handleEdit(res.data);
          handleFinally(false);
          toast.success(t("saveSuccessful"));
        } else {
          handleFinally(false);
        }
      })
      .finally(() => handleFinally(false));
  };

  const putApiKey = () => {
    request("softwareCompanies/apiKey", "put", parseInputs())
      .then((res) => {
        if (res.status === 200) {
          props.SET_COMMON_PROP({
            prop: "softwareCompanies",
            type: "setObjArrayProp",
            idValue: inputs.id.value,
            idProp: "id",
            innerProp: "apiKey",
            value: res.data,
          });
          setInputs((inputs) =>
            update(inputs, { apiKey: { value: { $set: res.data } } })
          );
          setDialog({
            title: t("apiKeyInfoTitle"),
            text: t("apiKeyInfoText"),
            cancelButtonTitle: t("close"),
            visible: true,
            hideSaveButton: true,
            disableBackdropClick: true,
            children: (
              <Box sx={{ position: "relative", paddingRight: "32px" }}>
                <Box
                  sx={{ position: "absolute", top: 32, right: 0, zIndex: 1000 }}
                >
                  <Tooltip tip={t("copyTooltip")}>
                    <button
                      className="tree-toolbar-button flex-center"
                      onClick={() => navigator.clipboard.writeText(res.data)}
                    >
                      <MdContentCopy />
                    </button>
                  </Tooltip>
                </Box>
                <TextField
                  variant="outlined"
                  label={t("apiKeyLink")}
                  defaultValue={res.data}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  sx={{
                    marginTop: "32px",
                    minWidth: "400px",
                  }}
                />
              </Box>
            ),
            fn: () => setDialogVisible(false),
          });
        }
      })
      .finally(() => setLoading(""));
  };

  const deleteApiKey = () => {
    request("softwareCompanies/apiKey", "delete", parseInputs())
      .then((res) => {
        if (res.status === 204) {
          props.SET_COMMON_PROP({
            prop: "softwareCompanies",
            type: "setObjArrayProp",
            idValue: inputs.id.value,
            idProp: "id",
            innerProp: "apiKey",
            value: "",
          });
          setInputs((inputs) =>
            update(inputs, { apiKey: { value: { $set: "" } } })
          );
        }
      })
      .finally(() => setLoading(""));
  };

  const handleSetSort = (by, order) => {
    setSort({ by, order });
    setSortEl(null);
    setLoading("get");
  };

  useEffect(() => {
    if (loading.startsWith("get")) handleGet();
    else if (loading.startsWith("delete")) handleDelete(loading.split("_")[1]);
    else if (loading.startsWith("edit")) handleEditPut(loading.split("_")[1]);
    else if (loading === "add") handleAdd();
    else if (loading === "apiKey") putApiKey();
    else if (loading === "delApiKey") deleteApiKey();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    handleGet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box
        sx={{
          width: props.width,
          height: props.height,
          display: "flex",
          overflow: "auto",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
          }}
        >
          <Stack sx={{ width: "100%" }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              p={1}
              alignItems="center"
            >
              <Typography variant="h6">{t("softwareCompanies")}</Typography>

              <Box>
                <IconButton
                  disabled={loading ? true : false}
                  onClick={() => setLoading("get")}
                >
                  <MdRefresh />
                </IconButton>
                <IconButton
                  disabled={loading ? true : false}
                  onClick={(e) => setSortEl(e.currentTarget)}
                >
                  <MdSort />
                </IconButton>
                <Menu
                  anchorEl={sortEl}
                  open={Boolean(sortEl)}
                  onClose={() => setSortEl(null)}
                >
                  <MenuItem onClick={() => handleSetSort("none", "asc")}>
                    {t("sortByNone")}
                  </MenuItem>
                  <MenuItem onClick={() => handleSetSort("name", "asc")}>
                    {t("sortByNameAsc")}
                  </MenuItem>
                  <MenuItem onClick={() => handleSetSort("name", "desc")}>
                    {t("sortByNameDesc")}
                  </MenuItem>
                </Menu>
              </Box>
            </Stack>

            <Divider />

            <AutoSizer disableHeight>
              {({ width }) => (
                <VirtualizedList
                  data={props.softwareCompanies}
                  emptyDataString={t("noClients")}
                  height={props.height - 57}
                  width={width - 1}
                  getName={(data) => data.name}
                  onItemClick={handleEdit}
                />
              )}
            </AutoSizer>
          </Stack>
        </div>

        <Divider orientation="vertical" />

        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            padding: 8,
          }}
        >
          {Object.keys(inputs).map((inputKey) => {
            const _key = `Input${inputKey}`;
            return (
              <div
                key={_key}
                style={{
                  width: "100%",
                }}
              >
                <FormComponent
                  input={inputs[inputKey]}
                  prop={inputKey}
                  handleChange={handleChange}
                  parentProps={props}
                />
              </div>
            );
          })}
          <div style={{ display: "flex", flexDirection: "row" }}>
            {editing ? (
              <>
                <div style={{ margin: 10 }}>
                  <Button
                    variant="contained"
                    disabled={loading ? true : false}
                    onClick={handleFinally}
                  >
                    {t("close")}
                  </Button>
                </div>
                <div style={{ margin: 10 }}>
                  <Button
                    variant="contained"
                    disabled={loading ? true : false}
                    onClick={() =>
                      setDialog({
                        title: t("deleteSoftwareCompany") + "?",
                        text:
                          t("deleteConfirmation") +
                          " " +
                          t("deleteSoftwareCompanyConfirmation"),
                        visible: true,
                        value: inputs.name.value,
                        fn: () => setLoading("delete_" + inputs.id.value),
                      })
                    }
                  >
                    {t("delete")}
                  </Button>
                </div>
                {inputs.apiKey?.value ? (
                  <div style={{ margin: 10 }}>
                    <Button
                      variant="contained"
                      disabled={loading ? true : false}
                      onClick={() =>
                        setDialog({
                          title: t("deleteApiKey") + "?",
                          text:
                            t("deleteConfirmation") +
                            " " +
                            inputs.name.value +
                            " " +
                            t("apiKey2"),
                          visible: true,
                          fn: () => setLoading("delApiKey"),
                        })
                      }
                    >
                      {t("deleteApiKey")}
                    </Button>
                  </div>
                ) : (
                  <div style={{ margin: 10 }}>
                    <Button
                      variant="contained"
                      disabled={loading ? true : false}
                      onClick={() => setLoading("apiKey")}
                    >
                      {t("generateApiKey")}
                    </Button>
                  </div>
                )}
                <div style={{ margin: 10 }}>
                  <Button
                    variant="contained"
                    disabled={loading ? true : false}
                    onClick={() => setLoading("edit_" + inputs.id.value)}
                  >
                    {t("save")}
                  </Button>
                </div>
              </>
            ) : (
              <Button
                variant="contained"
                disabled={loading ? true : false}
                onClick={() => setLoading("add")}
              >
                {t("add")}
              </Button>
            )}
          </div>
        </div>
      </Box>
      <ConfirmDialog
        title={dialog.title}
        open={dialog.visible}
        hideSaveButton={dialog.hideSaveButton}
        cancelButtonTitle={dialog.cancelButtonTitle}
        disableBackdropClick={dialog.disableBackdropClick}
        setOpen={setDialogVisible}
        value={dialog.value}
        onConfirm={dialog.fn}
      >
        {dialog.text + " " + (dialog.value || "")}
        {dialog.children}
      </ConfirmDialog>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    softwareCompanies: state.common.softwareCompanies,
  };
};

export default connect(mapStateToProps, {
  SET_COMMON_PROP,
})(SoftwareCompanyRegister);
