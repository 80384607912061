/**
 * Action for setting data to common reducer (state.common[prop])
 * @param {Object} payload
 * @param {Any} payload.data data to override prop with
 * @param {String} payload.prop property name
 * @returns null
 */
export const SET_COMMON_PROP = (payload) => ({
  type: "SET_COMMON_PROP",
  payload,
});

/**
 * Action for setting user settings prop (state.common.settings[prop])
 * @param {Object} payload
 * @param {Any} payload.value value to override prop with
 * @param {String} payload.prop property name
 * @returns null
 */
export const SET_SETTING = (payload) => ({
  type: "SET_SETTING",
  payload,
});
