import React, { Fragment } from "react";
import moment from "moment";
import axios from "axios";
import "moment/locale/fi";
import update, { extend } from "immutability-helper";
import queryString from "query-string";
import { PublicClientApplication } from "@azure/msal-browser";

import i18n from "./i18n"; /* eslint-disable-line no-unused-vars */
import { Redirect, Switch, BrowserRouter, Route } from "react-router-dom";

import PacketsScreen from "./screens/PacketsScreen";
import NomenclaturesScreen from "./screens/NomenclaturesScreen";
import ProductsScreen from "./screens/ProductsScreen";
import InputScreen from "./screens/InputScreen";
import SearchScreen from "./screens/SearchScreen";
import ControlPanelScreen from "./screens/ControlPanelScreen";
import VersionCompareScreen from "./screens/VersionCompareScreen";
import VersionsScreen from "./screens/VersionsScreen";

import Header from "./components/Header";

import { Provider } from "react-redux";
import { store, persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";

import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "flexlayout-react/style/light.css";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { LoginPage } from "./screens/Login";
import { msalLoginConfig } from "./components/MsLoginButton";

const theme = createTheme({
  palette: {
    primary: {
      light: "rgb(144, 209, 151)",
      main: "rgb(144, 209, 151)",
      dark: "rgb(144, 209, 151)",
      contrastText: "#000",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
    basic: {
      light: "#42a5f5",
      main: "#1976d2",
      dark: "#1565c0",
      contrastText: "#fff",
    },
    text: {
      main: "#000",
      contrastText: "#000",
    },
  },
});

/**
 * Main component, renders the whole app. test
 *
 * Url routing handled by react-router, add new url routes to allTabs and jsx return.
 *
 * Renders header component and toast library and tooltip main components.
 *
 * Waits for persist reducer to rehydrate before rendering ui.
 *
 * Axios baseURL is set here before rendering. Tries to get the baseURL for requests from /settings.json.
 *
 * Sets moment.js locale to fi.
 * @component
 * @returns JSX
 */
function App() {
  const allTabs = [
    "/packets",
    "/nomenclatures",
    "/products",
    "/input",
    "/versions",
    "/versionCompare",
    "/globalSearch",
    "/controlPanel",
  ];

  const onBeforeLift = async () => {
    moment.locale("fi");

    extend("$auto", function (value, object) {
      return object ? update(object, value) : update({}, value);
    });

    extend("$autoArray", function (value, object) {
      return object ? update(object, value) : update([], value);
    });

    extend("$autoMap", function (value, object) {
      return object ? update(object, value) : update(new Map(), value);
    });

    // let res;

    // try {
    //   res = await axios.get("/settings.json", {
    //     validateStatus: null,
    //     responseType: "json",
    //   });
    // } catch (error) {}

    // axios.defaults.https = true;
    // let baseURL = "http://10.10.10.10:8081/api/"; //"http://localhost:8080/";

    // if (res?.data?.env?.API_URL) {
    //   baseURL = res.data.env.API_URL + "api/";
    // }

    let baseURL =
      process.env.REACT_APP_API_URL || "http://10.10.10.10:8083/api"; //"http://localhost:8080/api"; //

    axios.defaults.baseURL = baseURL;
    axios.defaults.paramsSerializer = {
      encode: queryString.parse,
      serialize: queryString.stringify,
    };
    axios.interceptors.response.use(undefined, (error) => {
      console.log("rejected", error);
      if (error.response.status === 401) {
        console.log("login", window.location.href);
        window.location.href =
          "/login?redirect=" + encodeURIComponent(window.location.href);
      }
      return error;
    });

    msalLoginConfig.msalConfig = {
      auth: {
        clientId: process.env.REACT_APP_MS_CLIENT_ID, // Client ID
        authority:
          process.env.REACT_APP_MS_INSTANCE ||
          "https://login.microsoftonline.com/" +
            process.env.REACT_APP_MS_TENANT_ID, // Tenant ID of the React.JS App Registration
        redirectUri: process.env.REACT_APP_MS_REDIRECT_URI,
      },
    };

    msalLoginConfig.loginApiRequest = {
      scopes: process.env.REACT_APP_MS_SCOPES?.split(",") || [],
    };

    msalLoginConfig.msalInstance = process.env.REACT_APP_MS_CLIENT_ID
      ? new PublicClientApplication(msalLoginConfig.msalConfig)
      : null;

    if (msalLoginConfig.msalInstance) {
      axios.interceptors.request.use(
        async (config) => {
          try {
            const accounts = msalLoginConfig.msalInstance.getAllAccounts();

            const account = accounts[0];
            if (account) {
              const msalResponse =
                await msalLoginConfig.msalInstance.acquireTokenSilent({
                  ...msalLoginConfig.loginApiRequest,
                  account: account,
                });
              if (msalResponse.accessToken) {
                config.headers[
                  "Authorization"
                ] = `Bearer ${msalResponse.accessToken}`;
              }
            }
            return config;
          } catch (error) {
            console.error("msal acquireTokenSilent error", error);
            return config;
          }
        },
        (err) => {
          return Promise.reject(err);
        }
      );
    }

    return true;
  };

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider
        dateAdapter={AdapterMoment}
        // adapterLocale={localeMap[locale]}
      >
        <Provider store={store}>
          <PersistGate persistor={persistor} onBeforeLift={onBeforeLift}>
            <BrowserRouter>
              <Switch>
                <Route path="/login" component={LoginPage} />
                <Route
                  path="/"
                  render={({ location }) => {
                    return (
                      <Fragment>
                        <Header
                          location={location}
                          allTabs={allTabs}
                          tabValue={
                            location.pathname === "/"
                              ? "/packets"
                              : location.pathname
                          }
                        />

                        <Switch>
                          <Route
                            path={"/nomenclatures"}
                            component={NomenclaturesScreen}
                          />
                          <Route
                            path={"/products"}
                            component={ProductsScreen}
                          />
                          <Route path={"/input"} component={InputScreen} />
                          <Route
                            path={"/versions"}
                            component={VersionsScreen}
                          />
                          <Route
                            path={"/versionCompare"}
                            component={VersionCompareScreen}
                          />
                          <Route
                            path={"/globalSearch"}
                            component={SearchScreen}
                          />
                          <Route
                            path={"/controlPanel"}
                            component={ControlPanelScreen}
                          />
                          <Route path={"/packets"} component={PacketsScreen} />
                          <Redirect from="/" to="/packets" />
                        </Switch>
                      </Fragment>
                    );
                  }}
                />
                <div className="tooltip"></div>
              </Switch>
            </BrowserRouter>
            <ToastContainer
              position="top-center"
              transition={Slide}
              // bodyClassName="toastBody"
            />
          </PersistGate>
        </Provider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
