import React, { useRef, useState, useEffect } from "react";
import { AutoSizer, Column, Table } from "react-virtualized";

import { withStyles } from "@mui/styles";
import Stack from "@mui/material/Stack";
import Popover from "@mui/material/Popover";
import clsx from "clsx";
import Draggable from "react-draggable";

import { HiDotsVertical } from "react-icons/hi";

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import {
  useSortable,
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable";

const styles = (theme) => ({
  flexContainer: {
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
  },
  tableCell: {
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    flex: 1,
    // color: "inherit",
  },
  tableCellHover: {
    "&:hover": {
      backgroundColor: "#ffcc1b",
      // color: "inherit",
    },
    cursor: "pointer",
  },
  tableRow: {
    //cursor: "pointer",
    // color: "#fff",
  },
  tableRowHover: {
    "&:hover": {
      backgroundColor: "#ffcc1b",
      color: "#000",
    },
    cursor: "pointer",
  },
});

const Header = ({
  label,
  columnIndex,
  dataKey,
  handleHeaderClick,
  columns,
  handleResize,
  handleDragStop,
  widths,
  columnReorder,
  handleHeaderAuxClick,
  headerStyle,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: dataKey, disabled: !columnReorder });

  const style = {
    ...headerStyle,
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: transform ? "grabbing" : columnReorder ? "grab" : undefined,
  };

  const canHandleHeaderClick = handleHeaderClick ? true : false;
  return (
    <React.Fragment key={dataKey}>
      <span
        title={label}
        id={"VirtualizedTableCol" + columnIndex}
        key={dataKey}
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
        className="ReactVirtualized__Table__headerTruncatedText"
        onClick={(ev) => {
          if (canHandleHeaderClick) handleHeaderClick(ev, label, columnIndex);
        }}
        onContextMenu={(ev) => {
          ev.preventDefault();
          handleHeaderAuxClick(ev, columnIndex);
        }}
      >
        {label}
      </span>
      {columnIndex !== columns.length - 1 ? (
        <Draggable
          axis="x"
          onDrag={(event, { deltaX }) => handleResize(dataKey, deltaX)}
          onStop={() => handleDragStop(widths)}
          position={{ x: 0 }}
          zIndex={999}
          defaultClassName="DragHandle"
          defaultClassNameDragging="DragHandleActive"
        >
          <div className="DragHandleIcon">
            <HiDotsVertical />
          </div>
        </Draggable>
      ) : null}
    </React.Fragment>
  );
};

const HeaderRenderer = (props) => {
  return (
    <SortableContext
      id={props.dataKey}
      items={props.columns.map((x) => x.dataKey)}
      strategy={horizontalListSortingStrategy}
    >
      <Header {...props} />
    </SortableContext>
  );
};

const DEFAULT_MIN_WIDTH_CELL = 20;
const DEFAULT_MAX_WIDTH_CELL = 600;

function VirtualizedTable(props) {
  const {
    classes,
    rowHeight = 48,
    enableHeader,
    rowHover,
    headerHeight = 48,
    width,
    height,
    ...tableProps
  } = props;
  const [columns, setColumns] = useState([]);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const clickedHeader = useRef();
  const [anchorEl, setAnchorEl] = useState();
  const [widths, setWidths] = useState({});

  useEffect(() => {
    setColumns(props.columns);
    setWidths(
      props.columns.reduce((acc, cur) => {
        acc[cur.dataKey] = 100 / props.columns.length / 100;
        return acc;
      }, {})
    );
  }, [props.columns]);

  // function loadColumnInfoLocalStorage() {
  //   let columnsInfo = localStorage.getItem(props.id + "Columns");
  //   if (columnsInfo) {
  //     setWidths(JSON.parse(columnsInfo));
  //   }
  // }

  const handleDragStop = (_widths) => {
    return;
    // localStorage.setItem(props.id + "Columns", JSON.stringify(_widths));
  };

  const handleResize = (_width) => (dataKey, deltaX) => {
    setWidths((prevState) => {
      const prevWidths = prevState;
      const percentDelta = deltaX / _width;

      const nextDataKey =
        columns[columns.findIndex((x) => x.dataKey === dataKey) + 1].dataKey;

      const minWidth = DEFAULT_MIN_WIDTH_CELL;
      const maxWidth = DEFAULT_MAX_WIDTH_CELL;

      const newPercentDelta = prevWidths[dataKey] + percentDelta;
      const newNextPercentDelta = prevWidths[nextDataKey] - percentDelta;

      const newWidth = newPercentDelta * _width;
      const newNextWidth = newNextPercentDelta * _width;

      if (
        newWidth >= minWidth &&
        newWidth <= maxWidth &&
        newNextWidth >= minWidth &&
        newNextWidth <= maxWidth
      ) {
        return {
          ...prevWidths,
          [dataKey]: newPercentDelta,
          [nextDataKey]: newNextPercentDelta,
        };
      } else {
        return prevState;
      }
    });
  };

  useEffect(() => {
    // loadColumnInfoLocalStorage();
  }, []);

  const getRowClassName =
    (rowHover) =>
    ({ index }) => {
      return clsx(classes.tableRow, classes.flexContainer, {
        [classes.tableRowHover]: rowHover ? index !== -1 : false,
      });
    };

  const cellRenderer = ({ cellData, columnIndex }) => {
    return (
      <div
        component="div"
        className={clsx(classes.tableCell, classes.tableCellHover)}
        variant="body"
        style={{ height: rowHeight }}
        align={
          (columnIndex != null && columns[columnIndex].numeric) || false
            ? "right"
            : "center"
        }
      >
        {cellData}
      </div>
    );
  };

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      setColumns((_columns) => {
        const oldIndex = _columns.findIndex((x) => x.dataKey === active.id);
        const newIndex = _columns.findIndex((x) => x.dataKey === over.id);
        const newColumns = arrayMove(_columns, oldIndex, newIndex);
        props.onColumnChange && props.onColumnChange(newColumns);
        return newColumns;
      });
    }
  }

  const handleHeaderAuxClick = (ev, columnIndex) => {
    if (ev.button === 2) {
      clickedHeader.current = columnIndex;
      setAnchorEl(ev.target);
    }
  };

  const closePopovers = () => {
    setAnchorEl(null);
  };

  const handleHeaderOptionClick = (dataKey) => (ev) => {
    setColumns((_columns) => {
      // TODO handle new column
      const oldIndex = _columns.findIndex((x) => x.dataKey === dataKey);
      const newIndex = clickedHeader.current;
      const newColumns = arrayMove(_columns, oldIndex, newIndex);
      props.onColumnChange && props.onColumnChange(newColumns);
      return newColumns;
    });
    closePopovers();
  };

  return (
    <>
      <AutoSizer disableHeight={!!height} disableWidth={!!width}>
        {(dimensions) => {
          const _width = width || dimensions.width;
          return (
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <Table
                height={height || dimensions.height}
                width={_width}
                rowHeight={rowHeight}
                headerHeight={headerHeight}
                {...tableProps}
                rowClassName={getRowClassName(rowHover)}
                onRowClick={props.handleRowClick}
              >
                {columns.map(({ dataKey, label, ...other }, index) => (
                  <Column
                    key={dataKey}
                    headerRenderer={
                      enableHeader
                        ? (headerProps) => (
                            <HeaderRenderer
                              {...headerProps}
                              headerStyle={props.headerStyle}
                              columnIndex={index}
                              handleHeaderClick={props.handleHeaderClick}
                              columns={columns}
                              handleResize={handleResize(_width)}
                              handleDragStop={handleDragStop}
                              widths={widths}
                              columnReorder={props.columnReorder}
                              handleHeaderAuxClick={handleHeaderAuxClick}
                            />
                          )
                        : undefined
                    }
                    // className={classes.flexContainer}
                    width={widths[dataKey] * _width}
                    cellRenderer={
                      props.cellRenderer ? props.cellRenderer : cellRenderer
                    }
                    dataKey={dataKey}
                    label={label}
                    columnData={other}
                  />
                ))}
              </Table>
            </DndContext>
          );
        }}
      </AutoSizer>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={closePopovers}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div className="navigation-content-body nav-menu">
          <Stack spacing={1}>
            {columns.map((column, i) => (
              <div
                key={column.dataKey}
                className="navigation-item"
                onClick={handleHeaderOptionClick(column.dataKey)}
              >
                {column.label}
              </div>
            ))}
          </Stack>
        </div>
      </Popover>
    </>
  );
}

export default withStyles(styles)(VirtualizedTable);
