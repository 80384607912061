import React from "react";
import { useTranslation } from "react-i18next";

import { getName, getNodeIcon } from "../helpers/functions";

import { MdClose } from "react-icons/md";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

export default function ClipboardCard(props) {
  const { t } = useTranslation();
  return (
    <Card sx={{ minWidth: 275, position: "fixed", bottom: 40, right: 40 }}>
      <CardContent>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h5">
            {t("clipboardCardTitle") + " " + props.nodes.length}
          </Typography>
          <IconButton
            onClick={() => {
              props.removeAll();
            }}
          >
            <MdClose />
          </IconButton>
        </Stack>
        <Divider />
        <Box sx={{ maxHeight: 300, overflow: "auto" }}>
          <List>
            {props.nodes.map((x, i) => {
              return (
                <ListItem key={`${i}${x.id}`} disablePadding>
                  <div className="node-icon">
                    {getNodeIcon(false, x.nodeType, x.type)}
                  </div>
                  <ListItemText primary={getName(x)} />
                  <IconButton
                    onClick={() => {
                      props.removeOne(x);
                    }}
                  >
                    <MdClose />
                  </IconButton>
                </ListItem>
              );
            })}
          </List>
        </Box>
      </CardContent>
    </Card>
  );
}
