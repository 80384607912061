import { Box } from "@mui/material";
import _ from "lodash";

import msIconDark from "../ms-symbollockup_signin_dark.svg";

export const msalLoginConfig = {
  msalInstance: null,
  msalConfig: null,
  loginApiRequest: null,
};

/**
 * Microsoft login button
 */
export default function MsLoginButton({ theme }) {
  const handleAzureLogin = async (loginType) => {
    if (loginType === "popup") {
      msalLoginConfig.msalInstance
        .loginPopup(msalLoginConfig.loginApiRequest)
        .then(async (res) => {
          const params = new URLSearchParams(window.location.search);
          const redirect = params.get("redirect");
          window.location.href = redirect || "/";
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  return (
    <Box
      component="img"
      className="ms-button"
      src={msIconDark}
      alt="ms-sign-in-button"
      onClick={() => handleAzureLogin("popup")}
      width="216px"
      maxWidth="216px"
      sx={{ cursor: "pointer" }}
    />
  );
}
