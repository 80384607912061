import React from "react";

import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function LinearProgressWithLabel(props) {
  return (
    <Box width="100%" display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export default function ProgressIndicator({ title, errorMessage, progress }) {
  return (
    <div style={{ margin: 10 }}>
      <Typography variant="h6">{title}</Typography>
      {errorMessage ? (
        <Typography variant="h6" color="error">
          {errorMessage}
        </Typography>
      ) : (
        title && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <ClimbingBoxLoader size={8} />
            <Box
              sx={{
                display: "flex",
                flex: 1,
              }}
            >
              <LinearProgressWithLabel value={parseInt(progress || 0)} />
            </Box>
          </Box>
        )
      )}
    </div>
  );
}
