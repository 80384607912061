import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useStyles } from "../components/StyledComponents";
import WindowWrapper from "../components/WindowWrapper";

import { useTranslation } from "react-i18next";

import { SET_COMMON_PROP } from "../actions/CommonActions";
import { connect } from "react-redux";

import {
  SET_VERSION_CHANGES,
  SET_REGISTRY_VERSIONS,
} from "../actions/VersionsActions";

function VersionCompareScreen(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  let history = useHistory();
  let location = useLocation();
  let searchParams = new URLSearchParams(location.search);
  const root = searchParams.get("treeRoot") || "";
  const registryVersion = searchParams.get("registryVersion") || "";
  const oldRegistryVersion = searchParams.get("oldRegistryVersion") || "";

  const getURL = (
    _root = root,
    _version1 = oldRegistryVersion,
    _version2 = registryVersion
  ) => {
    return `${history.location.pathname}?treeRoot=${_root}&registryVersion=${_version2}&oldRegistryVersion=${_version1}`;
  };

  const renderHeader = () => {
    return (
      <Paper
        square
        className={classes.content}
        sx={{
          position: "fixed",
          top: 64,
          left: 0,
          width: "100%",
          zIndex: 1000,
        }}
      >
        <Select
          value={root}
          onChange={(ev) => history.replace(getURL(ev.target.value))}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem value={""} disabled>
            {t("pickRegister")}
          </MenuItem>
          <MenuItem key={"RootMenuItem" + 0} value={"packets"}>
            {t("PacketsLabel")}
          </MenuItem>
          <MenuItem key={"RootMenuItem" + 1} value={"tables"}>
            {t("inputLabel")}
          </MenuItem>
          <MenuItem key={"RootMenuItem" + 2} value={"nomenclatures"}>
            {t("nomenclaturesLabel")}
          </MenuItem>
        </Select>
        <Select
          value={oldRegistryVersion}
          onChange={(ev) => {
            if (registryVersion < ev.target.value) {
              history.replace(getURL(undefined, ev.target.value, ""));
            } else {
              history.replace(getURL(undefined, ev.target.value));
            }
          }}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem value={""} disabled>
            {t("compareVersion1PlaceHolder")}
          </MenuItem>
          {props?.registryVersions?.versions
            ? Object.keys(props.registryVersions.versions).map((x, i) =>
                !registryVersion || x < registryVersion ? (
                  <MenuItem key={"VersionMenuItem" + i} value={x}>
                    {"v." + x}
                  </MenuItem>
                ) : null
              )
            : null}
        </Select>
        <Select
          value={registryVersion}
          onChange={(ev) => {
            if (oldRegistryVersion > ev.target.value) {
              history.replace(getURL(undefined, "", ev.target.value));
            } else {
              history.replace(getURL(undefined, undefined, ev.target.value));
            }
          }}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem value={""} disabled>
            {t("compareVersion2PlaceHolder")}
          </MenuItem>
          {props?.registryVersions?.versions
            ? Object.keys(props.registryVersions.versions).map((x, i) =>
                !oldRegistryVersion || x > oldRegistryVersion ? (
                  <MenuItem key={"VersionMenuItem" + i} value={x}>
                    {"v." + x}
                  </MenuItem>
                ) : null
              )
            : null}
        </Select>
      </Paper>
    );
  };
  return (
    <>
      {renderHeader()}
      <WindowWrapper
        t={t}
        uiSettingsProp={"versionCompareScreen"}
        generateFlexLayoutJson={() => ({
          global: {
            tabEnableRename: true,
            tabSetEnableClose: true,
          },
          borders: [
            {
              type: "border",
              location: "top",
              barSize: 64 + 50,
              children: [],
            },
          ],
          layout: {
            type: "row",
            children: [
              {
                type: "row",
                children: [
                  {
                    type: "tabset",
                    children: [
                      {
                        type: "tab",
                        name: t("versionCompareTree"),
                        component: "nodeTree",
                        enableClose: false,
                        config: {
                          editable: false,
                          search: true,
                          loading: false,
                          useVersionInRequests: false,
                          disableRootChange: true,
                          enableVersionChanges: true,
                          nodeFormConfig: {
                            disableEditing: true,
                            compare: true,
                            reqistryVersionQueryParam: "registryVersion",
                            reqistryVersionQueryParam2: "oldRegistryVersion",
                          },
                        },
                      },
                      {
                        type: "tab",
                        name: t("list"),
                        component: "versionCompareList",
                        enableClose: false,
                        config: {},
                      },
                    ],
                  },
                ],
              },
              {
                type: "tabset",
                children: [
                  {
                    id: "nodeForm",
                    type: "tab",
                    name: "",
                    component: "node",
                    enableClose: false,
                    config: {},
                  },
                ],
              },
            ],
          },
        })}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state.versions,
    lng: "FIN",
    wholeSalers: state.common.wholeSalers,
    tree: state.trees,
  };
};

export default connect(mapStateToProps, {
  SET_REGISTRY_VERSIONS,
  SET_VERSION_CHANGES,
  SET_COMMON_PROP,
})(VersionCompareScreen);
