import React from "react";
import TextField from "@mui/material/TextField";
import Tooltip from "./Tooltip";

export default function TooltipInput(props) {
  return (
    <Tooltip tip={props.tip || props.label}>
      <TextField {...props} />
    </Tooltip>
  );
}
