import React from "react";
import moment from "moment";
import {
  FaBox,
  FaBoxOpen,
  FaTable,
  FaTools,
  FaDotCircle,
  FaMoneyBill,
  FaQuestion,
  FaUserTie,
} from "react-icons/fa";
import {
  RiFolderFill,
  RiFolderOpenFill,
  RiFolderForbidFill,
} from "react-icons/ri/";

import update from "immutability-helper";

import diff from "recursive-diff";

import {
  offset,
  shift,
  flip,
  arrow,
  computePosition,
} from "@floating-ui/react-dom";

export const insertPopup = (placement, elId, el2Id, arrowId, overlayId) => {
  const el = document.getElementById(elId);
  const el2 = document.getElementById(el2Id);
  const arrowEl = document.getElementById(arrowId);
  const overlay = document.getElementById(overlayId);
  computePosition(el, el2, {
    strategy: "fixed",
    placement,
    middleware: [
      offset(10),
      shift({ padding: 16 }),
      flip(),
      arrow({ element: arrowEl }),
    ],
  }).then(({ x, y, placement, middlewareData }) => {
    Object.assign(el2.style, {
      left: `${x}px`,
      top: `${y}px`,
      visibility: "visible",
    });
    Object.assign(overlay.style, {
      display: "block",
    });
    // arrow
    const { x: arrowX, y: arrowY } = middlewareData.arrow;
    const staticSide = {
      top: "bottom",
      right: "left",
      bottom: "top",
      left: "right",
    }[placement.split("-")[0]];

    Object.assign(arrowEl.style, {
      left: arrowX != null ? `${arrowX}px` : "",
      top: arrowY != null ? `${arrowY}px` : "",
      right: "",
      bottom: "",
      [staticSide]: "-4px",
    });
  });
};

export const roots = {
  packetsIV: "packets",
  packetsLV: "packets",
  packetsER: "packets",
  nomenclaturesIV: "nomenclatures",
  nomenclaturesLV: "nomenclatures",
  nomenclaturesER: "nomenclatures",
  tablesIV: "tables",
  tablesLV: "tables",
  tablesER: "tables",
  products: "products",
};

export const rootToURL = {
  packets: "packets",
  nomenclatures: "nomenclatures",
  tables: "input",
  products: "products",
};

export function sum(arr) {
  var res = 0;
  for (var x of arr) {
    res += x;
  }
  return res;
}

// cell types
// 10 = packet
// 13 = work
// 3 = product
// 14 = text

// zone
// L = LV,
// I = IV,
// E = ER

// public enum NodeType
// {
//     NewType = 0,
//     Folder = 1,
//     Structure = 2,
//     StrucItem = 3, NOT USED ANYWHERE
//     ItemTree = 4,
//     Item = 5,
//     Table = 6,
//     Supplier = 7,
//     Cell = 8,
//     LangValue = 9,
//     Language = 10,
//     Name = 11,
//     Translate = 12,
//     FolderTable = 13 NOT USED ANYWHERE
// }

// public enum Types
// {
// Folder = 1,
// ProductInPacket = 3,
// WorkFolder = 4,
// Item = 5,
// Table = 6,
// Supplier = 7,
// Packet = 10,
// WorkInPacket = 13,
// TableTextCell = 14,
// Unknown = 45,
// Other expenses = 51
// }

/**
 * Creates a tree structure from a list of paths
 * @param {Array} paths array of objects e.g. {path: ",packetsER,19ER,525ER,"}
 * @returns {Object} tree stucture with ids as keys
 */
export function pathsToTreeStructure(paths) {
  let result = [];
  let level = { result };

  paths.forEach((obj) => {
    const ids = pathToIds(obj.path);
    ids.reduce((r, id, i, a) => {
      if (!r[id]) {
        r[id] = { result: [] };
        r.result.push({
          id,
          children: r[id].result,
          parents: ids.slice(0, i),
        });
      }

      return r[id];
    }, level);
  });

  return result;
}

/**
 * Curried change handler for inputs
 * @param {*} editable
 * @param {*} key
 * @param {*} numeric
 * @param {*} idProp
 * @param {*} addToArray
 * @param {*} removeFromArray
 * @param {*} format
 * @param {*} setValues
 * @param {*} setErrors
 * @param {*} initialObject
 * @param {*} overrideFn
 * @returns {function} returns a function that accepts value parameter
 */
export function handleObjectChange(
  editable,
  key,
  numeric,
  idProp,
  addToArray,
  removeFromArray,
  format,
  setValues,
  setErrors,
  initialObject,
  overrideFn
) {
  return (value) => {
    if (editable) {
      let prop;
      const splitKey = key.split(".");
      prop = `${splitKey.join(".$auto.")}`;

      const _value = format ? format(value) : value;

      setErrors && setErrors((_errors) => ({ ..._errors, [key]: false }));
      if (overrideFn) {
        overrideFn(key, numeric, idProp, addToArray, removeFromArray, _value);
      } else {
        setValues((_values) =>
          update(
            _values || initialObject || {},
            unflatten({
              [prop]:
                addToArray || removeFromArray
                  ? {
                      $autoArray: addToArray
                        ? { $push: [_value] }
                        : {
                            $apply: (x) => {
                              return x.filter((__value) =>
                                idProp
                                  ? __value[idProp] !== _value[idProp]
                                  : __value !== _value
                              );
                            },
                          },
                    }
                  : { $set: idProp ? _value : onTextChange(_value, numeric) },
            })
          )
        );
      }
    }
  };
}

/**
 * Unflatten helper
 * Sets the value on result. Location of value is defined by the provided path.
 * @param {*} result
 * @param {*} param1
 * @returns
 */
const setAtPath = (result, [path, value]) => {
  const keys = path.split(/\.|(\[\d\])/).filter((k) => !!k);
  resolvePath(keys, value, result);
  return result;
};
/**
 * Unflatten helper
 * @param {*} result
 * @param {*} param1
 * @returns
 */
const resolvePath = (keys, finalValue, result) => {
  if (keys.length) {
    const key = cleanKey(keys.shift());

    if (!result[key]) {
      const nextKey = keys[0];
      result[key] = getNextValue(nextKey, finalValue);
    }
    return resolvePath(keys, finalValue, result[key]);
  }
};
/**
 * Unflatten helper
 * @param {*} result
 * @param {*} param1
 * @returns
 */
const isArray = (entry, prefix) => {
  return entry && entry[0] && entry[0].charAt(prefix.length) === "[";
};
/**
 * Unflatten helper
 * @param {*} result
 * @param {*} param1
 * @returns
 */
const prepareResult = (entry, prefix) => (isArray(entry, prefix) ? [] : {});
/**
 * Unflatten helper
 * @param {*} result
 * @param {*} param1
 * @returns
 */
const cutPrefix = (key, prefix) =>
  prefix ? key.substring(prefix.length) : key;
/**
 * Unflatten helper
 * @param {*} result
 * @param {*} param1
 * @returns
 */
const cleanKey = (key) => (isArrayIndex(key) ? extractIndex(key) : key);
/**
 * Unflatten helper
 * @param {*} result
 * @param {*} param1
 * @returns
 */
const isArrayIndex = (key) => key.startsWith("[");
/**
 * Unflatten helper
 * @param {*} result
 * @param {*} param1
 * @returns
 */
const extractIndex = (key) => key.substring(1, key.length - 1);
/**
 * Unflatten helper
 * @param {*} result
 * @param {*} param1
 * @returns
 */
const getNextValue = (nextKey, finalValue) => {
  return nextKey ? (isArrayIndex(nextKey) ? [] : {}) : finalValue;
};

/**
 * Unflatten object
 * @param {*} result
 * @param {*} param1
 * @returns
 */
export function unflatten(map, prefix = "", result) {
  const entries = Object.entries(map).filter(([key]) => key.startsWith(prefix));

  if (!result) {
    result = prepareResult(entries[0], prefix);
  }

  return entries
    .map(([key, value]) => [cutPrefix(key, prefix), value])
    .reduce(setAtPath, result);
}

export const pathToIds = (path) => path.substr(1, path.length - 2).split(",");

export const typeToTitle = {
  1: "Folder",
  3: "ProductInPacket",
  4: "WorkFolder",
  5: "Item",
  6: "Table",
  7: "Supplier",
  10: "Packet",
  13: "WorkInPacket",
};
export const nodeTypeToTitle = {
  0: "NewType",
  1: "Folder",
  2: "Structure",
  3: "StrucItem",
  4: "ItemTree",
  5: "Item",
  6: "Table",
  7: "Supplier",
  8: "Cell",
  9: "LangValue",
  10: "Language",
  11: "Name",
  12: "Translate",
  13: "FolderTable",
};

export function byString(o, s) {
  if (typeof s === "string") {
    s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
    s = s.replace(/^\./, ""); // strip a leading dot
    var a = s.split(".");
    for (var i = 0, n = a.length; i < n; ++i) {
      var k = a[i];
      if (o) {
        if (k in o) {
          o = o[k];
        } else {
          return;
        }
      } else return;
    }
    return o;
  } else return "";
}

export const chunk = (arr, size) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  );

export function onTextChange(text, numeric, maxDecimals, maxValue, minValue) {
  let _value = "";
  if (numeric) {
    let decimalIndex = null;
    for (let i = 0; i < text.length; i++) {
      const char = text[i];
      if (/[0-9]/g.test(char)) {
        if (maxDecimals && decimalIndex !== null) {
          if (i - decimalIndex < maxDecimals + 1) {
            _value += char;
          }
        } else {
          _value += char;
        }
      } else if (char === ",") {
        if (decimalIndex === null) _value += ".";
        decimalIndex = i;
      } else if (char === ".") {
        if (decimalIndex === null) _value += char;
        decimalIndex = i;
      } else if (char === "-" && i === 0) {
        _value += char;
      }
    }
    if (maxValue && Number(_value) > maxValue) {
      _value = maxValue.toString();
    }
    // if (minValue && Number(_value) < minValue) {
    //   _value = minValue.toString();
    // }
    // _value = Number(_value); // coercion to number breaks values like 1. which should be allowed so user can type decimals
  } else {
    _value = text.replace(/\r?\n|\r/g, "");
  }
  return _value;
}

function capitalize(s) {
  if (typeof s !== "string") return s; // return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
}

function isEmptyObj(obj) {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) return false;
  }

  return true;
}

export function getChanges(change, lng) {
  let propsChanges = [];
  if (change?.self?.action === 2) {
    propsChanges.push({
      id: change.id,
      prop: 2,
    });
  } else if (change?.self?.action === 3) {
    propsChanges.push({
      id: change.id,
      prop: 3,
      oldValue: change?.self?.oldValue,
    });
  } else {
    for (const [id, value] of Object.entries(change.props)) {
      if (
        id === "NodeTranslates" &&
        value?.props?.[lng]?.props?.Name?.newValue
      ) {
        propsChanges.push({
          id: change.id,
          prop: "name",
          change: value?.props?.[lng]?.props?.Name,
        });
      } else if (id === "Items" && value) {
        propsChanges.push({
          id: change.id,
          prop: "items",
          change: value,
        });
      } else {
        propsChanges.push({
          id: change.id,
          prop: id,
          change: value,
        });
      }
    }
  }
  return propsChanges;
}

export function checkIfArr(arr) {
  return arr && Array.isArray(arr) && arr.length > 0;
}

// ! DEPRECATED
/**
 * Returns node modified to the earliest registryVersion by using versionChanges
 * @param {Object} versionChanges versionChanges Object
 * @param {String} node node to modify
 * @return {Object} Returns modified node
 */
export function createNodeWithChanges(
  version1,
  version2,
  versionChanges,
  node = {},
  old
) {
  const changes = versionChanges[node.id];

  let _node = {
    ...node,
    nodeRegistryVersion: old ? version1 : version2,
    changedValues: [],
  };

  if (_node.nodeType === 5 && _node.treeParentId) {
    const parentChanges = versionChanges[_node.treeParentId];

    if (
      old &&
      parentChanges?.props?.Items?.newValue?.some((x) => x.id === _node.id)
    ) {
      return null;
    } else if (
      !old &&
      parentChanges?.props?.Items?.oldValue?.some((x) => x.id === _node.id)
    ) {
      return null;
    }
  }
  if (!changes) {
    return _node;
  }
  if (changes?.self?.action === 2) {
    if (old) {
      return null;
    } else {
      return _node;
    }
  } else if (changes?.self?.action === 3) {
    if (!old) {
      return null;
    } else {
      return _node;
    }
  }

  const handleNodeTextChange = (id, _value) => {
    if (_value?.[old ? "oldValue" : "newValue"] !== undefined) {
      _node.nodeText = {
        lng: "FIN",
        name: _value[old ? "oldValue" : "newValue"],
        nodeType: 12,
      };

      _node.changedValues.push({
        id: "nodeText" + id,
        changedValue: _value[old ? "oldValue" : "newValue"],
      });
    }
  };

  // eslint-disable-next-line no-unused-vars
  for (const [id, value] of Object.entries(node)) {
    if (
      id === "nodeText" &&
      changes?.props?.NodeTranslates?.props?.FIN?.props
    ) {
      Object.entries(changes?.props?.NodeTranslates?.props?.FIN?.props).forEach(
        ([id, _value]) => {
          handleNodeTextChange(id, _value);
        }
      );
    } else if (id === "supplier") {
      Object.entries(_node.supplier).forEach(([id, _value]) => {
        const changedValue =
          changes?.props.Supplier?.props[
            [id.charAt(0).toUpperCase() + id.slice(1)]
          ]?.[old ? "oldValue" : "newValue"];

        if (changedValue !== undefined) {
          _node.supplier = { ..._node.supplier, [id]: changedValue };
          _node.changedValues.push({ id: id + id, changedValue });
        }
      });
    } else if (id === "items" && changes?.props?.Items) {
      const oldItems = changes.props.Items.oldValue || [];
      const newItems = changes.props.Items.newValue || [];

      if (old) {
        _node.items = oldItems;
      } else {
        _node.items = newItems;
      }
      // if (checkIfArr(oldItems)) {
      //   _node.items = _node.items.filter(
      //     (x) => !oldItems.some((y) => y.id === x.id)
      //   );
      // }
      // if (checkIfArr(newItems)) {
      //   _node.items = _node.items.concat(newItems);
      // }
    } else {
      const changedValue =
        changes?.props[id.charAt(0).toUpperCase() + id.slice(1)]?.[
          old ? "oldValue" : "newValue"
        ];

      if (changedValue !== undefined) {
        _node[id] = changedValue;
        _node.changedValues.push({
          id,
          changedValue,
        });
      } else {
        _node[id] = value;
      }
    }
  }

  return _node;
}

/**
 * Query Version changes obj with path
 * @param {Object} versionChanges versionChanges Object
 * @param {String} path path to query with
 * @return {Array} Returns found keys
 */
export function getDeletedNodesWithPath(versionChanges, path) {
  let found = [];

  if (!versionChanges) return found;

  for (const [id, value] of Object.entries(versionChanges)) {
    if (value?.path && value.path === path && value.self?.action === 3) {
      found.push({
        id,
        node: { ...value.self?.oldValue, nodeRegistryVersion: value.version },
      });
    }
  }

  return found;
}

/**
 * Query Version changes obj with id to get Deleted items
 * @param {Object} versionChanges versionChanges Object
 * @param {String} id id to query with
 * @return {Array} Returns found keys
 */
export function getDeletedItemsWithId(versionChanges, id) {
  let found = [];
  const changes = versionChanges?.[id];

  // TODO check if not looping everything affects anything
  if (!changes) return found;

  const oldValue = changes.props?.Items?.oldValue;
  const newValue = changes.props?.Items?.newValue || [];
  if (changes.props?.Items?.action === 0 && checkIfArr(oldValue)) {
    oldValue.forEach((x) => {
      if (!newValue.some((y) => y.id === x.id)) {
        found.push({ ...x, self: 3 });
      }
    });
  }

  // for (const [id, value] of Object.entries(versionChanges)) {
  //   const oldValue = value?.props?.Items?.oldValue;
  //   const newValue = value?.props?.Items?.newValue || [];
  //   if (
  //     id === id &&
  //     value?.props?.Items?.action === 0 &&
  //     checkIfArr(oldValue)
  //   ) {
  //     oldValue.forEach((x) => {
  //       if (!newValue.some((y) => y.id === x.id)) {
  //         found.push({ ...x, self: 3 });
  //       }
  //     });
  //   }
  // }

  return found;
}

/**
 * Query Version changes obj with id to check if item has been added to it or not
 * @param {Object} versionChanges versionChanges Object
 * @param {String} parentId id to query versionChanges with
 * @param {String} itemId items id
 * @return {Boolean} Returns Boolean
 */
export function checkIfItemIsAddedItem(versionChanges, parentId, itemId) {
  if (!versionChanges) return false;

  if (
    versionChanges[parentId] &&
    versionChanges[parentId].props?.Items?.newValue?.some(
      (x) => x.id === itemId
    ) &&
    !versionChanges[parentId].props?.Items?.oldValue?.some(
      (x) => x.id === itemId
    )
  ) {
    return true;
  } else {
    return false;
  }
}

// ! DEPRECATED
/**
 * Query Version changes obj with path
 * @param {Object} versionChanges versionChanges Object
 * @param {String} path path to query with
 * @return {Array} Returns found keys
 */
export function getItemsToCompare(versionChanges, item) {
  // let found = [];
  // for (const [id, value] of Object.entries(versionChanges)) {
  //   if (value.paths.length > 0) {
  //     value.paths.forEach((pathObj) => {
  //       if (pathObj.path === path) found.push({ id, node: value.oldValue });
  //     });
  //   }
  // }
  // return found;
}

/**
 * Tries to get background color for node from versionChanges.
 * Modified = orange
 * Added = green
 * Removed = red
 * Not found = null
 * @param {Object} versionChanges versionChanges Object
 * @param {String} path path to query with
 * @return {Any} Returns null if theres no changes with the id or a hex color
 */
export function getVersionChangeNodeBGColor(versionChanges, id) {
  if (!versionChanges) return null;
  const found = versionChanges[id];
  if (found) {
    if (found.self?.action === 2) return "#b9d4b4";
    else if (found.self?.action === 3) return "#c79999";
    else return "#ebc96c";
  }
}

/**
 * Uses nodeType to return a string to display in treeview
 * @param {Object} data node data
 * @return {String} string to display in treeview
 */
export function getName(data, root, wholeSalers) {
  const { nodeType, nodeText } = data;

  let retval = "";

  const code = data?.code && data.code !== "0" ? data.code : "MISSING CODE";
  const name = nodeText?.name || "";
  const unit = data?.duration
    ? "h"
    : data?.suppliers?.[0]?.unit ||
      data?.suppliers?.[0]?.nodeText?.unit ||
      data?.unit ||
      nodeText?.unit ||
      "";
  const amount = data?.duration || data?.amount || "";

  if (data?.missing || !data?.id) retval = `${code} MISSING DATA`;
  else if (nodeType === 1 && (data.path === undefined || data.path === ","))
    retval = name;
  else {
    switch (nodeType) {
      case 1:
        if (root === "packets") {
          retval = name;
        } else {
          retval = `${code} ${name}`;
        }
        break;
      case 2:
        retval = `${code} ${name} ${unit}`;
        break;
      case 4:
        retval = name;
        break;
      case 5:
        if (data) {
          retval = `${code} ${name} ${amount} ${unit}`;
        } else {
          retval = name;
        }
        break;
      case 6:
        retval = `${code} ${name}`;
        break;
      case 7:
        const wholeSaler =
          wholeSalers?.find((x) => x.id === data.id)?.name || "VW";
        const priceValidFrom = data.priceValidFrom
          ? moment(data.priceValidFrom).format("DD.MM.YYYY")
          : "";

        retval = `${wholeSaler} ${data.unitPrice}€/${unit} ${priceValidFrom}`;
        break;
      default:
        retval = data.id;
    }
  }

  // if (true) {
  //   retval = data.id + " " + retval;
  // }

  return retval;
}

/**
 * Uses nodeType to return a JSX icon to display in treeview
 * @param {Object} isOpen is node opened in treeview
 * @param {Object} nodeType node type
 * @param {Object} itemType if node = item, its itemType is needed
 * @return {JSX} Icon to display in treeview
 */
export function getNodeIcon(isOpen, nodeType, itemType, isEmpty) {
  if (nodeType === 1 || nodeType === 4) {
    return isEmpty ? (
      <RiFolderForbidFill />
    ) : isOpen ? (
      <RiFolderOpenFill />
    ) : (
      <RiFolderFill />
    );
  } else if (nodeType === 2) return isOpen ? <FaBoxOpen /> : <FaBox />;
  else if (nodeType === 5) {
    if (itemType === 3) return <FaDotCircle />;
    else if (itemType === 10) return isOpen ? <FaBoxOpen /> : <FaBox />;
    else if (itemType === 13) return <FaTools />;
    else if (itemType === 45) return <FaQuestion />;
    else if (itemType === 51) return <FaMoneyBill />;
    else return <FaDotCircle />;
  } else if (nodeType === 6) return <FaTable />;
  else if (nodeType === 7) return <FaUserTie />;
  else return <FaDotCircle />;
}

/**
 * Compares two array items props
 * @param {Object} item1 props obj
 * @param {Object} item2 new props obj
 * @return {Boolean} boolean result, do the items match
 */
function compareArrItems(item1 = {}, item2 = {}) {
  const item1Keys = Object.keys(item1);
  const item2Keys = Object.keys(item2);

  if (item1Keys.length !== item2Keys.length) return false;

  for (let i = 0; i < item1Keys.length; i++) {
    const key = item1Keys[i];
    if (item1[key] !== item2[key]) return false;
  }

  return true;
}
//const found = oldOldValue.find(x => compareArrItems(newArrItem, x));

/**
 * Merges oldValue and newValue arrays, oldValue will be merged
 * @param {Object} old props obj
 * @param {Object} new new props obj
 * @return {Object} merged props obj
 */
function mergePropsArrays(
  oldOldValue = [],
  oldNewValue = [],
  newOldValue = [],
  newNewValue = []
) {
  let mergedOldValues = oldOldValue;
  let mergedNewValues = oldNewValue;

  for (let index = 0; index < newOldValue.length; index++) {
    const newArrItem = newOldValue[index];

    const foundInOldNewValues = oldNewValue
      ? oldNewValue.findIndex((x) => x.id === newArrItem.id)
      : -1;
    const foundInOldOldValues = oldOldValue
      ? oldOldValue.findIndex((x) => x.id === newArrItem.id)
      : -1;
    const foundInNewNewValues = newNewValue
      ? newNewValue.findIndex((x) => x.id === newArrItem.id)
      : -1;
    const foundInNewOldValues = newOldValue
      ? newOldValue.findIndex((x) => x.id === newArrItem.id)
      : -1;

    // if item is added and then deleted, remove it from oldNew and newOld
    if (
      foundInOldOldValues === -1 &&
      foundInOldNewValues !== -1 &&
      foundInNewOldValues !== -1 &&
      foundInNewNewValues === -1
    ) {
      mergedNewValues.splice(foundInOldNewValues, 1);
      newOldValue.splice(foundInNewOldValues, 1);
    }
    // if item is only found in newOldValues, push it
    else if (
      foundInOldOldValues === -1 &&
      foundInOldNewValues === -1 &&
      foundInNewOldValues !== -1 &&
      foundInNewNewValues === -1
    ) {
      mergedOldValues.push(newArrItem);
    }
  }

  for (let index = 0; index < newNewValue.length; index++) {
    const newArrItem = newNewValue[index];

    const foundInOldNewValues = oldNewValue
      ? oldNewValue.findIndex((x) => x.id === newArrItem.id)
      : -1;
    const foundInOldOldValues = oldOldValue
      ? oldOldValue.findIndex((x) => x.id === newArrItem.id)
      : -1;
    const foundInNewNewValues = newNewValue
      ? newNewValue.findIndex((x) => x.id === newArrItem.id)
      : -1;
    const foundInNewOldValues = newOldValue
      ? newOldValue.findIndex((x) => x.id === newArrItem.id)
      : -1;

    // item modified multiple times, replace new value
    if (
      foundInOldNewValues !== -1 &&
      foundInOldOldValues !== -1 &&
      foundInNewOldValues !== -1 &&
      foundInNewNewValues !== -1
    ) {
      // if item has been modified and then modified back, remove everything
      // if oldOldValue === newNewValue
      const compare = compareArrItems(
        newArrItem,
        oldOldValue[foundInOldOldValues]
      );
      if (compare) {
        mergedOldValues.splice(foundInOldOldValues, 1);
        mergedNewValues.splice(foundInOldNewValues, 1);
      } else {
        mergedNewValues.splice(foundInOldNewValues, 1, newArrItem);
      }
    }
    // item added and then deleted, remove from oldNew
    else if (
      foundInOldNewValues !== -1 &&
      foundInOldOldValues === -1 &&
      foundInNewOldValues !== -1 &&
      foundInNewNewValues === -1
    ) {
      mergedNewValues.splice(foundInOldNewValues, 1);
    }
    // item deleted, then added
    // if added value differs from deleted, it shows to user as modified
    // => replace new value
    // if added value is the same as deleted, remove both => nothing really happened
    else if (
      foundInOldNewValues === -1 &&
      foundInOldOldValues !== -1 &&
      foundInNewOldValues === -1 &&
      foundInNewNewValues !== -1
    ) {
      const compareRes = compareArrItems(
        newArrItem,
        oldOldValue[foundInOldOldValues]
      );

      if (compareRes) {
        mergedOldValues.splice(foundInOldOldValues, 1);
        newNewValue.splice(foundInNewNewValues, 1);
        --index;
      } else {
        mergedNewValues.push(newArrItem);
      }
    } else if (
      foundInOldNewValues === -1 &&
      foundInOldOldValues === -1 &&
      foundInNewOldValues === -1 &&
      foundInNewNewValues !== -1
    ) {
      mergedNewValues.push(newArrItem);
    }
  }

  return { mergedOldValues, mergedNewValues };
}

// if oldValue === newValue || oldAction === 3 && newAction === 2 && oldValue === newValue
// remove the prop, if parent props has no properties remove it
// if props == null and !self, remove whole thing
/**
 * Recursively gets nested merged props between two versions
 * @param {Object} props props obj
 * @param {Object} newProps new props obj
 * @return {Object} merged props obj
 */
function getRecursiveProps(props, newProps) {
  let mergedProps = {};

  const propsKeys = Object.keys(props);

  for (let index = 0; index < propsKeys.length; index++) {
    const prop = propsKeys[index];

    mergedProps[prop] = {};

    mergedProps[prop].action = newProps?.[prop]?.action ?? props[prop].action;

    const oldNewValue = props[prop].newValue;
    const oldOldValue = props[prop].oldValue;

    const newNewValue = newProps?.[prop]?.newValue;
    const newOldValue = newProps?.[prop]?.oldValue;

    let oldValue, newValue;

    if (prop.startsWith("Items") || prop.startsWith("Cells")) {
      const { mergedOldValues, mergedNewValues } = mergePropsArrays(
        oldOldValue,
        oldNewValue,
        newOldValue,
        newNewValue
      );
      oldValue = mergedOldValues;
      newValue = mergedNewValues;
    } else {
      oldValue = oldOldValue || newOldValue;
      newValue = newNewValue || oldNewValue;
    }

    if (oldValue) mergedProps[prop].oldValue = oldValue;
    if (newValue) mergedProps[prop].newValue = newValue;

    if (
      (oldValue && newValue && oldValue === newValue) ||
      ((prop.startsWith("Items") || prop.startsWith("Cells")) &&
        oldValue.length === 0 &&
        newValue.length === 0) ||
      (newProps?.[prop]?.action === 2 && props[prop].action === 3) ||
      (newProps?.[prop]?.action === 3 && props[prop].action === 2)
    ) {
      delete mergedProps[prop];
    } else {
      const propsVal =
        props[prop].props && newProps?.[prop]?.props
          ? getRecursiveProps(props[prop].props, newProps[prop].props)
          : undefined;
      if (propsVal) {
        if (isEmptyObj(propsVal)) {
          delete mergedProps[prop];
        } else {
          mergedProps[prop].props = propsVal;
        }
      }
    }
  }

  return mergedProps;
}

/**
 * Merges props from old and new registryVersion, first merges props from newProps without overwriting
 * old props keys and finally merges all nested props
 * @param {Object} props props objen
 * @param {Object} newProps new props obj
 * @return {Object} merged props obj
 */
export function mergeProps(props, newProps) {
  newProps &&
    Object.keys(newProps).forEach((x) => {
      if (!props.hasOwnProperty(x)) {
        props[x] = newProps[x];
        delete newProps[x];
      }
    });

  const recursiveProps = getRecursiveProps(props, newProps);
  return recursiveProps;
}

export function getPropsChangesFromDiff(lng, oldObj, newObj, difference) {
  let props = {};
  let itemsHandled = false;
  let cellsHandled = false;

  difference.forEach((x) => {
    if (x.path[0] === "nodeText") {
      if (x.path[1]) {
        props = update(props, {
          NodeTranslates: (tmpNodeTranslates) =>
            update(tmpNodeTranslates || {}, {
              action: { $set: 0 },
              props: (tmpNodeTranslatesProps) =>
                update(tmpNodeTranslatesProps || {}, {
                  [lng]: (tmpLang) =>
                    update(tmpLang || {}, {
                      action: { $set: 0 },
                      props: (tmpLangProps) =>
                        update(tmpLangProps || {}, {
                          [capitalize(x.path[1])]: {
                            $set: {
                              action: 0,
                              newValue: x.val,
                              oldValue: x.oldVal,
                            },
                          },
                        }),
                    }),
                }),
            }),
        });
      }
    } else if (x.path[0] === "items") {
      if (!itemsHandled) {
        let foundInBoth = [];
        let deletedItems = oldObj.items.filter((oldItem) => {
          const index = newObj.items.findIndex(
            (newItem) => oldItem.id === newItem.id
          );
          if (index === -1) {
            return true;
          } else {
            foundInBoth.push(oldItem);
            return false;
          }
        });
        let addedItems = newObj.items.filter((newItem) => {
          const index = oldObj.items.findIndex(
            (oldItem) => oldItem.id === newItem.id
          );
          if (index === -1) {
            return true;
          } else {
            return false;
          }
        });

        foundInBoth.forEach((oldItem, i) => {
          const newItem = newObj.items.find((x) => x.id === oldItem.id);
          if (!compareArrItems(oldItem, newItem)) {
            addedItems.push(newItem);
            deletedItems.push(oldItem);
          }
        });

        props = update(props, {
          Items: (tmpItems) =>
            update(tmpItems || {}, {
              action: { $set: 0 },
              newValue: { $set: addedItems },
              oldValue: { $set: deletedItems },
            }),
        });
        itemsHandled = true;
      }
    } else if (x.path[0] === "cells") {
      if (!cellsHandled) {
        let foundInBoth = [];
        let deletedItems = oldObj.cells.filter((oldItem) => {
          const index = newObj.cells.findIndex(
            (newItem) =>
              oldItem.row === newItem.row && oldItem.col === newItem.col
          );
          if (index === -1) {
            return true;
          } else {
            foundInBoth.push(oldItem);
            return false;
          }
        });
        let addedItems = newObj.cells.filter((newItem) => {
          const index = oldObj.cells.findIndex(
            (oldItem) =>
              oldItem.row === newItem.row && oldItem.col === newItem.col
          );
          if (index === -1) {
            return true;
          } else {
            return false;
          }
        });

        foundInBoth.forEach((oldItem) => {
          const newItem = newObj.cells.find(
            (x) => x.row === oldItem.row && x.col === oldItem.col
          );
          const cellDiff = diff.getDiff(newItem, oldItem, true);
          if (cellDiff.length !== 0) {
            addedItems.push(newItem);
            deletedItems.push(oldItem);
          }
        });

        props = update(props, {
          Cells: (tmpItems) =>
            update(tmpItems || {}, {
              action: { $set: 0 },
              newValue: { $set: addedItems },
              oldValue: { $set: deletedItems },
            }),
        });
        cellsHandled = true;
      }
    } else {
      const _updateString = `${x.path.reduce(
        (prev, path) => prev + capitalize(path) + ".",
        ""
      )}$set`;
      props = update(
        props,
        unflatten({
          [_updateString]: {
            action: 0,
            oldValue: x.oldVal,
            newValue: x.val,
          },
        })
      );
    }
  });

  return props;
}

export function getChangesObj(
  lng,
  oldObj,
  newObj,
  difference,
  registryVersion
) {
  return {
    id: newObj.id,
    path: newObj.path,
    props: getPropsChangesFromDiff(lng, oldObj, newObj, difference),
    registryVersion: registryVersion,
  };
}

/**
 * (Simplified explanation) Merges versionChanges between two versions
 * @param {Array} versionChangesArr versionChanges between versions in an array
 * @param {Number} version1 comparing start registryVersion
 * @param {Number} version2 comparing end registryVersion
 * @return {Object} merged versionChanges obj
 */
export function mergeVersionChanges(
  lng = "FIN",
  versionChangesArr,
  version1,
  version2
) {
  try {
    let mergedVersionChanges = {};
    const _version1 =
      typeof version1 === "string" ? version1.split("_")[0] : version1;

    for (let i = parseInt(_version1) + 1; i <= parseInt(version2); i++) {
      const versionChanges = versionChangesArr.find(
        (x) =>
          x &&
          parseInt(
            typeof x.registryVersion === "string"
              ? x.registryVersion.split("_")[0]
              : x.registryVersion
          ) === i
      );
      if (versionChanges) {
        Object.keys(versionChanges.data).forEach((x) => {
          const mergedChange = mergedVersionChanges[x];
          if (mergedChange) {
            const change = versionChanges.data[x];

            const oldSelf = mergedChange.self?.action;
            const newSelf = change.self?.action;

            if (oldSelf === 2 && newSelf === 3) {
              delete mergedVersionChanges[x];
            } else if (oldSelf === 3 && newSelf === 2) {
              const oldObj = mergedChange.self?.oldValue;
              const newObj = change.self?.newValue;
              if (oldObj && newObj) {
                const difference = diff.getDiff(oldObj, newObj, true);
                if (difference.length === 0) {
                  delete mergedVersionChanges[x];
                } else {
                  mergedVersionChanges[x] = {
                    ...getChangesObj(lng, oldObj, newObj, difference, i),
                    registryVersion: i,
                  };
                }
              } else {
                delete mergedVersionChanges[x];
              }
            } else if (oldSelf === 2 && !newSelf) {
              const nodeWithChanges = createNodeWithChanges(
                null,
                i,
                versionChanges.data,
                mergedChange.self.newValue,
                false
              );
              mergedVersionChanges[x].self.newValue = nodeWithChanges;
            } else {
              mergedVersionChanges[x].self = change.self;

              const merged = mergeProps(mergedChange.props, change.props);

              if (newSelf !== 2 && newSelf !== 3 && isEmptyObj(merged)) {
                delete mergedVersionChanges[x];
              } else {
                mergedVersionChanges[x].props = merged;
                mergedVersionChanges[x].registryVersion = i;
              }
            }
          } else {
            mergedVersionChanges[x] = {
              ...versionChanges.data[x],
              registryVersion:
                versionChanges.data[x]?.self?.action === 3 ? i - 1 : i,
            };
          }
        });
      }
    }

    return mergedVersionChanges;
  } catch (error) {
    throw error;
  }
}
