import update from "immutability-helper";

export default function versionsReducer(
  state = {
    registryVersions: null,
    versionChanges: {},
  },
  action
) {
  try {
    if (action.type === "SET_REGISTRY_VERSIONS") {
      const { data } = action.payload;
      return update(state, {
        registryVersions: {
          $set: data,
        },
      });
    }
    if (action.type === "SET_REGISTRY_VERSION") {
      const { data, registryVersion } = action.payload;

      return update(state, {
        registryVersions: {
          $auto: { versions: { $auto: { [registryVersion]: { $set: data } } } },
        },
      });
    } else if (action.type === "SET_PUBLISHED_VERSION") {
      const { registryVersion } = action.payload;

      return update(state, {
        registryVersions: {
          $auto: {
            $apply: (tmpRegistryVersions) =>
              update(tmpRegistryVersions, {
                publishedVersion: { $set: registryVersion },
              }),
          },
        },
      });
    } else if (action.type === "SET_VERSION_CHANGES") {
      const { root, oldRegistryVersion, registryVersion, versionChanges } =
        action.payload;

      if (versionChanges) {
        const values = Object.values(versionChanges);
        if (values.length > 0) {
          let modifiedParents = new Set();

          values.forEach((x) => {
            if (x.path && x.path.startsWith(`,${root}`)) {
              x.path
                .substring(1)
                .slice(0, -1)
                .split(",")
                .forEach((id) => {
                  modifiedParents.add(id);
                });
            }
          });

          return update(state, {
            versionChanges: {
              $auto: {
                [root]: {
                  $auto: {
                    [`${oldRegistryVersion}-${registryVersion}`]: {
                      $auto: {
                        $apply: (tmpVersion) =>
                          update(tmpVersion, {
                            $set: {
                              ...versionChanges,
                              modifiedParents:
                                modifiedParents.size > 0
                                  ? Array.from(modifiedParents)
                                  : null,
                            },
                          }),
                      },
                    },
                  },
                },
              },
            },
          });
        }
      }
    } else if (action.type === "SET_VERSION") {
      const { registryVersion } = action.payload;
      // set registryVersion to localstorage so we can use it globally without redux connection
      localStorage.setItem("registryVersion", registryVersion);
      window.dispatchEvent(new Event("storage"));
      return update(state, {
        registryVersion: { $set: registryVersion },
      });
    }

    return state;
  } catch (error) {
    console.error(
      `Error: ${error}, Reducer: versionsReducer, Action: ${
        action.type
      }, Payload: ${
        action.payload ? JSON.stringify(action.payload) : "no payload"
      }`
    );
    return state;
  }
}
