import update from "immutability-helper";

const errorReport = () => null;

const matchWithPropsArr = (a, b, arr) => arr.every((x) => a[x] === b[x]);

export const valueMatches = (action, a, b) =>
  action.idProp
    ? a[action.idProp] === b[action.idProp]
    : action.idProps
    ? matchWithPropsArr(a, b, action.idProps)
    : a === b;

export default function commonReducer(
  state = { wholeSalers: [], profile: null, settings: {} },
  action
) {
  try {
    if (action.type === "SET_COMMON_PROP") {
      const {
        data,
        type,
        prop,
        value,
        addMultiple,
        idProp,
        idValue,
        innerProp,
      } = action.payload;
      switch (type) {
        case "set":
          return update(state, { [prop]: { $set: value } });
        case "merge":
          return update(state, { $merge: value });
        case "replaceArrObj":
          return update(state, {
            [prop]: {
              $apply: (x) =>
                update(x || [], {
                  $apply: (arr) => {
                    const index = arr.findIndex((x) =>
                      valueMatches(action.payload, x, value)
                    );
                    if (index !== -1) {
                      return update(arr, { [index]: { $set: value } });
                    } else {
                      return update(arr, { $push: [value] });
                    }
                  },
                }),
            },
          });
        case "addToArr":
          return update(state, {
            [prop]: {
              $apply: (x) =>
                update(x || [], {
                  $apply: (arr) => {
                    if (
                      !arr.some((x) => valueMatches(action.payload, x, value))
                    ) {
                      return update(arr, {
                        $push: addMultiple ? value : [value],
                      });
                    } else {
                      return arr;
                    }
                  },
                }),
            },
          });
        case "addMultiple":
          return update(state, {
            [prop]: {
              $apply: (x) =>
                update(x || [], {
                  $apply: (arr) => {
                    let arrToPush = [];
                    value.forEach((y) => {
                      if (
                        !arr.some((x) => valueMatches(action.payload, x, y))
                      ) {
                        arrToPush.push(y);
                      }
                    });
                    return update(arr, {
                      $push: arrToPush,
                    });
                  },
                }),
            },
          });
        case "removeMultipleFromArr":
          try {
            return update(state, {
              [action.prop]: {
                $apply: (x) =>
                  update(x || [], {
                    $apply: (arr) => {
                      return arr.filter(
                        (x) =>
                          !value.some((y) => valueMatches(action.payload, x, y))
                      );
                    },
                  }),
              },
            });
          } catch (error) {
            errorReport({
              error,
              errorInFn: "removeMultipleFromArr",
              errorInScreen: "functions",
            });
            return state;
          }
        case "removeFromArr":
          return update(state, {
            [prop]: {
              $apply: (x) =>
                update(x || [], {
                  $apply: (arr) => {
                    const index = arr.findIndex((x) =>
                      valueMatches(action.payload, x, value)
                    );

                    if (index !== -1) {
                      return update(arr, { $splice: [[index, 1]] });
                    } else {
                      return arr;
                    }
                  },
                }),
            },
          });
        case "setObjArrayProp":
          return update(state, {
            [prop]: {
              $apply: (x) =>
                update(x || [], {
                  $apply: (arr) => {
                    const index = arr.findIndex((x) => x[idProp] === idValue);
                    if (index !== -1) {
                      return update(arr, {
                        [index]: { [innerProp]: { $set: value } },
                      });
                    } else {
                      return arr;
                    }
                  },
                }),
            },
          });
        default:
          return update(state, { [prop]: { $set: data } });
      }
    } else if (action.type === "SET_SETTING") {
      const { prop, value } = action.payload;
      return update(state, {
        settings: { $auto: { [prop]: { $set: value } } },
      });
    }

    return state;
  } catch (error) {
    console.error(
      `Error: ${error}, Reducer: CommonReducer, Action: ${
        action.type
      }, Payload: ${
        action.payload ? JSON.stringify(action.payload) : "no payload"
      }`
    );
    return state;
  }
}
