/**
 * Action for merging into nodes (state.nodes)
 * @param {Object} payload
 * @param {Array} payload.nodes merged with current nodes
 * @param {Array} payload.registryVersion
 * @returns null
 */
export const SET_NODES = (payload) => ({
  type: "SET_NODES",
  payload,
});

/**
 * Handler for nodeReplace response. Replaces nodes in node reducer. Only replaces already existing nodes.
 * Also replaces nodes in treeReducer.
 * @param {Object} payload
 * @param {Array} payload.nodes merged with current nodes and used to update children in treeReducer
 * @param {Array} payload.oldNode
 * @param {Array} payload.newNode
 * @param {Array} payload.registryVersion
 * @returns null
 */
export const REPLACE_NODES = (payload) => ({
  type: "REPLACE_NODES",
  payload,
});

/**
 * Action for saving nodes paths and associated nodes.
 * @param {Object} payload
 * @param {Array} payload.nodeId
 * @param {Array} payload.nodes
 * @param {Array} payload.paths
 * @param {Array} payload.registryVersion
 * @returns null
 */
export const SET_PATHS = (payload) => ({
  type: "SET_PATHS",
  payload,
});
