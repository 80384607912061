import React from "react";
import Tooltip from "./Tooltip";

export default function TreeToolButton({ tooltip, onClick, icon }) {
  return (
    <Tooltip tip={tooltip}>
      <button className="tree-toolbar-button flex-center" onClick={onClick}>
        {icon}
      </button>
    </Tooltip>
  );
}
