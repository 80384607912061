import update from "immutability-helper";
import orderBy from "lodash.orderby";

export default function tablesReducer(
  state = {
    cells: {},
  },
  action
) {
  try {
    if (action.type === "SET_TABLE") {
      const { table, registryVersion } = action.payload;
      if (table)
        return update(state, {
          [table.id]: { $auto: { [registryVersion]: { $set: table } } },
        });
      else return state;
    } else if (action.type === "DELETE_TABLE") {
      const { registryVersion, tableId } = action.payload;
      return update(state, {
        [tableId]: { $auto: { [registryVersion]: { $set: null } } },
        cells: {
          [tableId]: { $auto: { [registryVersion]: { $set: null } } },
        },
      });
    } else if (action.type === "REPLACE_TABLE") {
      const { table, registryVersion } = action.payload;
      if (table)
        return update(state, {
          [table.id]: { $auto: { [registryVersion]: { $set: table } } },
          cells: {
            [table.id]: (tmpTable) =>
              update(tmpTable || {}, {
                [registryVersion]: {
                  $set: orderBy(table.cells, ["row", "col"], ["asc", "asc"]),
                },
              }),
          },
        });
      else return state;
    } else if (action.type === "SET_TABLE_CELLS") {
      const { cells, registryVersion, tableId } = action.payload;
      return update(state, {
        cells: {
          [tableId]: (tmpTable) =>
            update(tmpTable || {}, {
              [registryVersion]: {
                $set: orderBy(cells, ["row", "col"], ["asc", "asc"]),
              },
            }),
        },
      });
    } else if (action.type === "REPLACE_CELL") {
      const { cell, registryVersion, tableId } = action.payload;
      return update(state, {
        cells: {
          [tableId]: (tmpTable) =>
            update(tmpTable || {}, {
              [registryVersion]: {
                $apply: (_cells) => {
                  const cellIndex = _cells.findIndex(
                    (x) => x.row === cell.row && x.col === cell.col
                  );
                  const newCells =
                    cellIndex !== -1
                      ? update(_cells, { [cellIndex]: { $set: cell } })
                      : update(_cells, { $push: [cell] });
                  return orderBy(newCells, ["row", "col"], ["asc", "asc"]);
                },
              },
            }),
        },
      });
    } else if (action.type === "ADD_ROW") {
      // increase tables row count and increase row num if cell.row >= row
      const { row, registryVersion, tableId } = action.payload;
      return update(state, {
        [tableId]: {
          $auto: {
            [registryVersion]: {
              rowCount: { $apply: (x) => x + 1 },
            },
          },
        },
        cells: {
          [tableId]: (tmpTable) =>
            update(tmpTable || {}, {
              [registryVersion]: {
                $apply: (_cells) =>
                  _cells.map((x) =>
                    x.row > row ? { ...x, row: x.row + 1 } : x
                  ),
              },
            }),
        },
      });
    } else if (action.type === "ADD_COLUMN") {
      // increase tables col count, add column width and increase col num if cell.col > col
      // if col === 0 add to the front
      const { col, registryVersion, tableId } = action.payload;
      return update(state, {
        [tableId]: {
          $auto: {
            [registryVersion]: {
              colCount: { $apply: (x) => x + 1 },
              //colLen: "22,99,117",
              colLen: {
                $apply: (x) => {
                  let _x = x.split(",");
                  _x.splice(col, 0, "40");
                  return _x.join(",");
                },
              },
            },
          },
        },
        cells: {
          [tableId]: (tmpTable) =>
            update(tmpTable || {}, {
              [registryVersion]: {
                $apply: (_cells) =>
                  _cells.map((x) =>
                    x.col > col ? { ...x, col: x.col + 1 } : x
                  ),
              },
            }),
        },
      });
    } else if (action.type === "DELETE_ROW") {
      const { row, registryVersion, tableId } = action.payload;
      if (state[registryVersion].rowCount < 2) {
        return state;
      }
      return update(state, {
        [tableId]: {
          $auto: {
            [registryVersion]: {
              rowCount: { $apply: (x) => x - 1 },
            },
          },
        },
        cells: {
          [tableId]: (tmpTable) =>
            update(tmpTable || {}, {
              [registryVersion]: {
                $apply: (_cells) =>
                  _cells.reduce((acc, cur) => {
                    if (cur.row < row) {
                      acc.push(cur);
                    } else if (cur.row > row) {
                      acc.push({ ...cur, row: cur.row - 1 });
                    }
                    return acc;
                  }, []),
              },
            }),
        },
      });
    } else if (action.type === "DELETE_COLUMN") {
      const { col, registryVersion, tableId } = action.payload;
      if (state[registryVersion].colCount < 2) {
        return state;
      }
      return update(state, {
        [tableId]: {
          $auto: {
            [registryVersion]: {
              colCount: { $apply: (x) => x - 1 },
              //colLen: "22,99,117",
              colLen: {
                $apply: (x) => {
                  let _x = x.split(",");
                  _x.splice(col - 1, 1);
                  return _x.join(",");
                },
              },
            },
          },
        },
        cells: {
          [tableId]: (tmpTable) =>
            update(tmpTable || {}, {
              [registryVersion]: {
                $apply: (_cells) =>
                  _cells.reduce((acc, cur) => {
                    if (cur.col < col) {
                      acc.push(cur);
                    } else if (cur.col > col) {
                      acc.push({ ...cur, col: cur.col - 1 });
                    }
                    return acc;
                  }, []),
              },
            }),
        },
      });
    } else if (action.type === "ADD_CELLS") {
      const { registryVersion, tableId } = action.payload;

      let table = state[tableId][registryVersion];
      let cells = state.cells[tableId][registryVersion];

      if (table && action.payload.cells.length > 0) {
        let maxRow = table.rowCount;
        let maxCol = table.colCount;

        action.payload.cells.forEach((cell) => {
          if (cell.row > maxRow) maxRow = cell.row;
          if (cell.col > maxCol) maxCol = cell.col;

          const cellIndex = cells.findIndex(
            (x) => x.col === cell.col && x.row === cell.row
          );

          // swap the value into nodeText so the field will be evaluated in backend
          const _cell = {
            ...cell,
            nodeText: { name: cell.value },
            value: undefined,
          };

          // if cell has value we can update it, otherwise remove it since we don't keep empty cells
          if (cellIndex !== -1) {
            if (cell.value) {
              cells = update(cells, {
                [cellIndex]: {
                  $set: _cell,
                },
              });
            } else {
              cells = update(cells, {
                $splice: [[cellIndex, 1]],
              });
            }
          } else if (cell.value) {
            cells = update(cells, { $push: [_cell] });
          }
        });

        return update(state, {
          [tableId]: {
            $auto: {
              [registryVersion]: {
                rowCount: { $set: maxRow },
                colCount: { $set: maxCol },
                colLen: {
                  $apply: (x) => {
                    let newColLenArr = [];
                    const splitColLen = x.split(",");
                    for (let i = 0; i < maxCol; i++) {
                      const colLen = splitColLen[i];
                      newColLenArr.push(colLen || "40");
                    }
                    return newColLenArr.join(",");
                  },
                },
              },
            },
          },
          cells: {
            [tableId]: (tmpTable) =>
              update(tmpTable || {}, {
                [registryVersion]: {
                  $set: cells,
                },
              }),
          },
        });
      } else {
        return state;
      }
    }

    return state;
  } catch (error) {
    console.error(
      `Error: ${error}, Reducer: TableCellsReducer, Action: ${
        action.type
      }, Payload: ${
        action.payload ? JSON.stringify(action.payload) : "no payload"
      }`,
      state
    );
    return state;
  }
}
