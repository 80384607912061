export const workForm = {
  data: [
    {
      title: "tagsForm1_1",
      rows: [
        [
          {
            key: "nodeText.lang",
            disabled: true,
            label: "language",
            type: "picker",
            options: ["FIN"],
            error: "",
            validation: "",
            mask: "",
          },
          // {
          //   key: "itemType",
          //   disabled: true,
          //   label: "type",
          //   type: "picker",
          //   options: [13],
          //   error: "",
          //   validation: "",
          //   mask: "",
          //   formatValue: "itemType" // doesn't work
          // },
        ],
        [
          {
            key: "code",
            label: "code",
            type: "textField",
            error: "",
            validation: "",
            mask: "",
            fill: true,
          },
        ],
        [
          {
            label: "name",
            key: "nodeText.name",
            type: "textField",
            error: "",
            validation: "",
            mask: "",
            fill: true,
          },
        ],
        [
          {
            label: "description",
            key: "nodeText.description",
            type: "textField",
            error: "",
            validation: "",
            mask: "",
            fill: true,
          },
        ],
        [
          {
            label: "jobHours",
            key: "duration",
            type: "textField",
            error: "",
            validation: "",
            mask: "",
            fill: true,
            numeric: true,
          },
        ],
        [
          {
            label: "price",
            key: "hourlyRate",
            type: "textField",
            valueFromRegistryVersion: true,
            equation: [
              { value: "this" },
              { operation: "*" },
              { value: "duration" },
            ],
            error: "",
            validation: "",
            mask: "",
            fill: true,
            numeric: true,
            disabled: true,
          },
        ],
        [
          {
            label: "unit",
            key: "nodeText.unit",
            type: "textField",
            error: "",
            validation: "",
            mask: "",
            fill: true,
          },
        ],
      ],
    },
  ],
};
