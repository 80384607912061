import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const FIN = {
  translation: {
    // tabs
    errorRenderingComponent: "Välilehden näyttämisessä tapahtui virhe",
    maximize: "Suurenna",
    restore: "Palauta",
    moveTabset: "Siirrä välilehdet",
    closeTabset: "Sulje välilehdet",
    duplicateTab: "Monista välilehti",
    newPackagesTreeTab: "Uusi pakettivälilehti",
    newProductsTreeTab: "Uusi tuotenimikevälilehti",
    newNomenclaturesTreeTab: "Uusi työnimikevälilehti",
    newTablesTreeTab: "Uusi syöttösivuvälilehti",
    // tabs
    // paave been blockedckage importer
    packageImporting: "Pakettien tuonti",
    fieldIdentification: "Kenttien tunnistus",
    itemCode: "Nimikkeen koodi",
    itemName: "Nimikkeen nimi",
    //
    versionCopyToNewVersion: "Kopioi uudeksi versioksi",
    versionConfig: "Version asetukset",
    versionEditTooltip: "Muokkaa version asetuksia, normituntikerroin yms.",
    versionCopyStarted: "Version kopiointi aloitettu",
    versionCopyFailed: "Version kopiointi epäonnistui",
    saveSuccessful: "Tallennettu",
    saveError: "Tallennuksessa tapahtui virhe",
    hourlyRate: "Normituntikerroin",
    replaceHandled: "Korvaus suoritettu",
    "You need to delete related children first":
      "sisältö tulee poistaa ennen kansion poistamista",
    "Node still has relations":
      "on vielä kytkettynä syöttösivuihin ja/tai paketteihin",
    noPathsToReplaceIn: "Ei polkuja mihin korvata",
    nodeReplaceTypeMismatch:
      "Korvattava on eri tyyppiä kuin korvaaja. Korvaaja on tyhjennetty",
    searchInfo:
      "Alkaa hakusanalla haun saa lisäämällä ^ merkin hakusanan alkuun \n^hakusana\n\nLoppuu hakusanalla haun saa lisäämällä $ merkin hakusanan loppuun \nhakusana$\n\nTäsmähaun saa lisäämällä hakusanan alkuun ^ merkin ja hakusanan loppuun $ merkin \n^hakusana$\n\nJos merkkejä ei käytä alussa tai lopussa haku etsii kaiken mikä sisältää hakusanan\n\nHakukentässä toimii myös kaikki muut regex-säännöt",
    licenceCount: "Lisenssimäärä",
    ivLicenceCount: "IV Lisenssimäärä",
    erLicenceCount: "ER Lisenssimäärä",
    lvLicenceCount: "LV Lisenssimäärä",
    softwareCompany: "Ohjelmistoyritys",
    softwareCompanies: "Ohjelmistoyritykset",
    companies: "Yritykset",
    add: "Lisää",
    versions: "Versiot",
    product: "Tuote",
    versionCompareTree: "Versiovertailupuu",
    tables: "Syöttösivut",
    register: "Rekisteri",
    settings: "Asetukset",
    collapseAllNodes: "Sulje kaikki avatut kansiot/paketit",
    packets: "Paketit",
    nomenclatures: "Työnimikkeet",
    products: "Tuotenimikkeet",
    cellFetchFailed: "Solujen haku epäonnistui",
    newProduct: "Uusi tuote",
    failedToFetchPaths: "Polkujen haku epäonnistui",
    replace: "Korvaa",
    nodeToReplace: "Korvattava",
    findNodeToReplace: "Etsi korvattava",
    replacement: "Korvaaja",
    findReplacement: "Etsi korvaaja",
    nodeToMove: "Siirrettävä",
    target: "Kohde",
    pastMonths: "Kuukausia taaksepäin",
    search: "Etsi",
    "Id/code already on use": "Koodi on jo käytössä",
    moveToRoot: "Siirrä juureen",
    addRowAbove: "Lisää 1 rivi yläpuolelle",
    addRowBelow: "Lisää 1 rivi alapuolelle",
    deleteRow: "Poista rivi",
    addColumnLeft: "Lisää 1 kolumni vasemmalle",
    addColumnRight: "Lisää 1 kolumni oikealle",
    deleteColumn: "Poista kolumni",
    failedToFetch: "Haku epäonnistui",
    noPaths: "Ei polkuja",
    packageOrItemCode: "Paketin tai tuotteen koodi",
    cell: "Solu",
    newTable: "Uusi taulu",
    newFolder: "Uusi kansio",
    newJob: "Uusi työ",
    alert: "Huomio",
    createSuccessful: "Luotu",
    createFailed: "Luonti epäonnistui",
    deleteSuccessful: "Poistettu",
    deleteFailed: "Poisto epäonnistui",
    moved: "Siirretty",
    moveFailed: "Siirto epäonnistui",
    saveFailed: "Tallennus epäonnistui",
    NewType: "Uusi tyyppi",
    Folder: "Kansio",
    Structure: "Paketti",
    StrucItem: "PakettiTuote",
    ItemTree: "Tuotekansio",
    Item: "Tuote",
    Table: "Taulukko",
    Supplier: "Tukkuliike",
    Cell: "Solu",
    LangValue: "Kieliarvo",
    Language: "Kieli",
    Name: "Nimi",
    Translate: "Käännösrakenne",
    Work: "Työ",
    Packet: "Paketti",
    amountOfPackets: "pakettia",
    ProductInPacket: "Tuote",
    WorkFolder: "Tuote",
    WorkInPacket: "Työ",
    folder: "Kansio",
    selectRoot: "Valitse rekisteri",
    selectVersion1: "Valitse versio 1",
    selectVersion2: "Valitse versio 2",
    cancel: "Peruuta",
    yes: "Kyllä",
    useTableAsBase: "Pohjana käytettävä syöttösivu",
    usePackageAsBase: "Pohjana käytettävä paketti",
    newCode: "Uusi koodi",
    noOptions: "Ei vaihtoehtoja",
    errFetchingChanges: "Muutosten haussa tapahtui virhe",
    noChangesBetweenVersions: "Ei muutoksia versioiden välillä",
    type: "Tyyppi",
    canTakeMultipleMinutes: "tähän voi mennä useita minuutteja",
    createDraft: "Luo muokkausversio",
    creatingDraft: "Editointiversion luonti kesken",
    creatingDraftOrCopy: "Editointiversion tai kopion luonti kesken",
    errorCreatingDraft: "Editointiversion luonnissa tapahtui virhe",
    testJSONCreationStarted: "Testi JSONin luonti aloitettu",
    testJSONCreationInProgress: "Testi JSONin luonti käynnissä",
    testJSONCreationError: "Testi JSONin luonnissa tapahtui virhe",
    JSONCreationStarted: "Jakelu JSONin luonti aloitettu",
    JSONCreationInProgress: "Jakelu JSONin luonti käynnissä",
    JSONCreationError: "Jakelu JSONin luonnissa tapahtui virhe",
    moveToSelected: "Siirrä valittuun",
    newSubFolder: "Uusi alakansio",
    newPackage: "Uusi paketti",
    importPackages: "Tuo paketteja",
    packageImporterTooltip:
      "Kopioi solut Excelistä ja liitä ne tähän CTRL+V näppäinkomennolla. Kolumneja voi vaihtaa raahaamalla tai hiiren oikealla napilla.",
    codeCollision: "Koodi on jo käytössä",
    packageImportSuccess: "Paketit luotu",
    packageImportError: "Pakettien luonti epäonnistui",
    packetsThatCantBeImported: "Paketit joita ei voitu luoda",
    packetsThatCanBeImported: "Paketit jotka voitaisiin luoda",
    packagePreviewCreationError: "Pakettien esikatselun luonti epäonnistui",
    move: "Siirrä",
    toTarget: "Kohteeseen",
    moveNotPermitted: "Siirto tähän kohteeseen ei sallittu",
    cantMoveToSelf: "Ei voida siirtää itsensä sisälle",
    cantMoveItemsNotInOwnRoot: "Ei voida siirtää tuotteita", // muualla kuin tuotenimikkeissä",
    cantMoveJobsNotInOwnRoot:
      "Ei voida siirtää työnimikkeitä muualle kuin työnimikkeisiin",
    paths: "Polut",
    addNodeToPackage: "Lisää uusi tuote/työ",
    browse: "Selaa",
    edit: "Muokkaa",
    discount: "Ale %",
    discountGroup: "Alennusryhmä",
    origCost: "Alkuperäinen hinta",
    vatId: "ALV-tunniste",
    apiKey: "API-avain",
    apiKey2: "API-avaimen",
    requiredErr: "Arvo on pakollinen",
    deleteSoftwareCompanyConfirmation: "ohjelmistoyrityksen:",
    deleteClientConfirmation: "asiakkaan:",
    noClients: "Ei lisättyjä asiakkaita",
    noVersionChanges: "Ei muutoksia versioiden välillä",
    noVersions: "Ei versioita",
    noVersionToDistribute: "Ei versiota jakelussa",
    searchPlaceholder: "Etsi…",
    generateApiKey: "Generoi API-avain",
    fetch: "Hae",
    searchLabel: "Haku",
    searchTextTooShort: "Hakusanan tulee olla yli 3 merkkiä",
    controlPanel: "Hallinta",
    publishVersionConfirmation: "Haluatko varmasti julkaista version:",
    deleting: "Poistetaan",
    moving: "Siirretään",
    moveConfirmation: "Haluatko varmasti siirtää",
    moveFinished: "Siirto käsitelty",
    deleteConfirmation: "Haluatko varmasti poistaa",
    deletionFinished: "Poisto käsitelty",
    salesPrice: "Hinta",
    tagsForm3_9: "Hinta voimassa",
    tagsForm3_1: "Hintatyyppi",
    distrubutionJSONCreated: "Jakelu JSON luotu",
    distributionBegan: "Jakelu aloitettu",
    distributionEnded: "Jakelu loppui",
    publishVersion: "Julkaise versio?",
    folderTitle: "Kansio",
    folderTitleInput: "Kansion nimi",
    tagsForm3_8: "Käyttöyksikön kustannus",
    duration: "Kesto",
    language: "Kieli",
    col: "Kolumni",
    comment: "Kommentti",
    code: "Koodi",
    tagsForm3Title: "Kustannustiedot:",
    description: "Kuvaus",
    uploadFile: "Lähetä tiedosto",
    uploadNewExcel: "Lähetä uusi tuotelista",
    uploadNewVersion: "Lähetä uusi versio",
    added: "Lisätty",
    list: "Lista",
    amount: "Määrä",
    modified: "Muokattu",
    priceValidFrom: "Hinta voimassa alkaen",
    convFactor: "Muunnoskerroin",
    price: "Myyntihinta",
    name: "Nimi",
    nomenclature: "Nimike",
    tagsForm2_5: "Nimikkeen EAN koodi",
    tagsForm2Title: "Nimikkeen tiedot",
    tagsForm3_2: "OVH hinta - alennus",
    update: "Päivitä",
    PacketsLabel: "Paketit",
    delete: "Poista",
    deleted: "Poistettu",
    deleteApiKey: "Poista API-avain",
    deleteSoftwareCompany: "Poista ohjelmistoyritys",
    apiKeyLink: "Linkki API-avaimen lataamiselle",
    apiKeyInfoTitle: "Tässä linkki API-avaimen lataamiselle",
    apiKeyInfoText:
      "Tämä linkki on näkyvillä vain siihen asti kunnes suljet tämän pop-upin. Linkki on käytettävissä vain kerran ja se vanhenee 3 päivän kuluttua. Älä avaa linkkiä, toimita linkki suoraan ohjelmistoyritykselle.",
    deleteClient: "Poista asiakas",
    deleteVersion: "Poista versio",
    deleteDraftVersion: "Poista muokkausversio",
    selectVersionForViewing: "Valitse selaukseen",
    selectVersionForEditing: "Valitse muokkaukseen",
    copyTooltip: "Kopioi arvo",

    downloadTestDistributionJSON: "Lataa testijakelu JSON",
    createTestDistributionJSON: "Luo testijakelu JSON",
    limitedDistributionJSON: "Rajoitettu JSON",
    distributionJSON: "Jakelu JSON",
    testDistributionJSON: "Testijakelu JSON",
    limitedTestDistributionJSON: "Rajoitettu testi JSON",
    downloadDistributionJSON: "Lataa jakelu JSON",
    createDistributionJSON: "Luo jakelu JSON",
    createDistributionJSONDialogTitle: "Haluatko varmasti luoda uuden JSONin?",
    createDistributionJSONDialogInfo:
      "Uuden jakelu JSONin luonti voi vaikuttaa sisältöön jos generointiin on tehty muutoksia.",
    createSampleDataFromDistributionJSONTooltip:
      "Valikoi kourallisen paketteja JSONiin koko jakelu JSONin asemesta, ei vaikutusta jakeluun",
    limitedData: "Rajoitettu aineisto",
    deletionFailed: "poistaminen epäonnistui",
    deletionSuccess: "poistettu",
    movingFailed: "siirto epäonnistui",
    clipboardCardTitle: "Valittu",
    removed: "Poistettu",
    dragNdrop: "Pudota tiedosto tähän tai klikkaa valitaksesi tiedoston",
    tree: "Puu",
    pickRegister: "Valitse rekisteri",
    row: "Rivi",
    structTitle: "Paketin tiedot",
    path: "Polku",
    cellValue: "Solun sisältö",
    cells: "Solut",
    close: "Sulje",
    tagsForm2_2: "Suomi",
    inputLabel: "Syöttösivut",
    back: "Takaisin",
    save: "Tallenna",
    err: "Tapahtui virhe",
    supplier: "Tavarantoimittaja",
    bytes: "tavua",
    nodetranslates: "Teksti",
    file: "Tiedosto",
    fileUploadErr: "Tiedoston lähetys epäonnistui.",
    tagsForm1_3: "Toim. ERP koodi",
    tagsForm2_1: "Toim. koodi nimikkeelle",
    unitPrice: "Toim. myyntihinta",
    tagsForm1_2: "Toim. nimi",
    tagsForm2_3: "Toim. nimi nimikkeelle",
    tagsForm1_4: "Toim. OVT koodi",
    tagsForm2_4: "Toim. tietokortti",
    wholeSaler: "Toimittaja",
    tagsForm1Title: "Toimittajan tiedot",
    tagsForm1_1: "Toimittajan tiedot",
    tagsForm3_6: "Toimituserän kustannus",
    productField: "Tuotealue",
    productsLabel: "Tuotenimikkeet",
    itemInfo: "Tuotetiedot",
    items: "Tuotteet",
    amountOfItems: "tuotetta",
    nomenclatureTypePicker1: "Työ",
    job: "Työ",
    nomenclaturesLabel: "Työnimikkeet",
    jobHours: "Työtunnit",
    new: "Uusi",
    chooseFile: "Valitse tiedosto",
    old: "Vanha",
    compareVersion1PlaceHolder: "Valitse versio 1",
    compareVersion2PlaceHolder: "Valitse versio 2",
    version: "Versio",
    currentVersionTitle: "Versio jakelussa",
    versionCompareLabel: "Versioiden vertailu",
    selectedVersionNoLongerExists:
      "Selattavaksi / muokattavaksi valittua versiota ei enää ole. Versioksi asetettu uusin versio",
    versionsLabel: "Versiointi",
    versionPosession: "Version",
    deleteVersionConfirmation: "version:",
    handlingInProgress: "Version käsittely kesken",
    compareMethod: "Vertailutapa",
    validFrom: "Voimassa alkaen",
    validTo: "Voimassa asti",
    companyId: "Y-tunnus",
    unit: "Yksikkö",
    companyName: "Yrityksen nimi",
    import: "Tuo",
    freeSearch: "Vapaa haku",
    missingNodes: "Puuttuvat tuotteet",
    expiringProducts: "Vanhentuvat tuotteet",
    discontinuedProducts: "Valikoimasta poistuneet tuotteet",
    sortByNone: "Ei järjestystä",
    sortByNameAsc: "Nimen mukaan nouseva",
    sortByNameDesc: "Nimen mukaan laskeva",
    "/packets": "Paketit",
    "/nomenclatures": "Työnimikkeet",
    "/products": "Tuotenimikkeet",
    "/input": "Syöttösivut",
    "/versions": "Versiot",
    "/versionCompare": "Versiovertailu",
    "/globalSearch": "Haku",
    "/controlPanel": "Hallinta",
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      FIN,
    },
    lng: "FIN",
    fallbackLng: "FIN",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
