import React, { useMemo, useState, useRef, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import debounce from "lodash.debounce";

import { makeStyles } from "@mui/styles";
import { MdSearch, MdClear } from "react-icons/md";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import CircularProgress from "@mui/material/CircularProgress";

import VirtualizedList from "./VirtualizedList";
import Form from "./Form";
import TreeToolButton from "./TreeToolButton";

import { MdRefresh, MdQuestionMark } from "react-icons/md";

import { getName, getNodeIcon } from "../helpers/functions";
import {
  requestExpiringProducts,
  requestMissingNodes,
  requestSearch,
  requestDiscontinuedProducts,
} from "../helpers/requests";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  search: (drawerWidth) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    width: drawerWidth,
    height: 56,
    // backgroundColor: fade(theme.palette.common.black, 0.1),
    // "&:hover": {
    //   backgroundColor: fade(theme.palette.common.black, 0.15),
    // },
  }),
  icon: {
    padding: theme.spacing(0, 1),
    height: "100%",
    //position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    // vertical padding + font size from icon
    height: "100%",
    flex: 1,
    backgroundColor: "red",
    transition: theme.transitions.create("width"),
    display: "flex",
    alignSelf: "stretch",
    // [theme.breakpoints.up("md")]: {
    //   width: "20ch",
    // },
  },
}));

const searchForm = {
  data: [
    {
      // title: "newTable",
      rows: [
        [
          {
            key: "pastMonths",
            type: "textField",
            label: "pastMonths",
            error: "",
            validation: "",
            mask: "",
            fill: true,
            numeric: true,
          },
        ],
      ],
    },
  ],
};
// TODO handle registryVersion change
export default function Search(props) {
  const { open, treeRoot } = props;

  let location = useLocation();
  let searchParams = new URLSearchParams(location.search);
  const urlSearchOption = searchParams.get("option");
  const urlSearch = searchParams.get("q");
  let history = useHistory();
  const { t } = useTranslation();

  const classes = useStyles(props.width);

  const input = useRef();

  const [searchTextError, setSearchTextError] = useState("");
  const [searchOption, setSearchOption] = useState("");
  const [searching, setSearching] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchRes, setSearchRes] = useState(null);
  const [monthsFilter, setMonthsFilter] = useState("1");
  const [forceSearch, setForceSearch] = useState(0);

  const registryVersion =
    props.registryVersion || localStorage.getItem("registryVersion");
  const registryVersionRef = useRef(registryVersion);

  const getURL = (
    _searchText = searchText,
    _searchOption = searchOption,
    _monthsFilter = monthsFilter
  ) => {
    return `${history.location.pathname}?q=${_searchText}&option=${_searchOption}&monthsFilter=${_monthsFilter}`;
  };

  useEffect(() => {
    if (location.search) {
      if (urlSearchOption !== "general") {
        setSearchOption(urlSearchOption);
      }
      if (urlSearch) {
        setSearchText(urlSearch);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSearching, setSearchOption, location.search]);

  useEffect(() => {
    if (open) {
      input.current.focus();
    }
  }, [input, open]);

  const fetch = useMemo(
    () =>
      debounce((_searchText, search, callback) => {
        let _searchParams = new URLSearchParams(search);
        requestSearch(
          _searchText,
          registryVersion,
          _searchParams,
          setSearchRes,
          setSearching
        );
      }, 500),
    [registryVersion]
  );

  useEffect(() => {
    const handleStorage = () => {
      const _registryVersion =
        props.registryVersion || localStorage.getItem("registryVersion");
      if (_registryVersion !== registryVersionRef.current) {
        registryVersionRef.current = _registryVersion;
        setSearchRes(null);
        setForceSearch((x) => x + 1);
      }
    };

    window.addEventListener("storage", handleStorage);
    return () => window.removeEventListener("storage", handleStorage);
  }, [props.registryVersion]);

  useEffect(() => {
    if (searchOption === "missingNodes") {
      setSearching(true);
      requestMissingNodes(
        treeRoot === "tmp" ? "" : treeRoot,
        registryVersion,
        setSearchRes,
        setSearching
      );
    } else if (searchOption === "expiringProducts") {
      setSearching(true);
      requestExpiringProducts(
        monthsFilter,
        props.registryVersion,
        setSearchRes,
        setSearching
      );
    } else if (searchOption === "discontinuedProducts") {
      setSearching(true);
      requestDiscontinuedProducts(
        props.registryVersion,
        setSearchRes,
        setSearching
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchOption, forceSearch]);

  const handleSelect = (ev) => {
    history.replace(getURL(undefined, ev.target.value));
  };

  const handleChange = (event) => {
    const val = event.target.value;

    if (val.length < 3) {
      setSearchTextError(t("searchTextTooShort"));
    } else {
      if (searchTextError) {
        setSearchTextError(false);
      }
      setSearching(true);
      fetch(val, location.search, setSearchRes);
    }
    setSearchText(val);
    history.replace(getURL(val));
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleChange(event);
    }
  };

  const handleClear = () => {
    if (searchTextError) {
      setSearchTextError(false);
    }
    setSearchText("");
    setSearchRes(null);
    history.replace(history.location.pathname);
  };

  const _setSearchItem = (node) => {
    props.setSearchItem(node, props.tabConfig);
  };

  const searchingExpiredProducts = urlSearchOption === "expiringProducts";
  return (
    <div className={classes.root}>
      <Box sx={{ position: "absolute", top: 0, right: 0, zIndex: 1000 }}>
        <TreeToolButton
          tooltip={t("searchInfo")}
          icon={<MdQuestionMark size="18px" />}
        />
      </Box>

      <Box sx={{ paddingRight: "32px" }}>
        <TextField
          fullWidth
          inputRef={input}
          placeholder={t("searchPlaceholder")}
          disabled={!!searchOption}
          value={searchText}
          inputProps={{ "aria-label": "search" }}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          error={!!searchTextError}
          InputProps={{
            startAdornment: (
              <IconButton
                onClick={() => {
                  history.replace(getURL());
                }}
              >
                <MdSearch />
              </IconButton>
            ),
            endAdornment: (
              <>
                {searching ? (
                  <IconButton>
                    <CircularProgress color="inherit" size={24} />
                  </IconButton>
                ) : (
                  <IconButton onClick={handleClear}>
                    <MdClear />
                  </IconButton>
                )}
              </>
            ),
          }}
        />
      </Box>

      <Stack direction="row">
        <Select
          fullWidth
          value={searchOption}
          onChange={handleSelect}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem key={"SearchOptionMenuItem" + 0} value={""}>
            {t("freeSearch")}
          </MenuItem>
          <MenuItem key={"SearchOptionMenuItem" + 1} value={"missingNodes"}>
            {t("missingNodes")}
          </MenuItem>
          <MenuItem key={"SearchOptionMenuItem" + 2} value={"expiringProducts"}>
            {t("expiringProducts")}
          </MenuItem>
          <MenuItem
            key={"SearchOptionMenuItem" + 3}
            value={"discontinuedProducts"}
          >
            {t("discontinuedProducts")}
          </MenuItem>
        </Select>
        <Stack direction="row">
          {searchingExpiredProducts && (
            <TextField
              fullWidth
              id="outlined-uncontrolled"
              label={t("pastMonths")}
              value={monthsFilter}
              onChange={(event) => {
                setMonthsFilter(event.target.value);
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  setSearching(true);
                  requestExpiringProducts(
                    monthsFilter,
                    props.registryVersion,
                    setSearchRes,
                    setSearching
                  );
                }
              }}
            />
          )}

          <IconButton
            disabled={searching ? true : false}
            onClick={() => setForceSearch((x) => x + 1)}
          >
            <MdRefresh />
          </IconButton>
        </Stack>
      </Stack>

      {searchingExpiredProducts && false ? (
        <Form
          t={t}
          open={true}
          form={searchForm}
          handleSubmit={(values) => {
            // setRequesting({
            //   loading: "new",
            //   params: { ...values, nodeType, type },
            // });
            // setFormDialog((cur) => ({ ...cur, loading: true }));
          }}
          handleClose={() => null}
          treeRoot={props.treeRoot}
          registryVersion={props.registryVersion}
          onSave={() => null}
          editable
          saveBtnTitle={t("search")}
          saveBtnIcon={<MdSearch />}
        />
      ) : null}

      {searchRes && (
        <VirtualizedList
          data={searchRes}
          height={props.height - 56 - 56}
          width={props.width}
          getName={(data) => {
            return getName(data, props.treeRoot) || data.code;
          }}
          getIcon={getNodeIcon}
          onItemClick={_setSearchItem}
        />
      )}
    </div>
  );
}

Search.defaultProps = {
  drawer: true,
};
