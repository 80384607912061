import React from "react";
import { useLocation } from "react-router-dom";

import Box from "@mui/material/Box";

import Form from "./Form";

import { workForm } from "../forms/workForm";
import { itemForm } from "../forms/itemForm";
import { packageForm } from "../forms/packageForm";
import { folderForm } from "../forms/folderForm";
import { tableForm } from "../forms/tableForm";

import Table from "./Table";

export default function NodeForm(props) {
  let location = useLocation();
  let searchParams = new URLSearchParams(location.search);
  const treeRoot = props.treeRoot || searchParams.get("root");
  const registryVersion =
    props.registryVersion ||
    searchParams.get(props.reqistryVersionQueryParam ?? "registryVersion");
  const registryVersion2 =
    props.registryVersion2 ||
    searchParams.get(props.reqistryVersionQueryParam2 ?? "registryVersion2");
  const form = !props.type
    ? folderForm
    : props.type === 1
    ? folderForm
    : props.type === 10
    ? packageForm
    : props.type === 6
    ? tableForm
    : props.type === 13
    ? workForm
    : props.type === 3 || props.type === 45
    ? itemForm
    : null;
  if (!form) return null;
  return props.nodeType === 6 && props.renderTable ? (
    <Table
      nodeId={props.nodeId}
      treeRoot={treeRoot}
      reduxRoot={props.reduxRoot || treeRoot}
      registryVersion={registryVersion}
      onCellClick={props.onCellClick}
      width={props.width}
      height={props.height}
      showPacketIds={props.editable}
      editable={props.editable}
      top={39 + (props.editable ? 69 : 0)}
      onSave={props.onSave}
      tabId={props.tabId}
      tabConfig={props.tabConfig}
      tabModel={props.tabModel}
      onNodeChanged={props.onNodeChanged}
      resetTab={props.resetTab}
    />
  ) : (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        // width: props.width,
        // height: props.height,
        // overflow: "auto",
      }}
    >
      <Form
        t={props.t}
        width={props.width}
        height={props.height}
        treeRoot={treeRoot}
        reduxRoot={props.reduxRoot || treeRoot}
        registryVersion={registryVersion}
        registryVersion2={registryVersion2}
        productsMap={props.productsMap}
        nodesMap={props.nodesMap}
        nodeId={props.nodeId}
        nodeCode={props.nodeCode}
        nodePath={props.nodePath}
        form={form}
        editable={props.editable}
        UPDATE_NODE={props.UPDATE_NODE}
        onSave={props.onSave}
        onNodeDelete={props.onNodeDelete}
        hidePaths={props.hidePaths}
        margin={props.margin}
        onClose={props.onClose}
        disablePaths={props.disablePaths}
        disableJSON={props.disableJSON}
        disableEditing={props.disableEditing}
        isAddedNode={props.isAddedNode}
        isRemovedNode={props.isRemovedNode}
        compare={props.compare}
        resetTab={props.resetTab}
        tabId={props.tabId}
        tabConfig={props.tabConfig}
        tabModel={props.tabModel}
      />
    </Box>
  );
}
