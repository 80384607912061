import React from "react";
import { useTranslation } from "react-i18next";
import { byString } from "../helpers/functions";
import moment from "moment";
import VirtualizedTable from "./VirtualizedTable";

export default function ProductTable(props) {
  const { t } = useTranslation();

  const columns = [
    {
      label: t("supplier"),
      dataKey: "supplier",
      valueGetter: (val) => {
        return props.wholeSalers.find((x) => x.id === val.id)?.name;
      },
      disabled: true,
    },
    {
      label: t("name"),
      dataKey: "nodeText.name",
      numeric: true,
    },
    // TODO missing in new supplier data
    // {
    //   label: t("amount"),
    //   dataKey: "amount",
    //   numeric: true,
    // },
    {
      label: t("priceValidFrom"),
      dataKey: "priceValidFrom",
      dateFormat: "DD.MM.YYYY",
      disabled: true,
    },
    {
      label: t("convFactor"),
      dataKey: "convFactor",
      numeric: true,
    },
    {
      label: t("discountGroup"),
      dataKey: "discountGroup",
      numeric: true,
    },
    {
      label: t("origCost"),
      dataKey: "origCost",
      numeric: true,
    },
    {
      label: t("salesPrice"),
      dataKey: "salesPrice",
      numeric: true,
    },
    {
      label: t("unitPrice"),
      dataKey: "unitPrice",
      numeric: true,
    },
    {
      label: t("unit"),
      dataKey: "unit",
      valueGetter: (val) => {
        return val?.unit ?? val?.nodeText?.unit;
      },
    },
  ];

  const _handleChange = (key, numeric, rowIndex) => (ev) => {
    props.onChange(`[${rowIndex}].${key}`, numeric, ev.target.value);
  };

  const cellRenderer = (_props) => {
    const { columnData, rowData, dataKey, rowIndex } = _props;

    let value = columnData.valueGetter
      ? columnData.valueGetter(rowData)
      : byString(rowData, dataKey) ?? "";
    if (columnData.dateFormat && value)
      value = moment(value).format(columnData.dateFormat);
    return props.editable ? (
      <input
        className="table-cell-input"
        type="text"
        spellCheck="false"
        value={value}
        title={value}
        disabled={columnData.disabled}
        onChange={_handleChange(dataKey, columnData.numeric, rowIndex)}
      />
    ) : (
      value
    );
  };

  // TODO test that editing works
  // TODO remove unused td th styles
  return (
    <VirtualizedTable
      id={"PackageImporter"}
      cellHover={false}
      rowHover={false}
      enableHeader={true}
      rowCount={props.data.length}
      rowGetter={({ index }) => props.data[index]}
      rowHeight={30}
      height={props.data.length * 30 + 20}
      //handleRowClick={handleRowClick}
      cellRenderer={cellRenderer}
      columns={columns}
      headerStyle={{ fontSize: 11 }}
      headerHeight={20}
    />
  );
}
