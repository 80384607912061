import React from "react";

import MenuItem from "@mui/material/MenuItem";
import {
  RowCheckbox,
  RowDatePicker,
  FormTextField,
  RowSelect,
  RowText,
} from "../components/StyledComponents";

function getPickerMenuItems(input, prop, parentProps) {
  const { options, optionsProp, optionsValueProp, optionsNameProp } = input;
  const _options = Array.isArray(options) ? options : parentProps[optionsProp];
  if (Array.isArray(_options)) {
    return _options.map((option, index) => {
      const _value = optionsValueProp ? option[optionsValueProp] : option;
      const _name = optionsValueProp ? option[optionsNameProp] : option;
      return (
        <MenuItem
          /*className={classes.label}*/ key={prop + _value}
          value={_value}
        >
          {_name}
        </MenuItem>
      );
    });
  } else return [];
}

/**
 * Returns a relevant component according to input.type.
 *
 * @component
 * @param {Object} props props for the component
 * @param {Object} props.input - input properties
 * @param {string} props.prop - property for onChange etc
 * @param {string | Object} props.handleChange - callback for input change
 * @param {string} props.parentProps - parents props
 * @returns JSX
 */
export default function FormComponent({
  input,
  prop,
  handleChange,
  parentProps,
}) {
  if (input.hidden) return null;
  if (input.type === "textField") {
    return (
      <FormTextField
        input={input}
        onChange={(value) =>
          handleChange(prop, input.type, value, input.numeric)
        }
        disabled={input.disabled}
        inputProps={input.readOnly ? { readOnly: true } : {}}
        autoComplete="off"
      />
    );
  } else if (input.type === "picker") {
    return (
      <RowSelect
        input={input}
        onChange={(value) => handleChange(prop, input.type, value)}
        items={getPickerMenuItems(input, prop, parentProps)}
      />
    );
  } else if (input.type === "text") {
    return <RowText title={input.value} />;
  } else if (input.type === "datePicker") {
    return (
      <RowDatePicker
        input={input}
        onChange={(value) => handleChange(prop, input.type, value)}
      />
    );
  } else if (input.type === "checkbox") {
    return (
      <RowCheckbox
        input={input}
        onChange={(value) => handleChange(prop, input.type, value)}
      />
    );
  } else return <RowText title={"UNSUPPORTED INPUT TYPE"} />;
}
