import { request } from "./api";
import { chunk, checkIfArr } from "./functions";
import { toast } from "react-toastify";

/**
 * Requests the server when user selects a registryVersion for editing.
 * @param {String} registryVersion registryVersion key
 */
export function setDraftVersion(registryVersion, t) {
  return request("packages/versions/createDraft", "post", {
    version: registryVersion,
  }).then((res) => {
    // 204 when there is a draft
    if (res.status === 204) {
      return true;
    }
    // 202 when there is no draft or server is still processing the draft
    else if (res.status === 202) {
      toast.info(t("creatingDraft") + ", " + t("canTakeMultipleMinutes"));
      return false;
    } else {
      toast.error(t("errorCreatingDraft"));
      return false;
    }
  });
}

/**
 * Request node children
 * @param {Function} SET_CHILDREN reducer action to set children
 * @param {Function} SET_NODES reducer action to set items
 * @param {String} root root trees name
 * @param {String} path path from node obj
 * @param {Array} itemsToRequest array of node codes to request
 * @param {String} registryVersion registryVersion
 * @param {String} root root prop
 * @param {String} deletedNodes deleted node objects to add
 */
export function requestTreeChildren(
  path,
  itemsToRequest = [],
  nodeVersion,
  registryVersion,
  root,
  deletedNodes = []
) {
  let promises = [];
  let childrenToAdd = deletedNodes;

  promises.push(
    request("nodes/children", "get", null, {
      path,
      registryVersion: nodeVersion ?? registryVersion,
      root,
    })
  );

  // get items
  // separate requests to stay within uri limit
  // querystring length for one item is ~20 chars
  // requests in parallel, may cause problems on server side if too many requests are sent
  if (itemsToRequest.length > 0) {
    const chunked = chunk(itemsToRequest, 100);
    chunked.forEach((items) => {
      promises.push(
        request("nodes/nodeItems", "get", null, {
          ids: items,
          registryVersion: registryVersion,
        })
      );
    });
  }

  return Promise.all(promises).then((values) => {
    if (checkIfArr(values[0].data?.children)) {
      childrenToAdd = childrenToAdd.concat(values[0].data.children);
    }

    let _items = {};
    for (let i = 1; i < values.length; i++) {
      if (values[i].data) {
        _items = Object.assign(_items, values[i].data);
      }
    }

    return { children: childrenToAdd, items: _items };
  });
}

/**
 * Request singular node with its id
 * @param {String} nodes id from node
 * @param {String} registryVersion registryVersion to request from
 */
export function requestChildNode(nodes, registryVersion) {
  return request("nodes", "get", null, {
    ids: nodes.map((x) => x.node.id),
    registryVersion,
  }).then((res) => {
    if (checkIfArr(res.data)) {
      return {
        itemsArr: res.data.map((x) => {
          return {
            res: x,
            merged: { ...nodes.find((x) => x.node.id === x.id), ...x },
          };
        }),
      };
    } else {
      return {};
    }
  });
}

/**
 * Request nodes with their ids
 * @param {Array} ids string array of node ids
 * @param {String} registryVersion selected registryVersion
 */
export function requestNodes(ids, registryVersion) {
  let promises = [];

  // separate requests to stay within uri limit
  // querystring length for one item is ~20 chars
  if (ids.length > 0) {
    const chunked = chunk(ids, 100);
    chunked.forEach((_ids) => {
      promises.push(
        request("nodes", "get", null, {
          ids: _ids,
          registryVersion,
        })
      );
    });
  }

  return Promise.all(promises).then((values) => {
    let _values = [];
    let error = false;
    values.forEach((x) => {
      if (x.status === 200) {
        _values = _values.concat(x.data);
      } else if (x.status !== 204) {
        error = x.status;
      }
    });
    return { status: error || (_values.length > 0 ? 200 : 204), data: _values };
  });
}

/**
 * Request singular node with its id
 * @param {String} id node id
 * @param {String} registryVersion selected registryVersion
 */
export function requestNode(id, registryVersion) {
  return request("nodes/" + id, "get", null, {
    registryVersion,
  });
}

/**
 * Request versions info, sets info to versions reducer
 * @param {Function} SET_REGISTRY_VERSIONS reducer action to set info
 * @param {Function} SET_VERSION reducer action to set current version
 * @param {Function} setLoading function to set loading to false after request is done (OPTIONAL)
 */
export function getVersions(SET_REGISTRY_VERSIONS, setLoading, SET_VERSION, t) {
  request("packages/versions").then((res) => {
    if (res.status === 200) {
      const selectedVersion = localStorage.getItem("registryVersion");
      const versionsList = Object.keys(res.data.versions);
      const newestVersion = Math.max(
        ...versionsList.map((x) => {
          const num = parseInt(x);
          return isNaN(num) ? 0 : num;
        })
      );

      if (!selectedVersion) {
        if (SET_VERSION) {
          const version = res.data.publishedVersion ?? newestVersion;
          SET_VERSION({ registryVersion: version });
        }
      } else if (selectedVersion && !versionsList.includes(selectedVersion)) {
        if (SET_VERSION) {
          toast.info(t("selectedVersionNoLongerExists"));
          SET_VERSION({ registryVersion: newestVersion });
        }
      }
      SET_REGISTRY_VERSIONS({ data: res.data });
    }
    if (setLoading) setLoading(false);
  });
}

/**
 * Request to get changes for a registryVersion, if registryVersion is 4, gets the changes from registryVersion 3 => 4
 * @param {String} root root trees name
 * @param {Number} registryVersion registryVersion to get
 */
export function getVersionChanges(root, registryVersion) {
  return request("packages/versionChanges", "get", null, {
    root,
    version: registryVersion,
  }).then((res) => {
    if (res?.status === 200) {
      return { ...res, registryVersion, root };
    } else if (res?.status === 204) {
      return null;
    } else {
      throw res;
    }
  });
}

/**
 * Request versions info, sets info to versions reducer
 * @param {Function} SET_COMMON_PROP reducer action to set info
 * @param {Function} [setLoading] function to set loading to false after request is done
 */
export function requestWholeSalers(SET_COMMON_PROP, setLoading) {
  request("packages/wholeSalers").then((res) => {
    SET_COMMON_PROP({ data: res.data, prop: "wholeSalers" });
    if (setLoading) setLoading(false);
  });
}

/**
 * Request global search
 * @param {String} q search text
 * @param {String} registryVersion selected registryVersion
 * @param {Function} setRes function to call back with res.data
 * @param {Function} [setLoading] function to set loading to false after request is done
 */
export function requestSearch(q, registryVersion, filters, setRes, setLoading) {
  request("nodes/search", "get", null, {
    q,
    registryVersion,
    ...filters,
  }).then((res) => {
    if (res.data) {
      setRes(res.data);
    } else {
      setRes([]);
    }
    if (setLoading) setLoading(false);
  });
}

/**
 * Request global search
 * @param {String} root tab root
 * @param {String} registryVersion selected registryVersion
 * @param {Function} setRes function to call back with res.data
 * @param {Function} setLoading function to set loading to false after request is done (OPTIONAL)
 */
export function requestMissingNodes(root, registryVersion, setRes, setLoading) {
  request("nodes/missingNodes", "get", null, { registryVersion, root }).then(
    (res) => {
      setRes(res.data);
      if (setLoading) setLoading(false);
    }
  );
}

/**
 * Request expiring nodes
 * @param {String} months how old nodes to search for
 * @param {String} registryVersion selected registryVersion
 * @param {Function} setRes function to call back with res.data
 * @param {Function} setLoading function to set loading to false after request is done (OPTIONAL)
 */
export function requestExpiringProducts(
  months,
  registryVersion,
  setRes,
  setLoading
) {
  request("nodes/expiringProducts", "get", null, {
    registryVersion,
    months,
  }).then((res) => {
    setRes(res.data);
    if (setLoading) setLoading(false);
  });
}

/**
 * Request discontinued nodes (may be discontinued only by some suppliers, others may still provide)
 * @param {String} registryVersion selected registryVersion
 * @param {Function} setRes function to call back with res.data
 * @param {Function} setLoading function to set loading to false after request is done (OPTIONAL)
 */
export function requestDiscontinuedProducts(
  registryVersion,
  setRes,
  setLoading
) {
  request("nodes/discontinuedProducts", "get", null, {
    registryVersion,
  }).then((res) => {
    setRes(res.data);
    if (setLoading) setLoading(false);
  });
}
