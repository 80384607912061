import React, { useEffect, useState } from "react";

import { FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

import Paper from "@mui/material/Paper";
import AsyncAutocomplete from "./AsyncAutocomplete";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";

import TooltipInput from "./TooltipInput";

import { FaTrashAlt } from "react-icons/fa";

import { requestNodes } from "../helpers/requests";

import { connect } from "react-redux";

const editedColor = "#ebc96c";
const removedColor = "#c79999";
const addedColor = "#b9d4b4";

const editedStyle = { backgroundColor: editedColor, borderRadius: "4px" };

const packageItemsAutoCompleteFilters = { types: [3, 10, 13] };

function Item({
  t,
  values,
  item,
  items2,
  oldItems,
  onRemoveItem,
  onItemChange,
  nodes,
  nodes2,
  editable,
}) {
  const item2 = items2 ? items2.find((item2) => item2.id === item.id) : null;
  return (
    <Grid
      container
      item
      wrap="nowrap"
      sx={{
        borderRadius: "4px",
        backgroundColor:
          items2 && !item2 ? (oldItems ? removedColor : addedColor) : null,
      }}
      spacing={0.5}
    >
      <Grid item xs={3} sx={{ paddingLeft: "16px" }}>
        <TooltipInput
          tip={item.code}
          value={item.code}
          inputprops={{ readOnly: true }}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TooltipInput
          sx={
            item2 &&
            nodes[item2.id]?.nodeText?.name !== nodes[item.id]?.nodeText?.name
              ? editedStyle
              : null
          }
          tip={nodes[item.id]?.nodeText?.name}
          value={nodes[item.id]?.nodeText?.name ?? ""}
          onChange={
            false && editable
              ? (ev) =>
                  onItemChange(
                    "nodeText",
                    false,
                    item.id,
                    item.code
                  )(ev.target.value)
              : null
          }
          inputprops={{ readOnly: true }}
          fullWidth
        />
      </Grid>
      <Grid item xs sx={{ paddingLeft: "16px" }}>
        <TooltipInput
          sx={item2 && item2.amount !== item.amount ? editedStyle : null}
          value={values?.amount ?? item.amount ?? ""}
          onChange={
            editable
              ? (ev) =>
                  onItemChange(
                    "amount",
                    true,
                    item.id,
                    item.code
                  )(ev.target.value)
              : null
          }
          inputprops={{ readOnly: true }}
          fullWidth
        />
      </Grid>
      <Grid item xs sx={{ paddingLeft: "16px" }}>
        <TooltipInput
          sx={item2 && item2.unit !== item.unit ? editedStyle : null}
          value={values?.unit ?? item.unit ?? ""}
          onChange={
            editable
              ? (ev) =>
                  onItemChange(
                    "unit",
                    false,
                    item.id,
                    item.code
                  )(ev.target.value)
              : null
          }
          inputprops={{ readOnly: true }}
          fullWidth
        />
      </Grid>
      {editable && (
        <Grid item xs="auto" sx={{ paddingLeft: "16px" }}>
          <IconButton
            aria-label="delete-item"
            onClick={() => onRemoveItem(item)}
          >
            <FaTrashAlt />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
}

function ItemRow({ data, index, style }) {
  const {
    t,
    _items,
    onItemChange,
    values,
    items2,
    oldItems,
    onRemoveItem,
    nodes,
    nodes2,
    editable,
  } = data;
  const item = _items[index];
  return (
    <div style={style}>
      <Item
        key={"ItemChanges" + item.id + index}
        item={item}
        t={t}
        onItemChange={onItemChange}
        values={values}
        items2={items2}
        oldItems={oldItems}
        onRemoveItem={onRemoveItem}
        nodes={nodes}
        nodes2={nodes2}
        editable={editable}
      />
    </div>
  );
}

export function ItemsList({
  t,
  height,
  maxHeight,
  values,
  items,
  items2,
  oldItems,
  onRemoveItem,
  onItemChange,
  nodes,
  nodes2,
  editable,
}) {
  const _items = values?.items ?? items;

  if (_items && _items.length > 0) {
    const itemSize = 60;
    const itemHeights = _items.length * itemSize;
    return (
      <>
        <Grid container item wrap="nowrap" spacing={0.5}>
          <Grid item xs={3} sx={{ paddingLeft: "16px" }}>
            <Typography>{t("code")}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>{t("product")}</Typography>
          </Grid>
          <Grid item xs sx={{ paddingLeft: "16px" }}>
            <Typography>{t("amount")}</Typography>
          </Grid>
          <Grid item xs sx={{ paddingLeft: "16px" }}>
            <Typography>{t("unit")}</Typography>
          </Grid>
          {editable && (
            <Grid item xs="auto" sx={{ paddingLeft: "16px" }}>
              <Typography>{t("delete")}</Typography>
            </Grid>
          )}
        </Grid>

        <AutoSizer disableHeight>
          {({ width }) => (
            <FixedSizeList
              height={
                height ??
                (itemHeights > maxHeight ? maxHeight : itemHeights + 24)
              }
              width={width}
              itemSize={itemSize}
              itemCount={_items.length}
              itemData={{
                _items,
                t,
                onItemChange,
                values,
                items2,
                oldItems,
                onRemoveItem,
                nodes,
                nodes2,
                editable,
              }}
            >
              {ItemRow}
            </FixedSizeList>
          )}
        </AutoSizer>
      </>
    );
  } else {
    return null;
  }
}

// TODO finish documentation
/**
 * EditTreeControls component.
 *
 * Renders controls for editing tree nodes; adding, removing, moving and replacing.
 * @component
 * @param {Object} props props for the component
 * @param {Object} props.height - height for wrapper
 * @param {Object} props.width - width for wrapper
 * @param {Object} props.margin - margin for wrapper
 * @param {Object} props.padding - padding for wrapper
 * @param {Object} props.values - node object
 * @param {Object} props.paperElevation selected node to edit
 * @param {Object} props.editable - tree from redux
 * @param {String} props.registryVersion - registryVersion from redux
 * @param {String} props.currentVersion2 - tree root e.g. "packets"
 * @param {Object} props.t - struct items from redux
 * @param {Function} props.items - function called on node add
 * @param {Function} props.items2 - function called on node add
 * @param {Function} props.productsMap - function called on node add
 * @param {Function} props.nodesMap - function called on node add
 * @param {Function} props.treeRoot - function called on node add
 * @param {Function} props.onAddItem - function called on node add
 * @param {Function} props.onRemoveItem - function called on node add
 * @param {Function} props.onItemChange - function called on node add
 * @param {Function} props.oldItems - function called on node add
 * @returns JSX
 */
function ItemsView({
  height,
  width,
  maxHeight,
  margin,
  padding,
  values,
  paperElevation,
  backgroundColor,
  editable,
  registryVersion, //needed if editable or showing versionChanges
  currentVersion2,
  selectedNode,
  t,
  items,
  items2,
  productsMap,
  nodesMap,
  treeRoot,
  onAddItem,
  onRemoveItem,
  onItemChange,
  oldItems,
}) {
  const _items = items;
  // const [_items, _setItems] = useState(items);
  const [nodes, setNodes] = useState({});
  const [nodes2, setNodes2] = useState({});
  // const [removedOrAddedItems, setRemovedOrAddedItems] = useState();

  const _requestNodes = (ids, registryVersion, callback) => {
    let _nodes = {};
    requestNodes(ids, registryVersion)
      .then((res) => {
        if (res.status === 200) {
          res.data.forEach((item) => {
            _nodes[item.id] = item;
          });
        }
      })
      .finally(() => {
        callback(_nodes);
      });
  };

  const selectedNodeId = selectedNode?.id;
  const selectedNodeItemsLength = selectedNode?.items?.length;
  useEffect(() => {
    let _nodes = {};
    let ids = [];

    if (items) {
      items.forEach((item) => {
        const map = item.type === 3 ? productsMap : nodesMap;
        const _node = map?.get?.(item.id);
        if (_node) _nodes[item.id] = _node;
        else ids.push(item.id);
      });
    }
    if (ids.length > 0) {
      _requestNodes(ids, registryVersion, (data) => {
        setNodes({ ..._nodes, ...data });
      });
    } else {
      setNodes(_nodes);
    }

    // get compare items
    if (items2) {
      const _removedOrAddedItems = [];

      items.forEach((item1, index) => {
        if (!items2?.some((item2) => item2.id === item1.id)) {
          _removedOrAddedItems.push(item1);
        }
      });
      // if (_removedOrAddedItems.length > 0) {
      //   setRemovedOrAddedItems(_removedOrAddedItems);
      // }

      _requestNodes(
        items2.map((x) => x.id),
        currentVersion2,
        (data) => {
          setNodes2(data);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    registryVersion,
    currentVersion2,
    selectedNodeId,
    selectedNodeItemsLength,
  ]);

  // TODO add loading spinner until items are fetched
  return (
    <Paper
      elevation={paperElevation ?? 3}
      sx={{
        padding: 0,
        width: width ?? "100%",
        paddingTop: padding !== undefined ? padding : "16px",
        overflow: "hidden",
        backgroundColor,
      }}
    >
      <Grid container alignItems="center" spacing={1}>
        {editable && (
          <Grid item xs={12}>
            <AsyncAutocomplete
              t={t}
              registryVersion={registryVersion}
              onSelect={onAddItem}
              label={t("addNodeToPackage")}
              searchParams={packageItemsAutoCompleteFilters}
            />
          </Grid>
        )}
      </Grid>

      <ItemsList
        height={height}
        maxHeight={maxHeight}
        values={values}
        items={_items}
        items2={items2}
        oldItems={oldItems}
        t={t}
        nodes={nodes}
        nodes2={nodes2}
        treeRoot={treeRoot}
        editable={editable}
        onRemoveItem={onRemoveItem}
        onItemChange={onItemChange}
      />
    </Paper>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    productsMap: state.nodes.productsMap,
    nodesMap: state.nodes.nodeMaps[ownProps.registryVersion],
  };
};

export default connect(mapStateToProps, null)(ItemsView);
