import React from "react";
import { useTranslation } from "react-i18next";

import WindowWrapper from "../components/WindowWrapper";

function TreeScreen(props) {
  const { t } = useTranslation();
  return (
    <WindowWrapper
      t={t}
      uiSettingsProp={props.treeRoot + "_tree"}
      generateFlexLayoutJson={() => ({
        global: {
          tabEnableRename: true,
          tabSetEnableClose: true,
        },
        borders: [
          {
            type: "border",
            location: "top",
            barSize: 64,
            children: [],
          },
        ],
        layout: {
          type: "row",
          children: [
            {
              type: "row",
              children: [
                {
                  type: "tabset",
                  weight: 70,
                  children: [
                    {
                      type: "tab",
                      name: t(props.treeRoot),
                      component: "nodeTree",
                      enableClose: false,
                      config: {
                        search: true,
                        loading: false,
                        disableRootChange: true,
                        useVersionInRequests: false,
                        treeRoot: props.treeRoot,
                      },
                    },
                  ],
                },
              ],
            },
            {
              type: "tabset",
              children: [
                {
                  id: "nodeForm",
                  type: "tab",
                  name: "",
                  component: "node",
                  enableClose: false,
                  config: {},
                },
              ],
            },
          ],
        },
      })}
    />
  );
}

export default TreeScreen;
