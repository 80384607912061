/**
 * Action for setting table
 * @param {Object} payload
 * @param {Object} payload.table data to override table with
 * @param {Object} payload.registryVersion
 * @returns null
 */
export const SET_TABLE = (payload) => ({
  type: "SET_TABLE",
  payload,
});

/**
 * Action for replacing table and its cells
 * @param {Object} payload
 * @param {Object} payload.table new table
 * @param {Object} payload.table.cells new cells
 * @param {Object} payload.registryVersion
 * @returns null
 */
export const REPLACE_TABLE = (payload) => ({
  type: "REPLACE_TABLE",
  payload,
});

/**
 * Action for deleting table and its cells
 * @param {Object} payload
 * @param {String} payload.registryVersion
 * @param {String} payload.tableId
 * @returns null
 */
export const DELETE_TABLE = (payload) => ({
  type: "DELETE_TABLE",
  payload,
});

/**
 * Action for setting cells (state.tables.cells[tableId][registryVersion])
 * @param {Object} payload
 * @param {Object} payload.cells data to override cells with
 * @param {String} payload.registryVersion
 * @param {String} payload.tableId
 * @returns null
 */
export const SET_TABLE_CELLS = (payload) => ({
  type: "SET_TABLE_CELLS",
  payload,
});

/**
 * Action for merging cells (state.tables.cells[tableId][registryVersion])
 * @param {Object} payload
 * @param {Object} payload.cells data to merge cells with
 * @param {String} payload.registryVersion
 * @param {String} payload.tableId
 * @returns null
 */
export const ADD_CELLS = (payload) => ({
  type: "ADD_CELLS",
  payload,
});
/**
 * Action for replacing cell. Cells row and col used to find its index (state.tables.cells[tableId][registryVersion][cellIndex])
 * @param {Object} payload
 * @param {Object} payload.cell data to override cell with
 * @param {String} payload.registryVersion
 * @param {String} payload.tableId
 * @returns null
 */
export const REPLACE_CELL = (payload) => ({
  type: "REPLACE_CELL",
  payload,
});

/**
 * Action for adding a row. Increments rowCount and updates cells array row numbers
 * @param {Object} payload
 * @param {Number} payload.row row number
 * @param {String} payload.registryVersion
 * @param {String} payload.tableId
 * @returns null
 */
export const ADD_ROW = (payload) => ({
  type: "ADD_ROW",
  payload,
});

/**
 * Action for adding a column. Increments colCount and updates cells array col numbers
 * @param {Object} payload
 * @param {Number} payload.col col number
 * @param {String} payload.registryVersion
 * @param {String} payload.tableId
 * @returns null
 */
export const ADD_COLUMN = (payload) => ({
  type: "ADD_COLUMN",
  payload,
});

/**
 * Action for deleting a row. Decrements rowCount and updates cells array row numbers
 * @param {Object} payload
 * @param {Number} payload.row row number
 * @param {String} payload.registryVersion
 * @param {String} payload.tableId
 * @returns null
 */
export const DELETE_ROW = (payload) => ({
  type: "DELETE_ROW",
  payload,
});

/**
 * Action for deleting a column. Decrements colCount and updates cells array col numbers
 * @param {Object} payload
 * @param {Number} payload.col col number
 * @param {String} payload.registryVersion
 * @param {String} payload.tableId
 * @returns null
 */
export const DELETE_COLUMN = (payload) => ({
  type: "DELETE_COLUMN",
  payload,
});
