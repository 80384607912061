import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { CellMeasurer, CellMeasurerCache } from "react-virtualized";
import PulseLoader from "react-spinners/PulseLoader";

import { getChanges } from "../helpers/functions";
import { TextInput } from "../components/StyledComponents";
import VirtualizedTable from "../components/VirtualizedTable";
import ModifiedItemsView from "../components/ModifiedItemsView";

import { connect } from "react-redux";

// TODO if change prop is "path" replace the its value with readable path
const Title = ({ text }) => (
  <div style={{ marginBottom: 10 }}>
    <p style={{ margin: 0, padding: 0 }}>
      <b>{text}</b>
    </p>
  </div>
);

// TODO optimize and make ui clearer for indicating changes
const VersionCompareListView = React.memo(
  function VersionCompareListViewComponent(props) {
    const { versionChangesState, width, height, t } = props;
    let location = useLocation();
    let searchParams = new URLSearchParams(location.search);
    const treeRoot = props.treeRoot || searchParams.get("treeRoot");
    const registryVersion =
      props.registryVersion ||
      searchParams.get("registryVersion") ||
      localStorage.getItem("registryVersion");
    const oldRegistryVersion =
      props.oldRegistryVersion || searchParams.get("oldRegistryVersion");

    const versionChanges =
      oldRegistryVersion &&
      versionChangesState?.[treeRoot]?.[
        `${oldRegistryVersion}-${registryVersion}`
      ];

    const [rows, setRows] = useState([]);

    function getVersionChangesRows(_versionChanges) {
      const versionChangesEntries = _versionChanges
        ? Object.entries(_versionChanges)
        : null;
      if (versionChangesEntries) {
        let _rows = versionChangesEntries.reduce((prev, x, index) => {
          if (x[0] !== "modifiedParents") {
            const changes = getChanges(x[1], props.lang);
            if (changes?.[0]?.prop !== 3) {
              prev.push({ id: x[0], changes: changes });
            }
          }
          return prev;
        }, []);

        setRows(_rows);
      }
    }

    useEffect(() => {
      if (versionChanges) {
        getVersionChangesRows(versionChanges);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [versionChanges]);

    function getCellChanges(cell, index, key) {
      return (
        <React.Fragment key={"cellChanges" + cell.row + cell.col + key + index}>
          <div
            style={{
              display: "flex",
              alignItems: "space-around",
              flexDirection: "row",
              padding: 5,
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
              }}
            >
              <p
                style={{
                  margin: 0,
                  padding: 0,
                  marginRight: 10,
                  marginLeft: 10,
                }}
              >
                {t("row")}
              </p>
              <TextInput
                value={cell.row}
                onChange={() => null}
                inputprops={{ readOnly: true }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flex: 1,
              }}
            >
              <p
                style={{
                  margin: 0,
                  padding: 0,
                  marginRight: 10,
                  marginLeft: 10,
                }}
              >
                {t("col")}
              </p>
              <TextInput
                value={cell.col}
                onChange={() => null}
                inputprops={{ readOnly: true }}
              />
            </div>
            {cell.value ? (
              <div
                style={{
                  display: "flex",
                  flex: 4,
                }}
              >
                <p
                  style={{
                    margin: 0,
                    padding: 0,
                    marginRight: 10,
                    marginLeft: 10,
                  }}
                >
                  {t("cellValue")}
                </p>
                <TextInput
                  value={cell.value}
                  onChange={() => null}
                  inputprops={{ readOnly: true }}
                />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flex: 4,
                }}
              >
                <p
                  style={{
                    margin: 0,
                    padding: 0,
                    marginRight: 10,
                    marginLeft: 10,
                  }}
                >
                  {t("name")}
                </p>
                <TextInput
                  value={cell.nodeText?.name}
                  onChange={() => null}
                  inputprops={{ readOnly: true }}
                />
              </div>
            )}
          </div>
        </React.Fragment>
      );
    }

    function getCellsList(cells = [], title, key) {
      const _cells = [];

      cells.forEach((cell, index) => {
        _cells.push(getCellChanges(cell, key, index));
      });

      if (_cells && _cells.length > 0) {
        return (
          <React.Fragment key={"ModifiedItem" + key}>
            <p style={{ margin: 0, padding: 0, marginRight: 10 }}>{t(title)}</p>
            {_cells}
          </React.Fragment>
        );
      } else {
        return null;
      }
    }

    function getChangesListLeftSide(x, index, innerIndex) {
      if (x.change) {
        if (x.prop === "items") {
          return (
            <ModifiedItemsView
              key={"ModifiedItemsViewRLeft" + index + innerIndex}
              sideKey={"ChangesListLeftSide"}
              index={index}
              innerIndex={innerIndex}
              t={t}
              prop={x.prop}
              addedOrRemovedTitle={"removed"}
              val1={x?.change?.oldValue}
              val2={x?.change?.newValue}
            />

            //   <Paper
            //   elevation={3}
            //   style={{ padding: 10, marginBottom: 10 }}
            //   key={"ChangesListLeftSide" + index + innerIndex}
            // >
            //   <p style={{ margin: 0, padding: 0 }}>{t(x.prop.toLowerCase())}</p>

            //   {getRemovedOrAddedItemsList(
            //     x?.change?.oldValue,
            //     x?.change?.newValue,
            //     "removed",
            //     "LeftSide" + index + innerIndex
            //   )}
            //   {getModifiedItemsList(
            //     x?.change?.oldValue,
            //     x?.change?.newValue,
            //     "modified",
            //     "LeftSide" + index + innerIndex
            //   )}
            // </Paper>
          );
        } else if (x.prop !== "Cells") {
          return (
            <React.Fragment key={"ChangesListLeftSide" + index + innerIndex}>
              <Paper
                elevation={2}
                style={{
                  display: "flex",
                  alignItems: "space-around",
                  flexDirection: "row",
                  padding: 5,
                }}
              >
                <p style={{ margin: 0, padding: 0, marginRight: 10 }}>
                  {t(x.prop.toLowerCase())}
                </p>
                <TextInput
                  value={x.change.oldValue}
                  onChange={() => null}
                  inputprops={{ readOnly: true }}
                />
              </Paper>
            </React.Fragment>
          );
        }
      } else return null;
    }

    function getChangesListRightSide(x, index, innerIndex) {
      if (x.prop === 3) {
        return (
          <div>
            <Title
              text={t("removed")}
              key={"ChangesListRightSide" + index + innerIndex}
            />
          </div>
        );
      } else if (x.prop === 2) {
        return (
          <Title
            text={t("added")}
            key={"ChangesListRightSide" + index + innerIndex}
          />
        );
      } else {
        if (x.prop === "items") {
          return (
            <ModifiedItemsView
              key={"ModifiedItemsViewRight" + index + innerIndex}
              sideKey={"ChangesListRightSide"}
              index={index}
              innerIndex={innerIndex}
              t={t}
              prop={x.prop}
              addedOrRemovedTitle={"added"}
              val1={x?.change?.newValue}
              val2={x?.change?.oldValue}
            />

            // <Paper
            //   key={"ChangesListRightSide" + index + innerIndex}
            //   elevation={2}
            //   style={{ padding: 10, marginBottom: 10 }}
            // >
            //   <p style={{ margin: 0, padding: 0, marginRight: 10 }}>
            //     {t(x.prop.toLowerCase())}
            //   </p>

            //   {getRemovedOrAddedItemsList(
            //     x?.change?.newValue,
            //     x?.change?.oldValue,
            //     "added",
            //     "RightSide" + index
            //   )}
            //   {getModifiedItemsList(
            //     x?.change?.newValue,
            //     x?.change?.oldValue,
            //     "modified",
            //     "RightSide" + index
            //   )}
            // </Paper>
          );
        } else if (x.prop === "Cells") {
          return (
            <Paper
              key={"ChangesListRightSide" + index + innerIndex}
              elevation={3}
              style={{
                padding: 10,
                marginBottom: 10,
                backgroundColor: "#ebc96c",
              }}
            >
              <p style={{ margin: 0, padding: 0 }}>{t(x.prop)}</p>
              {getCellsList(
                x?.change?.oldValue,
                "modified",
                "LeftSide" + index + innerIndex
              )}
            </Paper>
          );
        } else {
          return (
            <Paper
              key={"ChangesListRightSide" + index + innerIndex}
              elevation={2}
              style={{
                display: "flex",
                alignItems: "space-around",
                flexDirection: "row",
                padding: 5,
                backgroundColor: "#ebc96c",
              }}
            >
              <p style={{ margin: 0, padding: 0, marginRight: 10 }}>
                {t(x.prop.toLowerCase())}
              </p>
              <TextInput
                value={x.change.newValue}
                onChange={() => null}
                inputprops={{ readOnly: true }}
              />
            </Paper>
          );
        }
      }
    }

    function getChangesList(id, changes, rowIndex, columnIndex) {
      const _self = changes?.[0]?.prop;
      const key = `${columnIndex}${rowIndex}`;
      return (
        <Paper
          key={"FormBox" + rowIndex + columnIndex}
          elevation={3}
          style={{
            backgroundColor:
              _self === 3 ? "#c79999" : _self === 2 ? "#b9d4b4" : null,
            padding: 10,
            margin: 10,
            width: "90%",
          }}
        >
          <p>{t("code") + ": " + id + "_" + rowIndex}</p>
          {changes.map((x, j) => {
            if (x.prop === "NodeTranslates") {
              return Object.entries(x.change?.props?.FIN?.props || {}).map(
                ([prop, change], _j) => {
                  return columnIndex === 0
                    ? getChangesListLeftSide(
                        { id: id, prop, change },
                        key,
                        `${j}NT${_j}`
                      )
                    : getChangesListRightSide(
                        { id: id, prop, change },
                        key,
                        `${j}NT${_j}`
                      );
                }
              );
            } else {
              return columnIndex === 0
                ? getChangesListLeftSide(x, key, j)
                : getChangesListRightSide(x, key, j);
            }
          })}
        </Paper>
      );
    }

    const cache = new CellMeasurerCache({
      defaultHeight: 30,
      minHeight: 20,
      fixedWidth: true,
    });

    function cellRenderer({ columnIndex, key, parent, rowIndex, style }) {
      const rowData = rows[rowIndex];
      const content = getChangesList(
        rowData.id,
        rowData.changes,
        rowIndex,
        columnIndex
      );

      return (
        <CellMeasurer
          cache={cache}
          columnIndex={columnIndex}
          key={key}
          parent={parent}
          rowIndex={rowIndex}
        >
          <div
            style={{
              ...style,
              width: width / 2,
              whiteSpace: "nowrap",
            }}
          >
            {content}
          </div>
        </CellMeasurer>
      );
    }

    const columns = [
      {
        width: width / 2,
        label: "Vanha arvo",
        dataKey: "old",
      },
      {
        width: width / 2,
        label: "Uusi arvo",
        dataKey: "new",
      },
    ];
    if (props.loading) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingLeft: 20,
            fontSize: 12,
          }}
        >
          <PulseLoader
            //css={override}
            size={8}
            color={"#123abc"}
            loading={true}
          />
        </div>
      );
    } else {
      return (
        <div
          style={{
            width: width - 60,
            height: height - 50,
          }}
        >
          {rows.length === 0 ? (
            <Paper
              sx={{
                height: "100%",
                width: "100%",
                p: 4,
              }}
            >
              <Typography variant="h6">{t("noVersionChanges")}</Typography>
            </Paper>
          ) : (
            <div style={{ width: width, height: height }}>
              <VirtualizedTable
                cellHover={false}
                rowHover={false}
                enableHeader={true}
                rowCount={rows.length}
                rowGetter={({ index }) => rows[index]}
                rowHeight={cache.rowHeight}
                //handleRowClick={handleRowClick}
                cellRenderer={cellRenderer}
                columns={columns}
              />
            </div>
          )}
        </div>
      );
    }
  }
);

const mapStateToProps = (state, ownProps) => {
  return {
    versionChangesState: state.versions.versionChanges,
    lng: "FIN",
  };
};

export default connect(mapStateToProps, null)(VersionCompareListView);
