/**
 * Action for setting tree children
 * @param {Object} payload
 * @param {String} payload.root tree root e.g. "products"
 * @param {Array} payload.data new children
 * @param {String} [payload.path] path to add children to added to root if missing
 * @returns null
 */
export const SET_CHILDREN = (payload) => ({
  type: "SET_CHILDREN",
  payload,
});

/**
 * SET_CHILDREN with payloads array
 * @param {Object} payload
 * @param {Array} payload.payloads array of SET_CHILDREN payloads
 * @returns null
 */
export const SET_MULTIPLE_CHILDREN = (payload) => ({
  type: "SET_MULTIPLE_CHILDREN",
  payload,
});

/**
 * Action for clearing the tree
 * @param {Object} payload
 * @param {String} payload.root tree root e.g. "products"
 * @returns null
 */
export const CLEAR_TREE = (payload) => ({
  type: "CLEAR_TREE",
  payload,
});

/**
 * Action for replacing the tree
 * @param {Object} payload
 * @param {String} payload.root tree root e.g. "products"
 * @param {Array} payload.data new tree
 * @returns null
 */
export const REPLACE_TREE = (payload) => ({
  type: "REPLACE_TREE",
  payload,
});

/**
 * Action for overriding a node in tree
 * @param {Object} payload
 * @param {String} payload.root tree root e.g. "products"
 * @param {Object} payload.node new node
 * @returns null
 */
export const UPDATE_NODE = (payload) => ({
  type: "UPDATE_NODE",
  payload,
});

/**
 * Action for moving a node target nodes children, if no target is provided, node will be moved to root
 * @param {Object} payload
 * @param {String} payload.root tree root e.g. "products"
 * @param {Object} payload.node node to move
 * @param {Object} [payload.target] target node
 * @returns null
 */
export const MOVE_NODE = (payload) => ({
  type: "MOVE_NODE",
  payload,
});

/**
 * Action for deleting a node and its children
 * @param {Object} payload
 * @param {String} payload.root tree root e.g. "products"
 * @param {Object} payload.node node to move
 * @returns null
 */
export const DELETE_NODE = (payload) => ({
  type: "DELETE_NODE",
  payload,
});

/**
 * Action for adding nodes into targets children, if no target is provided, nodes will be added to root
 * @param {Object} payload
 * @param {String} payload.root tree root e.g. "products"
 * @param {Array} payload.nodes node to move
 * @param {Object} [payload.target] target node
 * @returns null
 */
export const ADD_NODES = (payload) => ({
  type: "ADD_NODES",
  payload,
});
