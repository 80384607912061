import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  useReducer,
} from "react";
import * as FlexLayout from "flexlayout-react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import update from "immutability-helper";

import { getName } from "../helpers/functions";

import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import ClipboardCard from "./ClipboardCard";
import CompanyRegister from "./CompanyRegister";
import SoftwareCompanyRegister from "./SoftwareCompanyRegister";
import VersionList from "./VersionList";
import TreeWrapper from "./TreeWrapper";
import NodeForm from "./NodeForm";
import Search from "./Search";
import VersionCompareListView from "./VersionCompareListView";
import ConfirmDialog from "./ConfirmDialog";

import { MdSettings } from "react-icons/md";

import { nanoid } from "nanoid";

const getNewTab = (model, id, name, component, config) => {
  // TODO only add items id to tab obj so the object won't bloat uiSettings if saving flexlayout to db
  // TODO handle only the id sent as props in every component
  const tabAlreadyExists = model.current.getNodeById(id);
  if (tabAlreadyExists) {
    model.current.doAction(FlexLayout.Actions.selectTab(id));
    return;
  } else {
    return {
      type: "tab",
      id,
      config,
      name,
      component,
    };
  }
};

export default function WindowWrapper(props) {
  let history = useHistory();
  const { t } = useTranslation();
  const layoutRef = useRef(null);
  const model = useRef(
    FlexLayout.Model.fromJson(
      (props.uiSettingsProp &&
        JSON.parse(localStorage.getItem(props.uiSettingsProp))) ||
        props.generateFlexLayoutJson(t)
    )
  );
  const [popover, setPopover] = useState({ anchorEl: null });
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [dialog, setDialog] = useState({
    visible: false,
  });
  const [selectedNodes, setSelectedNodes] = useState([]);

  const setDialogVisible = useCallback(
    (visible) => {
      setDialog((_dialog) => ({
        ..._dialog,
        visible,
      }));
    },
    [setDialog]
  );

  const _setDialog = useCallback(
    (params) => {
      setDialog(params);
    },
    [setDialog]
  );

  const onModelChange = (_model) => {
    if (props.uiSettingsProp) {
      localStorage.setItem(
        props.uiSettingsProp,
        JSON.stringify(_model.toJson())
      );
    }
  };

  const setTab = (id, name, component, config, tabId) => {
    const tab = model.current.getNodeById(id);

    if (tab) {
      updateTabConfig(id, name, component, config);
      // model.current.doAction(FlexLayout.Actions.selectTab(id));
      return;
    }

    const newTab = getNewTab(model, id, name, component, config);
    if (newTab) {
      if (tabId) {
        model.current.doAction(
          FlexLayout.Actions.addNode(
            newTab,
            tabId,
            FlexLayout.DockLocation.CENTER,
            -1
          )
        );
      } else {
        const children = model.current.getRoot().getChildren();
        if (children.length > 1) {
          model.current.doAction(
            FlexLayout.Actions.addNode(
              getNewTab(model, id, name, component, config),
              children[children.length - 1].getId(),
              FlexLayout.DockLocation.CENTER,
              0
            )
          );
        } else {
          model.current = FlexLayout.Model.fromJson(
            update(model.current.toJson(), {
              layout: {
                children: {
                  $push: [
                    {
                      type: "tabset",
                      //weight: 50,
                      children: [getNewTab(model, id, name, component, config)],
                    },
                  ],
                },
              },
            })
          );
        }
      }
    }

    forceUpdate();
  };

  const handleCloseTabWithId = (id) => {
    const foundTab = model.current.getNodeById(id);
    if (foundTab) {
      model.current.doAction(FlexLayout.Actions.deleteTab(id));
    }
  };

  const closeTab = (id) => () => {
    handleCloseTabWithId(id);
  };

  const onNodeChanged = useCallback(
    (node, tabId) => {
      const foundTab = model.current.getNodeById(tabId);
      if (foundTab) {
        model.current.doAction(
          FlexLayout.Actions.renameTab(tabId, getName(node))
        );
      }
    },
    [model]
  );

  const onNodeDelete = useCallback(
    (node, tabId) => {
      handleCloseTabWithId(tabId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [model]
  );

  const addTab = ({ component, name, parentId, config }) => {
    setTab(undefined, name, component, config, parentId);
  };

  const updateTabConfig = (id, name, component, config) => {
    const foundTab = model.current.getNodeById(id);
    if (foundTab) {
      model.current.doAction(
        FlexLayout.Actions.updateNodeAttributes(id, { name, component, config })
      );
      model.current.doAction(FlexLayout.Actions.selectTab(id));
    } else {
      setTab(model, id, name, component, config);
    }
  };

  const setSelectedNode = useCallback((_node, tabConfig, newTab) => {
    // TODO each tree should open/update its own node tab
    if (_node) {
      setTab(
        newTab ? _node.id ?? nanoid() : "nodeForm",
        getName(_node),
        "node",
        {
          ...tabConfig.nodeFormConfig,
          compare: !!_node.changes,
          isAddedNode: _node.changes?.self?.action === 2,
          isRemovedNode: _node.changes?.self?.action === 3,
          nodeId: _node.id,
          nodeCode: _node.code,
          nodePath: _node.path,
          nodeType: _node.nodeType,
          itemType: _node.itemType,
          type: _node.type,
          treeRoot: tabConfig.treeRoot,
          registryVersion: tabConfig.registryVersion,
          productsMap: tabConfig.productsMap,
          nodesMap: tabConfig.nodesMap,
          editable: tabConfig.editable,
          handleNodeClose: tabConfig.handleNodeClose,
          UPDATE_NODE: tabConfig.UPDATE_NODE,
          renderTable: true,
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetTab = useCallback((id) => {
    updateTabConfig(id, "", undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRootChange = useCallback((id, newRoot, tabConfig) => {
    updateTabConfig(id, t(newRoot), "nodeTree", {
      ...tabConfig,
      treeRoot: newRoot,
      reduxRoot: newRoot,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeAllSelectedNodes = useCallback((_class) => {
    setSelectedNodes([]);
  }, []);

  const removeOneSelectedNode = useCallback((_node) => {
    setSelectedNodes((_nodes) => {
      const index = _nodes.findIndex((x) => x.id === _node.id);
      if (index !== -1) return update(_nodes, { $splice: [[index, 1]] });
      else return _nodes;
    });
  }, []);

  const factory = (node) => {
    const rect = node.getRect();
    const config = node.getConfig();
    const component = node.getComponent();
    const id = node.getId();

    // TODO add context menu in tree for opening to a new tab or new window, default is to replace the node tab
    return (
      <div
        id="FlexWrapper"
        style={{
          flex: 1,
          height: rect.height,
          width: rect.width,
          overflow: "hidden",
        }}
      >
        {component === "nodeTree" ? (
          <TreeWrapper
            t={t}
            history={history}
            search={config.search}
            loading={config.loading}
            width={rect.width}
            height={rect.height}
            useVersionInRequests={config.useVersionInRequests}
            treeRoot={config.treeRoot}
            reduxRoot={config.reduxRoot}
            registryVersion={config.registryVersion}
            enableVersionChanges={config.enableVersionChanges}
            disableRootFetch={config.disableRootFetch}
            disableSettings={config.disableSettings}
            disableOpennessStatePersist={config.disableOpennessStatePersist}
            disableRootChange={config.disableRootChange}
            useRootPath={config.useRootPath}
            isOpenByDefault={config.isOpenByDefault}
            nodeIdPrefix={config.nodeIdPrefix}
            onRootChange={onRootChange}
            setSelectedNode={setSelectedNode}
            productsMap={config.productsMap}
            nodesMap={config.nodesMap}
            versionChanges={config.versionChanges}
            SET_CHILDREN={config.SET_CHILDREN}
            SET_NODES={config.SET_NODES}
            SET_REGISTRY_VERSIONS={config.SET_REGISTRY_VERSIONS}
            tabId={id}
            tabConfig={config}
            tabModel={model}
            setDialog={_setDialog}
            selectedNodes={selectedNodes}
            setSelectedNodes={setSelectedNodes}
            removeAllSelectedNodes={removeAllSelectedNodes}
            removeOneSelectedNode={removeOneSelectedNode}
          />
        ) : component === "node" ? (
          <NodeForm
            t={t}
            width={rect.width}
            height={rect.height}
            treeRoot={config.treeRoot}
            reduxRoot={config.reduxRoot}
            registryVersion={config.registryVersion}
            registryVersion2={config.registryVersion2}
            compare={config.compare}
            disableEditing={config.disableEditing}
            reqistryVersionQueryParam={config.reqistryVersionQueryParam}
            reqistryVersionQueryParam2={config.reqistryVersionQueryParam2}
            productsMap={config.productsMap}
            nodesMap={config.nodesMap}
            nodeId={config.nodeId}
            nodeCode={config.nodeCode}
            nodePath={config.nodePath}
            nodeType={config.nodeType}
            type={config.type}
            isAddedNode={config.isAddedNode}
            isRemovedNode={config.isRemovedNode}
            itemType={config.itemType}
            editable={config.editable}
            onClose={config.handleNodeClose}
            renderTable={config.renderTable}
            UPDATE_NODE={config.UPDATE_NODE}
            onSave={onNodeChanged}
            onNodeDelete={onNodeDelete}
            resetTab={resetTab}
            tabId={id}
            tabConfig={config}
            tabModel={model}
          />
        ) : component === "search" ? (
          <Search
            global
            width={rect.width}
            height={rect.height}
            drawer={false}
            registryVersion={config.registryVersion}
            productsMap={config.productsMap}
            nodesMap={config.nodesMap}
            SET_NODES={config.SET_NODES}
            setSearchItem={setSelectedNode}
            tabId={id}
            tabConfig={config}
            tabModel={model}
          />
        ) : component === "versionCompareList" ? (
          <VersionCompareListView
            loading={config.loading}
            version1={config.version1}
            version2={config.version2}
            width={rect.width}
            height={rect.height}
            t={t}
            tabId={id}
            tabConfig={config}
            tabModel={model}
          />
        ) : component === "versions" ? (
          <VersionList width={rect.width} height={rect.height} />
        ) : component === "softwareCompanyRegister" ? (
          <SoftwareCompanyRegister width={rect.width} height={rect.height} />
        ) : component === "companyRegister" ? (
          <CompanyRegister width={rect.width} height={rect.height} />
        ) : null}
      </div>
    );
  };

  const _i18nMapper = (key) => {
    switch (key) {
      case "Close":
        return t("close");
      case "Maximize tabset":
        return t("maximize");
      case "Restore tabset":
        return t("restore");
      case "Close tabset":
        return t("closeTabset");
      case "Move tabset":
        return t("moveTabset");
      case "Error rendering component":
        return t("errorRenderingComponent");
      default:
        return "";
    }
  };

  const onAuxMouseClick = (node, ev) => {
    if (
      ev.button === 1 &&
      node.getType() === "tab" &&
      node._attributes.enableClose !== false
    ) {
      closeTab(node.getId())();
    }
  };

  const handleTabSettingsClick = (params) => (event) => {
    setPopover({ anchorEl: event.currentTarget, params });
  };

  const handlePopoverClose = () => {
    setPopover({ anchorEl: null });
  };

  // TODO clear data from localStorage when closing tab
  const onRenderTabSet = (tabSetNode, renderValues) => {
    const node = tabSetNode.getSelectedNode();
    const component = node?.getComponent();
    const config = node?.getConfig();

    const id = "tab_settings_popover_" + tabSetNode._attributes.id;
    renderValues.buttons.push(
      <button
        key={id}
        className="custom_flexlayout__tab_toolbar_button flexlayout__tab_toolbar_button-min"
        onClick={handleTabSettingsClick({
          duplicateTab:
            node && component === "nodeTree" && !config?.disableSettings
              ? {
                  node,
                  component: "nodeTree",
                  config: {
                    // if duplicating a tree
                    nodeIdPrefix: nanoid(),
                    ...config,
                  },
                  name: t(config.treeRoot),
                  parentId: node._parent._attributes.id,
                }
              : null,
        })}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <MdSettings size="14px" fill="var(--color-icon)" />
      </button>
    );
  };

  const _classNameMapper = useCallback((_class) => {
    switch (_class) {
      case "flexlayout__tab_toolbar_button":
        return "custom_" + _class;
      case "flexlayout__tabset-selected":
        return "custom_" + _class;
      default:
        return _class;
    }
  }, []);

  const open = Boolean(popover.anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <FlexLayout.Layout
        ref={layoutRef}
        model={model.current}
        factory={factory}
        i18nMapper={_i18nMapper}
        //onRenderTab={onRenderTab(theme, colors, selectedTheme)}
        //onRenderTabSet={onRenderTabSet(theme, colors, selectedTheme)}
        //classNameMapper={classNameMapper(selectedTheme)}
        //onExternalDrag={onExternalDrag}
        classNameMapper={_classNameMapper}
        //font={layoutFont}
        //onAction={onAction}
        onModelChange={onModelChange}
        onAuxMouseClick={onAuxMouseClick}
        onRenderTabSet={onRenderTabSet}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={popover.anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <List>
          {popover.params?.duplicateTab && (
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  addTab(popover.params.duplicateTab);
                  handlePopoverClose();
                }}
              >
                <ListItemText primary={t("duplicateTab")} />
              </ListItemButton>
            </ListItem>
          )}
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                addTab({
                  name: t("packets"),
                  component: "nodeTree",
                  config: {
                    search: true,
                    loading: false,
                    useVersionInRequests: false,
                    treeRoot: "packets",
                    nodeIdPrefix: nanoid(),
                  },
                });
                handlePopoverClose();
              }}
            >
              <ListItemText primary={t("newPackagesTreeTab")} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                addTab({
                  name: t("products"),
                  component: "nodeTree",
                  config: {
                    search: true,
                    loading: false,
                    useVersionInRequests: false,
                    treeRoot: "products",
                    nodeIdPrefix: nanoid(),
                  },
                });
                handlePopoverClose();
              }}
            >
              <ListItemText primary={t("newProductsTreeTab")} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                addTab({
                  name: t("nomenclatures"),
                  component: "nodeTree",
                  config: {
                    search: true,
                    // TODO check if loading is used anywhere
                    loading: false,
                    // TODO check if useVersionInRequests is used anywhere
                    useVersionInRequests: false,
                    treeRoot: "nomenclatures",
                    nodeIdPrefix: nanoid(),
                  },
                });
                handlePopoverClose();
              }}
            >
              <ListItemText primary={t("newNomenclaturesTreeTab")} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                addTab({
                  name: t("tables"),
                  component: "nodeTree",
                  config: {
                    search: true,
                    loading: false,
                    useVersionInRequests: false,
                    treeRoot: "tables",
                    nodeIdPrefix: nanoid(),
                  },
                });
                handlePopoverClose();
              }}
            >
              <ListItemText primary={t("newTablesTreeTab")} />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
      <ConfirmDialog
        title={dialog.title}
        open={dialog.visible}
        setOpen={setDialogVisible}
        value={dialog.value}
        onConfirm={dialog.fn}
        loading={dialog.loading}
        hideSaveButton={dialog.hideSaveButton}
        cancelButtonTitle={dialog.cancelButtonTitle}
      >
        {dialog.content ?? (
          <Box sx={{ maxHeight: 400, overflowY: "auto" }}>
            <p className="pre-wrap">
              {dialog.text + " " + (dialog.value || "")}
            </p>
          </Box>
        )}
      </ConfirmDialog>
      {selectedNodes.length > 0 ? (
        <ClipboardCard
          nodes={selectedNodes}
          removeAll={removeAllSelectedNodes}
          removeOne={removeOneSelectedNode}
        />
      ) : null}
    </>
  );
}

WindowWrapper.whyDidYouRender = {
  logOnDifferentValues: true,
};
