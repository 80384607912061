import React, { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import Paper from "@mui/material/Paper";
import { request } from "../helpers/api";
import { pathToIds, roots, rootToURL } from "../helpers/functions";
import NodeTree from "./NodeTree";
import AsyncAutocomplete from "./AsyncAutocomplete";

export default function TreeWrapper(props) {
  let history = useHistory();
  let location = useLocation();
  let searchParams = new URLSearchParams(location.search);
  const treeRoot = props.treeRoot || searchParams.get("treeRoot");
  const reduxRoot =
    props.reduxRoot || searchParams.get("reduxRoot") || treeRoot;
  const registryVersion =
    props.registryVersion || searchParams.get("registryVersion");
  const oldRegistryVersion =
    props.oldRegistryVersion || searchParams.get("oldRegistryVersion");

  const treeSearchParams = React.useMemo(
    () => ({
      root: treeRoot,
    }),
    [treeRoot]
  );

  const handleAutocompleteSelect = useCallback(
    async (_node) => {
      if (_node) {
        const pathsRes = await request("nodes/itemPaths", "get", null, {
          id: _node.id,
          nodeType: _node.nodeType,
          root: treeRoot,
          registryVersion: props.registryVersion,
        });

        let nodeToHighlight = _node;

        if (pathsRes.status === 200) {
          const nodeWithPaths = pathsRes.data;
          const pathRootObj = Object.values(nodeWithPaths.nodes).find(
            (x) => roots[x.code] === treeRoot
          );
          const pathRoot = roots[pathRootObj.code];
          const firstPathInCurrentRoot = nodeWithPaths.paths.find((x) =>
            x.path.startsWith("," + pathRootObj.id)
          );

          if (firstPathInCurrentRoot) {
            const ids = pathToIds(firstPathInCurrentRoot.path);

            // if item to scroll to is inside a table (nodeType 6) we can strip the item from path and
            // highlight the table instead of the node
            const isNodeInsideTable =
              pathRoot === "tables" &&
              (_node.nodeType === 5 || _node.nodeType === 2);
            if (isNodeInsideTable) {
              nodeToHighlight = nodeWithPaths.nodes[ids[ids.length - 1]];
            }

            let to = "/" + rootToURL[pathRoot] + "?path=,";
            for (let i = 0; i < ids.length; i++) {
              to += ids[i] + ",";
            }
            if (!isNodeInsideTable) {
              to += _node.id + ",";
            }
            to += `&node=${nodeToHighlight.id}&clicked=${
              _node.id
            }&linkTime=${moment().valueOf()}`;

            history.push(to);
          }
        }

        props.setSelectedNode(nodeToHighlight, props.tabConfig);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      props.registryVersion,
      props.treeRoot,
      props.tabConfig,
      props.setSelectedNode,
    ]
  );

  return (
    <>
      {props.search && (
        <Paper
          sx={{
            paddingBottom: 1,
            paddingTop: 1,
            marginBottom: 1,
            paddingRight: "32px",
            borderRadius: 0,
          }}
        >
          <AsyncAutocomplete
            t={props.t}
            disabled={!treeRoot}
            registryVersion={props.registryVersion}
            onSelect={handleAutocompleteSelect}
            label={props.t("search")}
            searchParams={treeSearchParams}
            clearUrlOnClear
          />
        </Paper>
      )}
      <NodeTree
        enableVersionChanges={props.enableVersionChanges}
        search={props.search}
        loading={props.loading}
        width={props.width}
        height={props.height - (props.search ? 80 : 0)}
        useVersionInRequests={props.useVersionInRequests}
        treeRoot={treeRoot}
        reduxRoot={reduxRoot}
        registryVersion={registryVersion}
        oldRegistryVersion={oldRegistryVersion}
        setSelectedNode={props.setSelectedNode}
        nodeIdPrefix={props.nodeIdPrefix}
        productsMap={props.productsMap}
        nodesMap={props.nodesMap}
        versionChanges={props.versionChanges}
        disableRootFetch={props.disableRootFetch}
        onRootChange={props.onRootChange}
        useRootPath={props.useRootPath}
        disableSettings={props.disableSettings}
        disableOpennessStatePersist={props.disableOpennessStatePersist}
        disableRootChange={props.disableRootChange}
        isOpenByDefault={props.isOpenByDefault}
        tabId={props.tabId}
        tabConfig={props.tabConfig}
        tabModel={props.tabModel}
        setDialog={props.setDialog}
        selectedNodes={props.selectedNodes}
        setSelectedNodes={props.setSelectedNodes}
        removeAllSelectedNodes={props.removeAllSelectedNodes}
        removeOneSelectedNode={props.removeOneSelectedNode}
      />
    </>
  );
}
