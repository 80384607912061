import React, { useState } from "react";
import ReactDOM from "react-dom";
import { useFloating, offset, shift, flip } from "@floating-ui/react-dom";

export default function Tooltip(props) {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const { x, y, reference, floating, strategy } = useFloating({
    placement: "bottom",
    strategy: "fixed",
    middleware: [offset(8), shift(), flip()],
  });

  var elements = document.getElementsByClassName("tooltip");

  const onMouseOver = () => setTooltipVisible(true);
  const onMouseOut = () => setTooltipVisible(false);

  if (props.tip) {
    return (
      <>
        <div ref={reference} onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
          {props.children}
        </div>
        {tooltipVisible && elements[elements.length - 1]
          ? ReactDOM.createPortal(
              <div
                ref={floating}
                style={{
                  position: strategy,
                  top: y ?? "",
                  left: x ?? "",
                  backgroundColor: "#fff",
                  color: "#000",
                  borderStyle: "solid",
                  borderWidth: 2,
                  borderColor: "#000",
                  padding: 8,
                  borderRadius: 4,
                  zIndex: 5000,
                  whiteSpace: "pre-wrap",
                  maxWidth: "300px",
                }}
              >
                {props.tip}
              </div>,
              elements[elements.length - 1]
            )
          : null}
      </>
    );
  } else {
    return props.children;
  }
}
