export const tableForm = {
  data: [
    {
      title: "Table",
      rows: [
        [
          {
            key: "nodeText.name",
            label: "name",
            type: "textField",
            error: "",
            validation: "",
            mask: "",
            fill: true,
          },
        ],
      ],
    },
  ],
};
