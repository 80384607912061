import React, { useCallback, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import update from "immutability-helper";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";

import AsyncAutocomplete from "./AsyncAutocomplete";

import { MdClose, MdSave } from "react-icons/md";
// import { AiOutlinePlusSquare, AiOutlineMinusSquare } from "react-icons/ai/";

import { request } from "../helpers/api";
import {
  getName,
  getNodeIcon,
  pathsToTreeStructure,
} from "../helpers/functions";

import { FixedSizeTree as Tree } from "react-vtree";
import useWindowDimensions from "../helpers/useWindowDimensions";

const overlayId = "node-replace-overlay";

/**
 * Finds all nested childrens ids and returns them
 * @param {Array} arr array containing objects e.g. {id: "342t", children: []}
 * @param {Array} ids array to insert ids into
 * @returns {Object} object containing isChecked and indeterminate booleans
 */
const findChildIds = (arr, ids, level = 0) => {
  arr.forEach((x) => {
    ids.push(x.id);
    if (x.children.length > 0) {
      findChildIds(x.children, ids, level + 1);
    }
  });
};

// This helper function constructs the object that will be sent back at the step
// [2] during the treeWalker function work. Except for the mandatory `data`
// field you can put any additional data here.
const getNodeData = (
  index,
  nodes,
  nestingLevel,
  allNodes,
  checked,
  handleChange
) => {
  const treeNode = nodes[index];
  const node = allNodes[treeNode.id];
  return {
    data: {
      id: treeNode.id, // mandatory
      isLeaf: treeNode.children.length > 0,
      isOpenByDefault: true, // mandatory
      name: treeNode.name,
      nestingLevel,
      handleChange,
      treeNode,
      node,
      checked,
      siblings: nodes,
    },
    nestingLevel,
    node: treeNode,
  };
};

// The `treeWalker` function runs only on tree re-build which is performed
// whenever the `treeWalker` prop is changed.
function* treeWalker(treeNodes, allNodes, checked, handleChange) {
  // Step [1]: Define the root node of our tree. There can be one or
  // multiple nodes.
  for (let i = 0; i < treeNodes.length; i++) {
    yield getNodeData(i, treeNodes, 0, allNodes, checked, handleChange);
  }

  while (true) {
    // Step [2]: Get the parent component back. It will be the object
    // the `getNodeData` function constructed, so you can read any data from it.
    const parent = yield;
    for (let i = 0; i < parent.node.children.length; i++) {
      // Step [3]: Yielding all the children of the provided component. Then we
      // will return for the step [2] with the first children.
      yield getNodeData(
        i,
        parent.node.children,
        parent.nestingLevel + 1,
        allNodes,
        checked,
        handleChange
      );
    }
  }
}

// Node component receives all the data we created in the `treeWalker` +
// internal openness state (`isOpen`), function to change internal openness
// state (`toggle`) and `style` parameter that should be added to the root div.
const Node = ({ data, isOpen, style, setOpen }) => {
  const { checked, id, siblings, treeNode, node, nestingLevel, handleChange } =
    data;
  const paddingLeft = nestingLevel ? 20 + nestingLevel * 20 : 20;
  return (
    <div
      style={{
        ...style,
        paddingLeft: paddingLeft,
        width: `calc(100% - ${paddingLeft}px)`,
      }}
      className={"node-row"} //noselect
      // onClick={(event) => {
      //   event.stopPropagation();
      //   handleChange(
      //     treeNode,
      //     siblings
      //   )({ target: { checked: !(checked[id] === 1) } });
      // }}
    >
      {/* {data.isLeaf && (
        <div
          className="node-icon"
          onClick={() => {
            setOpen(!isOpen);
          }}
        >
          {isOpen ? (
            <AiOutlineMinusSquare size={20} color={"#000"} />
          ) : (
            <AiOutlinePlusSquare size={20} color={"#000"} />
          )}
        </div>
      )} */}

      <div className={"flex-row clickable-tree-node"}>
        <Checkbox
          sx={{ padding: 0 }}
          checked={checked[id] === 1}
          indeterminate={checked[id] === 2}
          onChange={handleChange(treeNode, siblings)}
        />

        <div className="node-icon">
          {getNodeIcon(false, node.nodeType, node.type, data.isEmpty)}
        </div>

        {getName(node)}
      </div>
    </div>
  );
};

const findNode = (arr, children, ids, idIndex = 0) => {
  if (children.length > 0) {
    for (let i = 0; i < children.length; i++) {
      const x = children[i];
      if (x.id === ids[idIndex]) {
        arr.push(x);
        findNode(arr, x.children, ids, idIndex + 1);
        break;
      }
    }
  }
};

/**
 * Renders a Checkbox tree from a tree structure
 * @component
 * @returns JSX
 */
function CheckboxTree(props) {
  const { checked, setChecked } = props;

  const handleChange = (treeNode) => (event) => {
    if (props.disabled) return;

    // toggle checked on self and nested children
    // set parent according to siblings status and percolate changes upwards
    setChecked((_checked) => {
      let _parents = [];
      findNode(_parents, props.tree, treeNode.parents, 0);

      let childIds = [];
      findChildIds(treeNode.children, childIds);

      let newChecked = _checked;

      const nodeIsChecked = event.target.checked ? 1 : 0;
      // set node and its children
      newChecked = {
        ...newChecked,
        ...(childIds.length > 0
          ? childIds.reduce((acc, x) => {
              acc[x] = nodeIsChecked;
              return acc;
            }, {})
          : null),
        [treeNode.id]: nodeIsChecked,
      };

      // set parents
      let allChildrenChecked = true;
      let someChildrenChecked = false;

      const getCheckedStatus = (id) => {
        if (newChecked[id] === 1) {
          someChildrenChecked = true;
        } else {
          allChildrenChecked = false;
        }
      };

      for (let i = _parents.length - 1; i > -1; i--) {
        allChildrenChecked = true;
        someChildrenChecked = false;
        const _parent = _parents[i];
        let _childIds = [];
        findChildIds(_parent.children, _childIds);
        if (_childIds.length > 0) {
          _childIds.forEach(getCheckedStatus);
        } else {
          allChildrenChecked = false;
          someChildrenChecked = false;
        }

        let parentChecked;

        if (allChildrenChecked) {
          parentChecked = 1;
        } else if (someChildrenChecked) {
          parentChecked = 2;
        } else {
          parentChecked = 0;
        }
        newChecked = update(newChecked, {
          [_parent.id]: { $set: parentChecked },
        });
      }
      return newChecked;
    });
  };

  return (
    <Tree
      treeWalker={() =>
        treeWalker(props.tree, props.nodes, checked, handleChange)
      }
      itemSize={30}
      height={props.height}
      width={props.width}
    >
      {Node}
    </Tree>
  );
}

const nodeToReplaceSearchParams = { types: [3, 10, 13] };

const itemSearchParams = { types: [3] };
const workSearchParams = { types: [13] };
const packagSearchParams = { types: [10] };
/**
 * Renders a component for replacing a node in selected paths.
 * Fetches itemPaths, formats them into tree stucture and renders the tree with selectable checkboxes.
 * @component
 * @returns JSX
 */
const NodeReplace = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { windowHeight, windowWidth } = useWindowDimensions();
  const [checked, setChecked] = useState({});

  const [paths, setPaths] = useState();
  const [node, setNode] = useState();
  const [newNode, setNewNode] = useState();
  const [saving, setSaving] = useState(false);

  const fetchInitialData = useCallback(
    async (node, nodeId, nodeCode, nodeType, root) => {
      const isMissingNode = !nodeId && nodeCode;
      const pathsRes = await (isMissingNode
        ? request("nodes/missingNodes/paths", "get", null, {
            code: nodeCode,
            registryVersion: props.registryVersion,
          })
        : request("nodes/itemPaths", "get", null, {
            id: nodeId,
            nodeType: nodeType,
            root: root,
            registryVersion: props.registryVersion,
          }));
      if (pathsRes?.status === 200) {
        props.SET_PATHS({
          ...pathsRes.data,
          nodeId: isMissingNode ? nodeCode : pathsRes.data.nodeId,
          registryVersion: props.registryVersion,
        });
        // filter own path from paths
        const filteredPaths = node?.path
          ? pathsRes.data.paths.filter((x) => x.path !== node.path)
          : pathsRes.data.paths;
        if (filteredPaths.length === 0) {
          toast.info(t("noPathsToReplaceIn"));
          props.onClose();
        } else {
          setPaths({
            nodes: pathsRes.data.nodes,
            paths: filteredPaths,
            tree: pathsToTreeStructure(filteredPaths),
          });
        }
      } else {
        props.onClose();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.registryVersion]
  );

  const _node = props.node || node;
  const nodeId = props.node?.id || node?.id;
  const nodeCode = props.node?.code || node?.code;
  useEffect(() => {
    if (!props.node && nodeId) {
      fetchInitialData(
        props.node,
        nodeId,
        nodeCode,
        _node.nodeType,
        props.root
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodeId]);

  useEffect(() => {
    if (props.open) {
      if (nodeId || (!nodeId && nodeCode)) {
        setNode(_node);
        if (props.node) {
          fetchInitialData(
            props.node,
            nodeId,
            nodeCode,
            _node.nodeType,
            props.root
          );
        }
      }
    } else {
      setPaths();
      setNode();
      setSaving(false);
      setNewNode();
      setChecked({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open, props.root, props.registryVersion, fetchInitialData]);

  useEffect(() => {
    if (saving) {
      request("nodes/replace", "PUT", {
        oldNode: node.id,
        newNode: newNode.id,
        targets: Object.entries(checked).reduce((prev, [key, value]) => {
          if (value) {
            prev.push(key);
          }
          return prev;
        }, []),
      })
        .then((res) => {
          if (res.status === 200) {
            fetchInitialData(
              node,
              node.id,
              node.code,
              node.nodeType,
              props.root
            );
            props.REPLACE_NODES({
              newNode: newNode,
              oldNode: node,
              nodes: res.data.replacedIn,
              registryVersion: props.registryVersion,
            });
            toast.success(t("replaceHandled"));
          }
        })
        .finally(() => setSaving(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, node, newNode, saving]);

  if (!props.open) return null;

  const width = windowWidth > 1000 ? 1000 : windowWidth - 32;
  const height = windowHeight + 4;
  if (!paths)
    return (
      <div
        id={overlayId}
        className="overlay block invisible"
        onClick={props.onClose}
      />
    );
  return (
    <>
      <div id={overlayId} className="overlay block" onClick={props.onClose} />
      <Box
        sx={{
          position: "fixed",
          top: "16px",
          left: "50%",
          transform: "translateX(-50%)",
          width: width,
          height: height,
          bgcolor: "background.paper",
          boxShadow: 24,
          zIndex: 1100,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Stack spacing={1} p={2} sx={{ display: "flex", flex: 1 }}>
          <AsyncAutocomplete
            t={t}
            value={node}
            registryVersion={props.registryVersion}
            onSelect={(_node) => {
              if (_node) {
                setNode(_node);
                if (newNode && newNode.nodeType !== _node.type) {
                  setNewNode(null);
                  toast.info(t("nodeReplaceTypeMismatch"));
                }
              }
            }}
            label={t(props.node ? "nodeToReplace" : "findNodeToReplace")}
            searchParams={nodeToReplaceSearchParams}
            disabled={props.node}
          />
          {props.node ? null : (
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="outlined-adornment-password">
                {t("nodeToReplace")}
              </InputLabel>
              <OutlinedInput
                fullWidth
                label={t("nodeToReplace")}
                value={node ? getName(node) : ""}
                onChange={() => null}
                endAdornment={
                  <IconButton
                    onClick={() => {
                      setNode();
                    }}
                  >
                    <MdClose />
                  </IconButton>
                }
              />
            </FormControl>
          )}

          <AsyncAutocomplete
            t={t}
            value={newNode}
            registryVersion={props.registryVersion}
            onSelect={(_node) => {
              if (_node) setNewNode(_node);
            }}
            label={t("findReplacement")}
            searchParams={
              node
                ? node.type === 3
                  ? itemSearchParams
                  : node.type === 10
                  ? packagSearchParams
                  : workSearchParams
                : nodeToReplaceSearchParams
            }
            filterResults={(list) =>
              node ? list.filter((x) => x.id !== node.id) : list
            }
          />
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="outlined-adornment-password">
              {t("replacement")}
            </InputLabel>
            <OutlinedInput
              fullWidth
              label={t("replacement")}
              value={newNode ? getName(newNode) : ""}
              onChange={() => null}
              endAdornment={
                <IconButton
                  onClick={() => {
                    setNewNode();
                  }}
                >
                  <MdClose />
                </IconButton>
              }
            />
          </FormControl>
          {paths && (
            <CheckboxTree
              nodes={paths.nodes}
              pathsArr={paths.paths}
              tree={paths.tree}
              disabled={saving}
              height={height - 278 - (props.node ? 0 : 56)}
              width={width - 32}
              checked={checked}
              setChecked={setChecked}
            />
          )}
        </Stack>
        <Stack
          direction="row"
          sx={{
            justifyContent: "space-between",
            padding: 2,
            paddingTop: 0,
          }}
        >
          <Button
            onClick={props.onClose}
            color="text"
            variant="outlined"
            startIcon={<MdClose />}
          >
            {t("cancel")}
          </Button>
          <LoadingButton
            disabled={saving || !paths || !node || !newNode}
            onClick={() => {
              setSaving(true);
            }}
            variant="contained"
            loading={saving}
            loadingPosition="end"
            endIcon={<MdSave />}
            color="basic"
          >
            {t("replace")}
          </LoadingButton>
        </Stack>
      </Box>
    </>
  );
});
export default NodeReplace;
