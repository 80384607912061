import React, { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdChevronRight } from "react-icons/md";
import moment from "moment";
import PulseLoader from "react-spinners/PulseLoader";

import { FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

import { withStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import {
  getName,
  getNodeIcon,
  pathToIds,
  roots,
  rootToURL,
} from "../helpers/functions";
import { request } from "../helpers/api";

import { useHistory } from "react-router-dom";

import { toast } from "react-toastify";

export const StyledBreadcrumbs = withStyles({
  separator: {
    marginRight: 0,
    marginLeft: 0,
  },
})(Breadcrumbs);

function Path({ parent, isLast }) {
  return (
    <div className="flex-row flex-align-center">
      <div className="path-text">
        <div className="path-icon">
          {getNodeIcon(false, parent?.nodeType, parent?.type, false)}
        </div>

        {getName(parent)}
      </div>
      {!isLast && (
        <div className="left-padding-4">
          <MdChevronRight />
        </div>
      )}
    </div>
  );
}

function PathRow({ data, index, style }) {
  const { history, paths, nodes, _node, root } = data;
  const pathObj = paths[index];
  if (pathObj.path) {
    const ids = pathToIds(pathObj.path);
    return (
      <div style={style}>
        <React.Fragment key={"Breadcrumb-" + pathObj.path}>
          <Stack direction="row">
            {ids.map((id, _idIndex) => {
              const parent = nodes[id];

              const onPathClick = () => {
                const pathRootObj = nodes[ids[0]];

                const pathRoot = roots[pathRootObj.code];

                let to = "/" + rootToURL[pathRoot] + "?path=,";
                for (let i = 0; i < _idIndex; i++) {
                  to += ids[i] + ",";
                }
                to += id + ",";
                to += `&node=${
                  _node.id
                }&clicked=${id}&linkTime=${moment().valueOf()}`;

                if (pathRoot === root) {
                  history.push(to);
                } else {
                  window.open(to, "_blank", "noopener");
                }
              };

              return (
                <React.Fragment key={pathObj.path + index + id}>
                  <div className="cursor-pointer" onClick={onPathClick}>
                    <Path
                      isLast={_idIndex === ids.length - 1}
                      parent={parent ?? { id }}
                    />
                  </div>
                </React.Fragment>
              );
            })}
          </Stack>
        </React.Fragment>
      </div>
    );
  } else return null;
}

export default function PathsView(props) {
  let history = useHistory();
  const { t } = useTranslation();
  const [_node, _setNode] = useState(null);
  const nodeRef = useRef(props.node);
  const [isFetching, setIsFetching] = useState(false);
  const registryVersionRef = useRef(props.registryVersion);
  const isMissingNode = !props.node?.id && props.node?.code;
  const _nodeId = isMissingNode ? _node?.code : _node?.id;
  const paths = _node ? props.paths?.[_nodeId]?.pathsArray || [] : [];
  const nodes = _node ? props.paths?.[_nodeId]?.nodes || {} : {};
  // TODO handle versionchange paths
  // should work if displaying old item on left with old paths
  // should add background colors for changed paths?

  const getPaths = () => {
    setIsFetching(true);
    // TODO root is deprecated from request
    // fetch missing node paths if node is missing id
    (isMissingNode
      ? request("nodes/missingNodes/paths", "get", null, {
          code: props.node.code,
          registryVersion: props.registryVersion,
        })
      : request("nodes/itemPaths", "get", null, {
          id: props.node.id,
          nodeType: props.node.nodeType,
          root: props.root,
          registryVersion: props.registryVersion,
        })
    )
      .then((res) => {
        if (res.status === 200) {
          props.SET_PATHS({
            ...res.data,
            nodeId: isMissingNode ? props.node.code : res.data.nodeId,
            registryVersion: props.registryVersion,
          });
          _setNode(props.node);
        }
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  useEffect(() => {
    if (
      (!isFetching &&
        props.fetchOnOpen &&
        props.open &&
        _nodeId !== (props.node?.id || props.node?.code)) ||
      registryVersionRef.current !== props.registryVersion
    ) {
      registryVersionRef.current = props.registryVersion;
      getPaths();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    //_node, causes infinite loop
    props.node,
    _nodeId,
    props.registryVersion,
    props.fetchOnOpen,
    props.open,
  ]);

  const nodeCode = props.node?.code;
  const nodeId = props.node?.id;
  useEffect(() => {
    if (
      !isFetching &&
      !props.fetchOnOpen &&
      (isMissingNode
        ? nodeRef.current?.code !== nodeCode
        : nodeRef.current?.id !== nodeId)
    ) {
      _setNode(null);

      nodeRef.current = props.node;
      getPaths();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodeId, nodeCode]);

  const itemSize = 27;
  const itemsHeight = paths.length > 0 ? paths.length * itemSize + 24 : 0;
  return (
    <Box
      sx={{
        padding: 1,
        border: 1,
        borderColor: "rgba(0, 0, 0, 0.23)",
        borderRadius: "4px",
      }}
    >
      {isFetching || !_node ? (
        <PulseLoader />
      ) : paths.length < 1 ? (
        <>
          <Typography sx={{ padding: "6px" }}>{t("noPaths")}</Typography>
          <Divider />
        </>
      ) : (
        <AutoSizer disableHeight>
          {({ width }) => (
            <FixedSizeList
              height={
                props.height ??
                (itemsHeight > props.maxHeight ? props.maxHeight : itemsHeight)
              }
              width={width}
              itemSize={itemSize}
              itemCount={paths.length}
              itemData={{
                _node,
                paths: paths,
                nodes: nodes,
                root: props.root,
                history,
              }}
            >
              {PathRow}
            </FixedSizeList>
          )}
        </AutoSizer>
      )}
    </Box>
  );
}
