import { createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import rootReducer from "../reducers";
import localForage from "localforage";

const persistConfig = {
  key: "root",
  storage: localForage,
  stateReconciler: autoMergeLevel2,
  blacklist: ["nodes", "trees", "tables"],
};

const pReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  pReducer
  //applyMiddleware(thunk)
);
export const persistor = persistStore(store);
