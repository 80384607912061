import React from "react";
import { MdHelp } from "react-icons/md";
import Tooltip from "./Tooltip";

export default function TooltipIcon(props) {
  return (
    <Tooltip tip={props.tip}>
      <MdHelp size={props.size || 26} />
    </Tooltip>
  );
}
