import React from "react";
import Paper from "@mui/material/Paper";
import { TextInput } from "../components/StyledComponents";

function getItemChanges(item, index, key, item2 = {}, removed, t) {
  return (
    <div
      key={"ItemChanges" + key + index}
      style={{
        display: "flex",
        alignItems: "space-around",
        flexDirection: "row",
        padding: 5,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "space-around",
          flexDirection: "row",
          backgroundColor: !removed && item.id !== item2.id ? "#ebc96c" : null,
        }}
      >
        <p
          style={{
            margin: 0,
            padding: 0,
            marginRight: 10,
            marginLeft: 10,
          }}
        >
          {t("code")}
        </p>
        <TextInput
          value={item.code}
          onChange={() => null}
          inputprops={{ readOnly: true }}
        />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "space-around",
          flexDirection: "row",
          backgroundColor:
            !removed && item.amount !== item2.amount ? "#ebc96c" : null,
        }}
      >
        <p
          style={{
            margin: 0,
            padding: 0,
            marginRight: 10,
            marginLeft: 10,
          }}
        >
          {t("amount")}
        </p>
        <TextInput
          value={item.amount}
          onChange={() => null}
          inputprops={{ readOnly: true }}
        />
      </div>
    </div>
  );
}

function getRemovedOrAddedItemsList(items1 = [], items2, title, key, t) {
  const items = [];

  items1.forEach((item1, index) => {
    if (!items2?.some((item2) => item2.id === item1.id)) {
      items.push(getItemChanges(item1, index, key, null, true, t));
    }
  });

  if (items && items.length > 0) {
    return (
      <Paper
        key={"RemovedOrAddedItem" + key}
        elevation={3}
        style={{
          backgroundColor: title === "removed" ? "#c79999" : "#b9d4b4",
          padding: 10,
          width: "calc(100% - 20px)",
        }}
      >
        <p style={{ margin: 0, padding: 0, marginRight: 10 }}>{t(title)}</p>
        {items}
      </Paper>
    );
  } else {
    return null;
  }
}

function getModifiedItemsList(items1 = [], items2, title, key, t) {
  const items = [];

  items1.forEach((item1, index) => {
    const found = items2?.find((item2) => item2.id === item1.id);
    if (found) {
      items.push(getItemChanges(item1, index, key, found, false, t));
    }
  });

  if (items && items.length > 0) {
    return (
      <React.Fragment key={"ModifiedItem" + key}>
        <p style={{ margin: 0, padding: 0, marginRight: 10 }}>{t(title)}</p>
        {items}
      </React.Fragment>
    );
  } else {
    return null;
  }
}

export default function ModifiedItemsView({
  sideKey,
  index,
  innerIndex,
  t,
  prop,
  addedOrRemovedTitle,
  val1,
  val2,
  className,
}) {
  return (
    <Paper
      elevation={3}
      className={className}
      style={className ? null : { padding: 10, marginBottom: 10 }}
      key={sideKey + index + innerIndex}
    >
      <p style={{ margin: 0, padding: 0 }}>{t(prop.toLowerCase())}</p>

      {getRemovedOrAddedItemsList(
        val1,
        val2,
        addedOrRemovedTitle,
        "LeftSide" + index + innerIndex,
        t
      )}
      {getModifiedItemsList(
        val1,
        val2,
        "modified",
        "LeftSide" + index + innerIndex,
        t
      )}
    </Paper>
  );
}
