import { useState, useEffect } from "react";

const initialRequesting = { loading: null, params: null };

export default function Request(props) {
  const [requesting, setRequesting] = useState(initialRequesting);
  const onDone = () => {
    setRequesting(initialRequesting);
  };
  useEffect(() => {
    if (requesting.loading) {
      props.onRequest[requesting.loading](
        requesting.params,
        requesting.loading,
        onDone
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requesting]);
  return [requesting.loading, requesting.params, setRequesting];
}
