import React from "react";

import { useTranslation } from "react-i18next";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";

import { MdSave } from "react-icons/md";

/**
 * ConfirmDialog component.
 *
 * Renders a confirmation modal with loading button.
 *
 * Parent controls visibility.
 * @component
 * @param {Object} props props for the component
 * @param {string} props.title - Dialog title
 * @param {string} props.cancelButtonTitle - Cancel button label
 * @param {string} props.saveButtonTitle - Save button label
 * @param {JSX} props.children children to render as content
 * @param {boolean} props.open - dialog visibility
 * @param {Function} props.setOpen - function to control dialog visibility with
 * @param {Function} props.onConfirm - function called on confirmation
 * @param {Any} props.value - arg passed to onConfirm
 * @param {boolean} props.loading - whether to display loading button
 * @param {boolean} props.hideSaveButton - whether to hide save button
 * @returns JSX
 */
const ConfirmDialog = (props) => {
  const { title, children, open, setOpen, onConfirm, value, loading } = props;
  const { t } = useTranslation();

  const handleClose = (event, reason) => {
    if (props.disableBackdropClick && reason && reason === "backdropClick")
      return;
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="confirm-dialog">
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button color="basic" onClick={() => setOpen(false)}>
          {props.cancelButtonTitle ?? t("cancel")}
        </Button>
        {props.hideSaveButton ? null : (
          <LoadingButton
            loading={loading}
            loadingPosition="end"
            endIcon={<MdSave />}
            disabled={loading}
            onClick={() => {
              setOpen(false);
              onConfirm(value);
            }}
            color="basic"
          >
            {props.saveButtonTitle ?? t("yes")}
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmDialog;
