import React from "react";
import { useTranslation } from "react-i18next";

import WindowWrapper from "../components/WindowWrapper";

function VersionsScreen(props) {
  const { t } = useTranslation();
  return (
    <WindowWrapper
      t={t}
      uiSettingsProp={"versionsScreen"}
      generateFlexLayoutJson={() => ({
        global: {
          tabEnableRename: true,
          tabSetEnableClose: true,
        },
        borders: [
          {
            type: "border",
            location: "top",
            barSize: 64,
            children: [],
          },
        ],
        layout: {
          type: "row",
          children: [
            {
              type: "row",
              children: [
                {
                  type: "tabset",
                  weight: 70,
                  children: [
                    {
                      type: "tab",
                      name: t("versions"),
                      component: "versions",
                      enableClose: false,
                    },
                  ],
                },
              ],
            },
          ],
        },
      })}
    />
  );
}

export default VersionsScreen;
