import { checkIfArr, getDeletedNodesWithPath } from "./functions";
import { requestTreeChildren, requestChildNode } from "./requests";

// Doesn't request children if childrenLength > 0
// nodeType 5 + type 10 items with no nodeText will be requested with requestChildNode
// nodeType 5 with any other type that aren't found in products will be requested in requestTreeChildren
/**
 * Toggle node open, get node item codes for request. Request only if children and needed node items aren't cached.
 * If versionChanges param is present and truthy, calls getDeletedNodesWithPath function and requests nodes returned from it.
 * @param {Function} SET_CHILDREN reducer action to set children
 * @param {Function} useRootPath useRootPath for SET_CHILDREN action
 * @param {Function} SET_NODES reducer action to set items
 * @param {String} childrenLength nodes childrens arr length
 * @param {String} path path from node obj
 * @param {String} reduxRoot root prop for redux
 * @param {String} treeRoot root prop for tree
 * @param {Array} nodeItems nodes items arr
 * @param {Object} productsMap products map from redux
 * @param {Object} nodesMap nodes map from redux
 * @param {String} nodeRegistryVersion nodes registryVersion
 * @param {String} registryVersion current registryVersion
 * @param {Object} versionChanges object with versionChanges
 */
export function getChildren(
  SET_CHILDREN,
  useRootPath,
  SET_NODES,
  childrenLength,
  path,
  reduxRoot,
  treeRoot,
  // node items has some versionCompare changes made to the list, not the default nodes item list
  nodeItems = [],
  productsMap = new Map(),
  nodesMap = new Map(),
  nodeRegistryVersion,
  registryVersion,
  versionChanges,
  dontSaveToRedux
) {
  let requests = [];
  let _nodeItems = [...nodeItems];
  let itemsToRequest = [];
  let nodesToRequest = [];
  let deletedNodeObjects = getDeletedNodesWithPath(versionChanges, path);
  let deletedNodes = [];

  deletedNodeObjects.forEach((x) => {
    if (x.node) {
      deletedNodes.push(x.node);
    }
  });

  if (checkIfArr(_nodeItems)) {
    for (let i = 0; i < _nodeItems.length; i++) {
      const item = _nodeItems[i];
      if (item.type === 3) {
        itemsToRequest.push(item.id);
      } else {
        nodesToRequest.push({
          node: item,
          nodeRegistryVersion,
        });
      }
    }
  }

  if (checkIfArr(nodesToRequest)) {
    requests.push(
      requestChildNode(nodesToRequest, nodeRegistryVersion || registryVersion)
    );
  }

  if (childrenLength === 0 || itemsToRequest.length !== 0) {
    requests.push(
      requestTreeChildren(
        path,
        itemsToRequest,
        nodeRegistryVersion,
        registryVersion,
        treeRoot,
        deletedNodes
      )
    );
  }

  let nodesPayload = { nodes: [], registryVersion };
  if (requests.length > 0) {
    return Promise.all(requests).then((res) => {
      let childrenToAdd = [];

      res.forEach((cur) => {
        if (checkIfArr(cur.children)) {
          cur.children.forEach((x) => {
            nodesPayload.nodes.push(x);
            childrenToAdd.push({ ...x, path, originalPath: x.path });
          });
        }

        if (cur.items) {
          const itemsValues = Object.values(cur.items);
          if (itemsValues.length > 0) {
            itemsValues.forEach((x) => {
              if (x) {
                nodesPayload.nodes.push(x);
                const _node = { ...x, path, originalPath: x.path };
                childrenToAdd.push(_node);
              }
            });
          }
        } else if (cur.itemsArr) {
          cur.itemsArr.forEach((x) => {
            if (x) {
              nodesPayload.nodes.push(x.res);
              const _node = {
                ...x.merged,
                path,
                originalPath: x.merged.path,
              };
              childrenToAdd.push(_node);
            }
          });
        }
      });

      _nodeItems.forEach((x) => {
        if (x.missing) {
          nodesPayload.nodes.push({
            ...x,
            id: x.code,
          });
          childrenToAdd.push({
            ...x,
            id: x.code,
            path,
            originalPath: path,
          });
        }
      });

      const childrenPayload = {
        root: reduxRoot,
        registryVersion,
        data: childrenToAdd,
        path,
        useRootPath,
      };

      if (!dontSaveToRedux) {
        SET_NODES(nodesPayload);
        SET_CHILDREN(childrenPayload);
      }

      return { childrenPayload, nodesPayload };
    });
  } else {
    return Promise.resolve(true);
  }
}
