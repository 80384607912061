import { combineReducers } from "redux";
import localForage from "localforage";
import hardSet from "redux-persist/lib/stateReconciler/hardSet";
import { persistReducer } from "redux-persist";

import TreeReducer from "./TreeReducer";
import NodesReducer from "./NodesReducer";
import TablesReducer from "./TablesReducer";
import VersionsReducer from "./VersionsReducer";
import CommonReducer from "./CommonReducer";

// const itemsPersistConfig = {
//   key: "items",
//   storage: localForage,
//   stateReconciler: hardSet,
// };

// const treePersistConfig = {
//   key: "tree",
//   storage: localForage,
//   stateReconciler: hardSet,
//   blacklist: ["products", "tmp"],
// };

// const tablesPersistConfig = {
//   key: "tables",
//   storage: localForage,
//   stateReconciler: hardSet,
//   blacklist: ["table"],
// };

const versionsPersistConfig = {
  key: "versions",
  storage: localForage,
  stateReconciler: hardSet,
  whitelist: ["selectedVersion"],
};

const commonPersistConfig = {
  key: "common",
  storage: localForage,
  stateReconciler: hardSet,
};

export default combineReducers({
  nodes: NodesReducer, //persistReducer(itemsPersistConfig, NodesReducer),
  trees: TreeReducer, //persistReducer(treePersistConfig, TreeReducer),
  tables: TablesReducer, //persistReducer(inputPersistConfig, TablesReducer),
  versions: persistReducer(versionsPersistConfig, VersionsReducer),
  common: persistReducer(commonPersistConfig, CommonReducer),
});
