import { Box, Typography } from "@mui/material";
import MsLoginButton from "../components/MsLoginButton";

export function LoginPage() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        gap: "48px",
      }}
    >
      <Typography variant="h3">Kirjaudu sisään</Typography>
      <MsLoginButton />
    </Box>
  );
}
