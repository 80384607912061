import React from "react";
import { MdClose } from "react-icons/md";
import useWindowDimensions from "../helpers/useWindowDimensions";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Draggable from "react-draggable";
import { ResizableBox } from "react-resizable";
import "react-resizable/css/styles.css";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

/**
 * DraggableDialog component.
 *
 * Renders a modal that can be moved and resized.
 *
 * Parent controls visibility and dimensions.
 * @component
 * @param {Object} props props for the component
 * @param {string} props.title - Dialog title
 * @param {JSX} props.children children to render as content
 * @param {boolean} props.open - dialog visibility
 * @param {Function} props.handleClose - function to control dialog visibility with
 * @param {number} props.height - controlled height
 * @param {number} props.width - controlled width
 * @param {Function} props.setDimensions - Function to set dimensions
 * @param {JSX} [props.children] - children to render
 * @returns JSX
 */
export default function DraggableDialog(props) {
  const { windowHeight, windowWidth } = useWindowDimensions();
  return (
    <Dialog
      maxWidth="calc(100% - 64px)"
      open={props.open}
      onClose={props.handleClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <ResizableBox
        resizeHandles={["se"]}
        height={props.height}
        width={props.width}
        onResize={(event, data) => {
          props.setDimensions({
            height: props.height + event.movementY,
            width: props.width + event.movementX,
          });
        }}
        minConstraints={[100, 100]}
        maxConstraints={[windowWidth - 64, windowHeight - 64]}
      >
        <>
          <DialogTitle
            sx={{
              cursor: "move",
              display: "flex",
              justifyContent: "space-between",
            }}
            id="draggable-dialog-title"
          >
            {props.title}
            <IconButton onClick={props.handleClose}>
              <MdClose />
            </IconButton>
          </DialogTitle>
          <DialogContent>{props.children}</DialogContent>
        </>
      </ResizableBox>
    </Dialog>
  );
}
