import React from "react";

import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { getName, getNodeIcon } from "../helpers/functions";

import cx from "classnames";

/**
 * AutoCompleteOption component used in AsyncAutocomplete.
 *
 * Renders the node with proper name and icon. Also has an onHover popover Form.
 * @component
 * @param {Object} props props for the component
 * @param {Function} props.t - translation function
 * @param {Object} props.option - Node passed from AsyncAutocompletes renderOption
 * @param {string} props.registryVersion - currently selected registryVersion
 * @param {string} props.treeRoot - Tree root e.g. "packets"
 * @param {Object} props.productsMap - products map from redux
 * @param {Object} props.nodesMap - nodes map from redux
 * @returns JSX
 */
export default function AutoCompleteOption(props) {
  const { option, className = "" } = props;
  const name = getName(option);

  return (
    <>
      <Stack
        p={1}
        direction="row"
        alignItems="center"
        spacing={1}
        key={name}
        className={cx(className, "cursor-pointer")}
      >
        <div className="node-icon">
          {getNodeIcon(false, option.nodeType, option.type, false)}
        </div>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ whiteSpace: "nowrap" }}
        >
          {name}
        </Typography>
      </Stack>
    </>
  );
}
