import update from "immutability-helper";
import { pathToIds, unflatten } from "../helpers/functions";

const INITIAL_TREE_STATE = {
  packets: {},
  tables: {},
  nomenclatures: {},
  products: {},
  tmp: {},
};

const minimizeNode = (x) => ({
  id: x.id,
  type: x.type,
  nodeType: x.nodeType,
  path: x.path,
  items: x.items,
  children: x.children,
  isEmpty: x.isEmpty,
});

// const util = require("util");

const updateNestedPaths = (arr, parentPath) => {
  for (let i = 0; i < arr.length; i++) {
    const node = arr[i];
    node.path = parentPath;
    if (node.children) {
      updateNestedPaths(node.children, parentPath + node.id + ",");
    }
  }
  return arr;
};

const sliceFromUpdateStringEnd = (__updateString, sliceAmount = 1) => {
  let indexesOfDots = [];
  for (let i = __updateString.length - 1; i > 0; i--) {
    const char = __updateString[i];
    if (char === ".") {
      indexesOfDots.push(i);
      if (indexesOfDots.length === sliceAmount) break;
    }
  }

  return __updateString.substring(0, indexesOfDots[sliceAmount - 1]);
};

const getTreeIndices = (arr, _ids, _idIndex, indices, log) => {
  if (!arr) return null;
  if (_idIndex < _ids.length) {
    const index = arr.findIndex((x) => x.id === _ids[_idIndex]);
    indices.push(index);
    if (arr[index].children) {
      return getTreeIndices(arr[index].children, _ids, _idIndex + 1, indices);
    } else {
      return indices;
    }
  } else return indices;
};

const generateUpdateStringWithPath = (
  state,
  root,
  registryVersion,
  path,
  log
) => {
  const ids = pathToIds(path);
  const indices = getTreeIndices(state[root][registryVersion], ids, 0, [], log);
  // if full path couldn't be traversed or there is no array at all, return null
  if (indices.length !== ids.length || indices === null) return null;
  return indices.reduce((updateString, index) => {
    return updateString + `.[${index}].children`;
  }, "");
};

const SET_CHILDREN = (state, payload, log) => {
  const { root, registryVersion, data, path, useRootPath, useOldChildren } =
    payload;
  if (!root) return state;

  if (path) {
    let _path = path;
    // if useRootPath is true, the path should start from root folders id
    if (useRootPath) {
      const start = _path.search(
        new RegExp(`\\b(${state[root][registryVersion][0].id})\\b`)
      );
      _path = "," + _path.substring(start, _path.length);
    }

    let updateString = generateUpdateStringWithPath(
      state,
      root,
      registryVersion,
      _path,
      log
    );

    const split = updateString.split(".");
    split.pop();
    updateString = `${root}.$auto.${registryVersion}${split.join(".")}`;
    if (data.length > 0) {
      return update(
        state,
        // if we want to preserve children we use the current arr and merge it with new one
        useOldChildren
          ? unflatten({
              [updateString]: {
                $apply: (node) => {
                  const children = update(node.children, {
                    $autoArray: {
                      $apply: (arr) =>
                        data.map((x) => ({
                          ...minimizeNode(x),
                          children: arr.find((n) => n.id === x.id)?.children,
                        })),
                    },
                  });
                  return update(node, {
                    children: { $set: children },
                    isEmpty: { $set: children.length === 0 },
                  });
                },
              },
            })
          : unflatten({
              [updateString]: {
                children: { $set: data.map(minimizeNode) },
                isEmpty: { $set: data.length === 0 },
              },
            })
      );
    } else {
      return update(
        state,
        unflatten({
          [updateString]: { isEmpty: { $set: true } },
        })
      );
    }
  } else if (useOldChildren) {
    return update(state, {
      [root]: {
        $auto: {
          [registryVersion]: {
            $autoArray: {
              $apply: (arr) =>
                data.map((x) => ({
                  ...minimizeNode(x),
                  children: arr.find((n) => n.id === x.id)?.children,
                })),
            },
          },
        },
      },
    });
  } else {
    return update(state, {
      [root]: {
        $auto: { [registryVersion]: { $set: data.map(minimizeNode) } },
      },
    });
  }
};

export default function treeReducer(state = INITIAL_TREE_STATE, action) {
  try {
    if (action.type === "SET_CHILDREN") {
      return SET_CHILDREN(state, action.payload, action.log);
    } else if (action.type === "SET_MULTIPLE_CHILDREN") {
      const { payloads } = action.payload;
      let _state = state;
      payloads.forEach((x) => {
        _state = SET_CHILDREN(_state, x);
      });
      return _state;
    } else if (action.type === "CLEAR_TREE") {
      const { root, registryVersion } = action.payload;
      if (!root) return state;

      return update(state, {
        [root]: { $auto: { [registryVersion]: { $set: [] } } },
      });
    } else if (action.type === "REPLACE_TREE") {
      const { root, registryVersion, data } = action.payload;
      if (!root) return state;

      return update(state, {
        [root]: {
          $auto: { [registryVersion]: { $set: data.map(minimizeNode) } },
        },
      });
    } else if (action.type === "UPDATE_NODE") {
      const { root, registryVersion, node } = action.payload;
      if (!root) return state;

      const updateString = generateUpdateStringWithPath(
        state,
        root,
        registryVersion,
        node.path + node.id + ","
      );

      if (updateString) {
        const _updateString = sliceFromUpdateStringEnd(updateString);
        return update(
          state,
          unflatten({
            [`${root}.$auto.${registryVersion}${_updateString}.$apply`]: (
              x
            ) => {
              // let _children;
              // if (node.items) {
              //   _children = [...node.items];
              // } else {
              //   _children = x.children;
              // }
              return {
                ...x,
                ...node,
                path: x.path,
                // children: _children,
              };
            },
          })
        );
      }
    } else if (action.type === "MOVE_NODE") {
      // add node under target
      // delete node from old place
      // update all nested updateStrings or just delete children and fetch again
      const { root, registryVersion, node, target } = action.payload;
      if (!root) return state;

      let _state;
      if (node.path === ",") {
        _state = update(
          state,
          unflatten({
            [root]: {
              $auto: {
                [registryVersion]: {
                  $apply: (rootArr) => {
                    let arrIndex = (rootArr || []).findIndex(
                      (x) => x.id === node.id
                    );
                    if (arrIndex === -1) {
                      return rootArr;
                    }
                    return update(rootArr, {
                      $splice: [[arrIndex, 1]],
                    });
                  },
                },
              },
            },
          })
        );
      } else {
        const updateString = generateUpdateStringWithPath(
          state,
          root,
          registryVersion,
          node.path
        );

        if (updateString) {
          let _updateString = sliceFromUpdateStringEnd(updateString);
          _state = update(
            state,
            unflatten({
              [`${root}.$auto.${registryVersion}${_updateString}.$apply`]: (
                parent
              ) => {
                let arrIndex = (parent.children || []).findIndex(
                  (x) => x.id === node.id
                );
                if (arrIndex === -1) {
                  return parent;
                }
                const newArr = update(parent.children, {
                  $splice: [[arrIndex, 1]],
                });
                if (newArr.length === 0) {
                  return update(parent, {
                    isEmpty: { $set: true },
                    children: {
                      $set: [],
                    },
                  });
                } else {
                  return update(parent, {
                    children: {
                      $set: newArr,
                    },
                  });
                }
              },
            })
          );
        }
      }

      if (target) {
        const targetUpdateString = generateUpdateStringWithPath(
          _state,
          root,
          registryVersion,
          target.path + target.id + ","
        );
        const _targetUpdateString =
          sliceFromUpdateStringEnd(targetUpdateString);

        return update(
          _state,
          unflatten({
            // adding
            [`${root}.$auto.${registryVersion}${_targetUpdateString}.$apply`]: (
              parent
            ) => {
              const newArr = update(parent.children || [], {
                $push: [
                  update(node, {
                    path: {
                      $set: parent.path + parent.id + ",",
                    },
                  }),
                ],
              });
              return update(parent, {
                children: {
                  $set: updateNestedPaths(
                    newArr,
                    parent.path + parent.id + ","
                  ),
                },
              });
            },
          })
        );
      } else {
        return update(_state, {
          [root]: {
            $auto: {
              [registryVersion]: { $push: updateNestedPaths([node], ",") },
            },
          },
        });
      }
    } else if (action.type === "DELETE_NODE") {
      const { root, registryVersion, node } = action.payload;
      if (!root) return state;

      if (node.path === ",") {
        return update(
          state,
          unflatten({
            [root]: {
              $auto: {
                [registryVersion]: {
                  $apply: (rootArr) => {
                    let arrIndex = (rootArr || []).findIndex(
                      (x) => x.id === node.id
                    );
                    if (arrIndex === -1) {
                      return rootArr;
                    }
                    return update(rootArr, {
                      $splice: [[arrIndex, 1]],
                    });
                  },
                },
              },
            },
          })
        );
      } else {
        const updateString = generateUpdateStringWithPath(
          state,
          root,
          registryVersion,
          node.path
        );

        if (updateString) {
          let _updateString = sliceFromUpdateStringEnd(updateString);
          return update(
            state,
            unflatten({
              [`${root}.$auto.${registryVersion}${_updateString}.$apply`]: (
                parent
              ) => {
                let arrIndex = (parent.children || []).findIndex(
                  (x) => x.id === node.id
                );
                if (arrIndex === -1) {
                  return parent;
                }
                const newArr = update(parent.children, {
                  $splice: [[arrIndex, 1]],
                });
                if (newArr.length === 0) {
                  return update(parent, {
                    isEmpty: { $set: true },
                    children: {
                      $set: [],
                    },
                  });
                } else {
                  return update(parent, {
                    children: {
                      $set: newArr,
                    },
                  });
                }
              },
            })
          );
        }
      }
    } else if (action.type === "ADD_NODES") {
      // add node under target
      // update all nested updateStrings
      const { root, registryVersion, nodes, target } = action.payload;
      if (!root) return state;

      if (target) {
        const targetUpdateString = generateUpdateStringWithPath(
          state,
          root,
          registryVersion,
          target.path + target.id + ","
        );

        if (targetUpdateString) {
          const _targetUpdateString =
            sliceFromUpdateStringEnd(targetUpdateString);

          return update(
            state,
            unflatten({
              // adding
              [`${root}.$auto.${registryVersion}${_targetUpdateString}.$apply`]:
                (parent) => {
                  const newArr = update(parent.children || [], {
                    $push: nodes.map(minimizeNode),
                  });
                  return update(parent, {
                    children: {
                      $set: updateNestedPaths(
                        newArr,
                        parent.path + parent.id + ","
                      ),
                    },
                  });
                },
            })
          );
        }
      } else {
        return update(state, {
          [root]: {
            $auto: {
              [registryVersion]: { $push: updateNestedPaths(nodes, ",") },
            },
          },
        });
      }
    } else if (action.type === "REPLACE_NODES") {
      const { registryVersion, nodes, oldNode, newNode } = action.payload;

      let _state = state;

      const isMissingNode = !oldNode.id && oldNode.code;
      nodes.forEach((node) => {
        // this can only be used for nodeReplace endpoint response handling for now,
        // since we can't tell which root folders belong to
        const root = node.type === 10 ? "packets" : "tables";
        let updateString;
        // will throw if path is not found, and path may not be in tree yet
        try {
          updateString = generateUpdateStringWithPath(
            state,
            root,
            registryVersion,
            node.path + node.id + ","
          );
        } catch (error) {}

        if (updateString) {
          const _updateString = sliceFromUpdateStringEnd(updateString);
          _state = update(
            _state,
            unflatten({
              [`${root}.$auto.${registryVersion}${_updateString}.$apply`]: (
                x
              ) => {
                return {
                  ...x,
                  children: x.children
                    ? x.children.map((child) => {
                        if (
                          isMissingNode
                            ? child.code === oldNode.code
                            : child.id === oldNode.id
                        ) {
                          return {
                            ...child,
                            id: newNode.id,
                            code: newNode.code,
                          };
                        } else {
                          return child;
                        }
                      })
                    : undefined,
                  items: newNode.items,
                  // children: _children,
                };
              },
            })
          );
        }
      });
      return _state;
    }

    return state;
  } catch (error) {
    console.error(
      `Error: ${error}, Reducer: TreeReducer, Action: ${
        action.type
      }, Payload: ${
        action.payload ? JSON.stringify(action.payload) : "no payload"
      }`
    );
    console.error(error);
    return state;
  }
}
