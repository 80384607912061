import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";

import { MdOutlineWarning, MdClose, MdSave } from "react-icons/md";

import { FixedSizeList } from "react-window";

import ConfirmDialog from "./ConfirmDialog";
import ItemsView from "./ItemsView";
import VirtualizedTable from "./VirtualizedTable";
import Tooltip from "./Tooltip";

import useWindowDimensions from "../helpers/useWindowDimensions";
import { request } from "../helpers/api";
import { getName } from "../helpers/functions";

import { toast } from "react-toastify";
import TooltipIcon from "./TooltipIcon";

function PackagePreview({ node, index }) {
  const { t } = useTranslation();
  // TODO mark items with missing ids as missing
  const backgroundColor = index % 2 ? "#ededed" : "#fff";
  return (
    <Box sx={{ paddingRight: 1 }}>
      <Card
        sx={{
          width: "100%",
          marginTop: 1,
          marginBottom: 1,
        }}
        variant="outlined"
      >
        <Paper sx={{ p: 1, backgroundColor: backgroundColor }}>
          <Stack direction="row" spacing={2}>
            {node.codeCollision ? (
              <Tooltip tip={t("codeCollision")}>
                <MdOutlineWarning size={26} color={"red"} />
              </Tooltip>
            ) : null}

            <Typography variant="h6" sx={{}}>
              {node.code +
                (node?.nodeText?.name ? " " + node.nodeText.name : "")}
            </Typography>
          </Stack>
          <Divider />
          <Typography variant="caption">
            {(node.items ? node.items.length : "0") + " " + t("amountOfItems")}
          </Typography>

          <ItemsView
            backgroundColor={backgroundColor}
            paperElevation={0}
            height={140 - 24}
            maxHeight={140 - 24}
            //values={values}
            items={node.items}
            t={t}
            nodes={node.items.reduce((acc, cur) => {
              acc[cur.code] = cur;
              return acc;
            }, {})}
            editable={false}
          />
          {/* <ItemsList/> */}
        </Paper>
      </Card>
    </Box>
  );
}

function Row({ data, index, style }) {
  const item = data[index];

  return (
    <div style={style}>
      <PackagePreview node={item} index={index} />
    </div>
  );
}

const overlayId = "package-importer-overlay";

const excelColumnsToDataKey = [
  ["code", ["koodi", "paketinkoodi", "pakettikoodi"]],
  ["name", ["nimi", "paketinnimi", "pakettinimi"]],
  ["itemCode", ["nimikekoodi", "nimikkeenkoodi"]],
  ["itemName", ["nimikkeennimi", "(jääväliineipakollinentieto)"]], // ! ???
  ["amount", ["määrä"]],
  ["unit", ["yksikkö"]],
];
const initialColumns = [
  "code",
  "itemCode",
  "name",
  "itemName",
  "amount",
  "unit",
];
/**
 * Importing ui for multiple packages.
 *
 * Data to use for importing can be e.g.:
 * | Package code | Item Code    | Package name  | Item name   | Item amount | Item unit |
 * | ------------ | ------------ | ------------- | ----------- | ----------- | --------- |
 * | Pl 1         |              | testpackage1  |             |             | kpl       |
 * | Pl 1         | L123         | testpackage1  | testitem1   | 1           | kpl       |
 * | Pl 1         | L124         | testpackage1  | testitem2   | 2           | kpl       |
 * | Pl 2         |              | testpackage2  |             |             | kpl       |
 * | Pl 2         | L125         | testpackage2  | testitem3   | 3           | kpl       |
 * | Pl 2         | L126         | testpackage2  | testitem4   | 4           | kpl       |
 *
 * @param {Boolean} open Whether to show importer modal
 * @returns
 */
export default function PackageImporter(props) {
  const { windowHeight, windowWidth } = useWindowDimensions();
  const { t } = useTranslation();
  const [saving, setSaving] = useState(false);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState(
    initialColumns.map((x) => ({ dataKey: x, label: t(x) }))
  );
  const [packages, setPackages] = useState([]);
  const [dialog, setDialog] = useState({ visible: false });
  // I = iv, L = LV, E = er

  const setDialogVisible = (visible) => {
    setDialog((_dialog) => ({
      ..._dialog,
      visible,
    }));
  };

  const handleSave = () => {
    request("packages/importPackets", "POST", {
      targetId: props.node.id,
      nodes: packages,
    })
      .then((res) => {
        if (res.status === 200) {
          // TODO display created packages?
          if (res.data.added.length > 0) {
            props.ADD_NODES({
              nodes: res.data.added,
              root: props.reduxRoot,
              registryVersion: props.registryVersion,
              target: props.node,
            });
            toast.success(t("packageImportSuccess"));
          }

          if (res.data.conflicts.length > 0) {
            setDialog((_dialog) => ({
              ..._dialog,
              visible: true,
              hideSaveButton: true,
              cancelButtonTitle: t("close"),
              title: t("packageImportError"),
              text: `${t("packetsThatCantBeImported")}:\n\n${res.data.conflicts
                .map((x) => getName(x))
                .join("\n")}\n${
                res.data.canAdd.length > 0
                  ? "\n" +
                    t("packetsThatCanBeImported") +
                    "\n\n" +
                    res.data.canAdd.map((x) => getName(x)).join("\n")
                  : ""
              }`,
            }));
          }

          setSaving(false);
        } else {
          setSaving(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setSaving(false);
      });
  };

  useEffect(() => {
    if (saving) {
      handleSave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saving]);

  const handleColumnChange = (newColumns) => {
    setColumns(newColumns);
  };

  const handlePaste = (e) => {
    e.preventDefault();
    let paste = (e.clipboardData || window.clipboardData).getData("text");

    // handle excel adding \r\n always to the end of string
    if (paste.endsWith("\r\n")) {
      paste = paste.substring(0, paste.length - 2);
    }
    paste = paste.replace(/(?:\\[rn]|[\r\n]+)+/g, "\n");

    let _data = paste.split("\n").map((x) => x.split("\t"));

    let isMissingColumns = false;
    let _columns = [];

    // first row may be column names
    let firstRowIsNames = _data[0].every((x) => x && !/\d/.test(x));
    // try to detect columns from first row
    if (firstRowIsNames) {
      _data[0].forEach((x) => {
        const match = excelColumnsToDataKey.find((y) =>
          y[1].includes(x.toLowerCase().trim())
        );
        if (match) {
          _columns.push(match[0]);
        } else {
          isMissingColumns = true;
          _columns.push("");
        }
      });

      // setColumns(_data[0].map((x) => ({dataKey: x, })));
      _data = _data.slice(1, _data.length);
    }
    // try to detect columns automatically

    // code
    // package code column all codes should start with "P" and they should have both numbers
    // and characters
    // ! package name can also start with "P" and have numbers so detecting package code doesn't really work

    // name
    // every line has package name. Package name can be anything, we can assume the package name will be before items name

    // itemName
    // items name can be anything

    // amount
    // items amount is a number

    // unit
    // unit is usually less than 4 characters
    else {
      isMissingColumns = true;
      _columns = initialColumns;
      // _data[0].forEach((str) => {
      //   const _str = str.toLowerCase().replace(/\s+/g, "");
      //   if (_str.startsWith("pl") && /\w/g.test(str) && /\d/g.test(str)) {
      //     _columns.push("code");
      //   } else if (/[+-]?[0-9]+[.][0-9]*([e][+-]?[0-9]+)?/g.test(str)) {
      //     _columns.push("amount");
      //   } else if (str && str.length < 4) {
      //     _columns.push("unit");
      //   } else if (str && !_columns.includes("name")) {
      //     _columns.push("name");
      //   } else {
      //     _columns.push("");
      //   }
      // });
    }

    // fill in missing columns
    if (isMissingColumns) {
      let _index = -1;
      const missingColumns = initialColumns.filter(
        (x) => !_columns.includes(x)
      );

      _columns = _columns.map((x) => {
        if (!x) {
          _index++;
          return missingColumns[_index];
        } else {
          return x;
        }
      });
    }

    _columns = _columns.map((x) => ({ dataKey: x, label: t(x) }));

    if (_data[0].length === 5) {
      _columns = _columns.filter((x) => x.dataKey !== "itemName");
    }

    setColumns(_columns);
    setData(_data);
  };

  const getPackageList = async (_data, _columns) => {
    const res = await request("packages/createFromExcelData", "POST", {
      data: _data,
      columns: _columns.map((x) => x.dataKey),
    });

    if (res.status === 200) {
      setPackages(res.data);
    }
  };

  useEffect(() => {
    if (data.length > 0) {
      getPackageList(data, columns);
      // setPackages(
      //   data.map((_node) => ({
      //     code: _node[columns.findIndex((x) => x.dataKey === "code")],
      //     name: _node[columns.findIndex((x) => x.dataKey === "name")],
      //     items: data.map((__node) => ({})),
      //   }))
      // );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, columns]);

  useEffect(() => {
    if (props.open) {
      document.addEventListener("paste", handlePaste);
    } else {
      document.removeEventListener("paste", handlePaste);
    }
    return () => {
      document.removeEventListener("paste", handlePaste);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  const cellRenderer = ({ columnIndex, key, parent, rowIndex, style }) => {
    return (
      <Box className="text-padding-8">
        <Typography>{data[rowIndex][columnIndex]}</Typography>
      </Box>
    );
  };
  if (!props.open) return null;

  const width = windowWidth > 1000 ? 1000 : windowWidth - 32;
  const height = windowHeight + 4;
  // padding top = 16
  // padding bottom = 16
  // title = 32.5
  // title2 = 32
  // divider = 1
  // title3 = 32
  // buttons = 52.5
  // divider paddings = 5 * 8
  // = 222
  // height space for lists = (height - 222) / 2
  const listHeight = (height - 222) / 2;
  return (
    <>
      <div id={overlayId} className="overlay block" onClick={props.onClose} />
      <Box
        sx={{
          position: "fixed",
          top: "16px",
          left: "50%",
          transform: "translateX(-50%)",
          width: width,
          height: height,
          bgcolor: "background.paper",
          boxShadow: 24,
          zIndex: 1100,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Stack spacing={1} p={2}>
          <Stack direction="row" justifyContent="space-between">
            <Typography
              id="modal-package-importer-title"
              variant="h5"
              component="h2"
            >
              {t("packageImporting")}
            </Typography>
            <TooltipIcon tip={t("packageImporterTooltip")} size={28} />
          </Stack>

          <Typography id="modal-package-importer-title" variant="h6">
            {t("fieldIdentification")}
          </Typography>
          <VirtualizedTable
            id={"PackageImporter"}
            columnReorder={true}
            onColumnChange={handleColumnChange}
            cellHover={false}
            rowHover={false}
            enableHeader={true}
            rowCount={data.length}
            rowGetter={({ index }) => data[index]}
            rowHeight={30}
            height={listHeight}
            //handleRowClick={handleRowClick}
            cellRenderer={cellRenderer}
            columns={columns}
          />

          {packages.length > 0 ? (
            <>
              <Divider />
              <Typography variant="h6">
                {packages.length + " " + t("amountOfPackets")}
              </Typography>
              <FixedSizeList
                height={listHeight}
                width={width - 32}
                itemSize={16 + 16 + 16 + 32 + 2 + 14 + 140}
                itemCount={packages.length}
                itemData={packages}
              >
                {Row}
              </FixedSizeList>
            </>
          ) : null}
        </Stack>
        <Stack
          direction="row"
          sx={{
            justifyContent: "space-between",
            padding: 2,
            paddingTop: 0,
          }}
        >
          <Button
            onClick={props.onClose}
            color="text"
            variant="outlined"
            startIcon={<MdClose />}
          >
            {t("close")}
          </Button>
          <LoadingButton
            disabled={packages.length === 0 || saving}
            variant="contained"
            loading={saving}
            loadingPosition="end"
            endIcon={<MdSave />}
            onClick={() => setSaving(true)}
            color="basic"
          >
            {t("import")}
          </LoadingButton>
        </Stack>
      </Box>
      <ConfirmDialog
        title={dialog.title}
        open={dialog.visible}
        setOpen={setDialogVisible}
        value={dialog.value}
        onConfirm={dialog.fn}
        hideSaveButton={dialog.hideSaveButton}
        cancelButtonTitle={dialog.cancelButtonTitle}
      >
        <Box sx={{ maxHeight: 400, overflowY: "auto" }}>
          <p className="pre-wrap">{dialog.text}</p>
        </Box>
      </ConfirmDialog>
    </>
  );
}
