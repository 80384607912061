import React, { useCallback, useState, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { Button } from "./StyledComponents";

import { FaTrashAlt } from "react-icons/fa";
import IconButton from "@mui/material/IconButton";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export default function StyledDropzone(props) {
  const { t } = useTranslation();
  const [myFiles, setMyFiles] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    setMyFiles(acceptedFiles);
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: props.acceptedFiles,
    multiple: false,
    maxFiles: props.maxFiles,
  });

  const removeFile = (file) => () => {
    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const files = myFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} {t("bytes")}
      <IconButton onClick={removeFile(file)}>
        <FaTrashAlt />
      </IconButton>
    </li>
  ));

  return (
    <div className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>{t("dragNdrop")}</p>
      </div>
      {files.length > 0 && (
        <>
          <aside>
            <h4>{t("file")}</h4>
            <ul>{files}</ul>
          </aside>

          {myFiles.length > 0 && (
            <Button onClick={() => props.uploadFiles(myFiles)}>
              {t("uploadFile")}
            </Button>
          )}
        </>
      )}
    </div>
  );
}
