import React from "react";
import Typography from "@mui/material/Typography";
import { FixedSizeList } from "react-window";

function Row({ data, index, style }) {
  const { getIcon, getName, onItemClick, rows } = data;
  const item = rows[index];

  return (
    <div
      style={style}
      className="node-row"
      onDoubleClick={() => onItemClick(item)}
    >
      <div className="text-padding-8">
        {getIcon && (
          <div className="node-icon">
            {getIcon(false, item.nodeType, item.type)}
          </div>
        )}
      </div>

      <div className="text-padding-8">{getName(item) || item.id}</div>
    </div>
  );
}

export default function VirtualizedList(props) {
  if (!Array.isArray(props.data) || props.data.length === 0) {
    return (
      <Typography style={{ margin: 10, fontSize: 16, lineHeight: "inherit" }}>
        {props.emptyDataString || "..."}
      </Typography>
    );
  } else {
    return (
      <FixedSizeList
        height={props.height}
        width={props.width}
        itemSize={50}
        itemCount={props.data.length}
        itemData={{
          rows: props.data,
          getName: props.getName,
          getIcon: props.getIcon,
          onItemClick: props.onItemClick,
        }}
      >
        {Row}
      </FixedSizeList>
    );
  }
}
