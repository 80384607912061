import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";

import useWindowDimensions from "../helpers/useWindowDimensions";
import { movePermissions } from "../helpers/constants";

import {
  FaTable,
  FaTrashAlt,
  FaBox,
  FaChevronDown,
  FaTools,
  FaDotCircle,
} from "react-icons/fa";
import { RiFolderAddFill } from "react-icons/ri/";

import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import Form from "./Form";
import DraggableDialog from "./DraggableDialog";
import Tooltip from "./Tooltip";
import NodeTree from "./NodeTree";
import ConfirmDialog from "./ConfirmDialog";
import NodeReplace from "./NodeReplace";

import { request } from "../helpers/api";
import { getChildren } from "../helpers/getChildren";

import { connect } from "react-redux";
import {
  CLEAR_TREE,
  SET_CHILDREN,
  DELETE_NODE,
  MOVE_NODE,
  ADD_NODES,
} from "../actions/TreeActions";
import { SET_REGISTRY_VERSIONS } from "../actions/VersionsActions";
import { SET_NODES, SET_PATHS, REPLACE_NODES } from "../actions/ItemsActions";

import { getName } from "../helpers/functions";
import { toast } from "react-toastify";
import PackageImporter from "./PackageImporter";

const newTableForm = {
  data: [
    {
      title: "newTable",
      rows: [
        [
          {
            key: "baseNode",
            label: "useTableAsBase",
            type: "autoComplete",
            error: "",
            validation: "",
            mask: "",
            fill: true,
            idProp: "id",
            searchParams: {
              nodeTypes: [6],
            },
          },
        ],
        [
          {
            required: true,
            key: "code",
            type: "textField",
            label: "newCode",
            error: "",
            validation: "",
            mask: "",
            fill: true,
          },
        ],
        [
          {
            key: "name",
            type: "textField",
            label: "name",
            error: "",
            validation: "",
            mask: "",
            fill: true,
          },
        ],
      ],
    },
  ],
};
const newPackageForm = {
  data: [
    {
      title: "newPackage",
      rows: [
        [
          {
            key: "baseNode",
            label: "usePackageAsBase",
            type: "autoComplete",
            error: "",
            validation: "",
            mask: "",
            fill: true,
            idProp: "id",
            searchParams: {
              nodeTypes: [2],
            },
          },
        ],
        [
          {
            required: true,
            key: "code",
            type: "textField",
            label: "newCode",
            error: "",
            validation: "",
            mask: "",
            fill: true,
          },
        ],
        [
          {
            key: "name",
            type: "textField",
            label: "name",
            error: "",
            validation: "",
            mask: "",
            fill: true,
          },
        ],
      ],
    },
  ],
};
const newFolderForm = {
  data: [
    {
      title: "newFolder",
      rows: [
        [
          {
            key: "code",
            type: "textField",
            label: "newCode",
            error: "",
            validation: "",
            mask: "",
            fill: true,
          },
        ],
        [
          {
            required: true,
            key: "name",
            type: "textField",
            label: "name",
            error: "",
            validation: "",
            mask: "",
            fill: true,
          },
        ],
      ],
    },
  ],
};
const newProductForm = {
  data: [
    {
      title: "newProduct",
      rows: [
        [
          {
            required: true,
            key: "code",
            type: "textField",
            label: "newCode",
            error: "",
            validation: "",
            mask: "",
            fill: true,
          },
        ],
        [
          {
            key: "name",
            type: "textField",
            label: "name",
            error: "",
            validation: "",
            mask: "",
            fill: true,
          },
        ],
      ],
    },
  ],
};
const newWorkForm = {
  data: [
    {
      title: "newJob",
      rows: [
        [
          {
            required: true,
            key: "code",
            type: "textField",
            label: "newCode",
            error: "",
            validation: "",
            mask: "",
            fill: true,
          },
        ],
        [
          {
            key: "name",
            type: "textField",
            label: "name",
            error: "",
            validation: "",
            mask: "",
            fill: true,
          },
        ],
      ],
    },
  ],
};
const initialRequesting = { loading: null, params: null };

// TODO moving node in global search page doesn't work, missing root
/**
 * EditTreeControls component.
 *
 * Renders controls for editing tree nodes; adding, removing, moving and replacing.
 * @component
 * @param {Object} props props for the component
 * @param {Function} props.t - translation function
 * @param {Object} props.node selected node to edit
 * @param {Object} props.tree - tree from redux
 * @param {String} props.registryVersion - registryVersion from redux
 * @param {String} props.treeRoot - tree root e.g. "packets"
 * @param {Function} props.setNode - function called on node add
 * @param {Function} props.onDelete - function called on node deletion
 * @param {Function} props.onNodeMoved - function called when node was moved
 * @param {Function} props.SET_CHILDREN - redux action to set tree children
 * @param {Function} props.SET_REGISTRY_VERSIONS - redux action to setting versions
 * @param {Function} props.ADD_NODES - redux action for adding nodes
 * @param {Function} props.MOVE_NODE - redux action for moving a node
 * @param {Function} props.DELETE_NODE - redux action for deleting a node
 * @returns JSX
 */
function EditTreeControls(props) {
  const { t } = useTranslation();
  const [requesting, setRequesting] = useState(initialRequesting);
  const { windowHeight, windowWidth } = useWindowDimensions();
  const [targetNode, setTargetNode] = useState(null);
  const [open, setOpen] = useState(false);
  const [formDialog, setFormDialog] = useState({ open: false });
  const [dialog, setDialog] = useState({
    visible: false,
  });
  const [packageImporter, setPackageImporter] = useState({
    visible: false,
  });
  const [replaceModal, setReplaceModal] = useState({
    visible: false,
  });

  const setDialogVisible = (visible) => {
    setDialog((_dialog) => ({
      ..._dialog,
      visible,
    }));
  };

  const [dimensions, setDimensions] = useState({
    height: windowHeight - 128,
    width: windowWidth - 128,
  });

  const fetchNodeChildren = async (path, items) => {
    await getChildren(
      props.SET_CHILDREN,
      props.useRootPath,
      props.SET_NODES,
      0,
      path,
      props.reduxRoot,
      props.treeRoot,
      items,
      props.productsMap,
      props.nodesMap,
      undefined,
      props.registryVersion,
      undefined
    );
  };

  useEffect(() => {
    if (requesting.loading === "new") {
      let closeDialog = true;
      request(
        "nodes",
        "post",
        {
          ...requesting.params,
          baseNode: requesting.params.baseNode
            ? requesting.params.baseNode.id
            : undefined,
          root: props.treeRoot,
          targetId: props.node?.id,
        },
        { registryVersion: props.registryVersion }
      )
        .then(async (res) => {
          if (res.status === 200) {
            await fetchNodeChildren(res.data.path);

            // props.ADD_NODES({
            //   nodes: [res.data],
            //   root: props.reduxRoot,
            //   registryVersion: props.registryVersion,
            //   target: props.node,
            // });
            setRequesting(initialRequesting);
            toast.success(t("createSuccessful"));
            props.setNode && props.setNode(res.data);
          } else {
            if (
              res?.response?.data?.displayMessage === "Id/code already on use"
            ) {
              closeDialog = false;
            }
          }
        })
        .finally(() => {
          setRequesting(initialRequesting);
          setFormDialog((cur) => ({ ...cur, loading: false }));
          if (closeDialog) {
            setFormDialog({
              open: false,
            });
          }
        });
    } else if (requesting.loading === "delete") {
      request("nodes/" + encodeURIComponent(props.node.id), "delete", null, {
        registryVersion: props.registryVersion,
        root: props.treeRoot,
      })
        .then(async (res) => {
          // folder should return 409 if it still has children => message: You need to delete related childrens first
          // package/product/job should return 409 if it is referenced in table cells or some package => message: Node still has relations
          if (res.status === 204) {
            // TODO should delete node delete the node not only from tree but also from nodes maps
            await fetchNodeChildren(props.node.path);

            // props.DELETE_NODE({
            //   root: props.reduxRoot,
            //   registryVersion: props.registryVersion,
            //   node: props.node,
            // });
            setRequesting(initialRequesting);
            props.onDelete(props.node);
            toast.success(t("deleteSuccessful"));
          }
        })
        .finally(() => {
          setRequesting(initialRequesting);
          setDialog({
            visible: false,
          });
        });
    } else if (requesting.loading === "move") {
      // TODO handle path changing for Forms path view
      // should work when backend returns the changed path on node fetch
      request(
        "nodes/move",
        "put",
        {
          targetId: requesting.params.moveToRoot ? null : targetNode.id,
          id: props.node.id,
          root: props.treeRoot,
        },
        { registryVersion: props.registryVersion }
      )
        .then(async (res) => {
          if (res.status === 200) {
            // fetch original parents children
            await fetchNodeChildren(props.node.path);
            // fetch destinations children
            await fetchNodeChildren(res.data);

            // props.MOVE_NODE({
            //   root: props.reduxRoot,
            //   registryVersion: props.registryVersion,
            //   node: props.node,
            //   target: requesting.params.moveToRoot ? null : targetNode,
            // });
            if (props.onNodeMoved) {
              setRequesting(initialRequesting);
              props.onNodeMoved(res.data);
            }
            toast.success(t("moveSuccessful"));
          }
        })
        .finally(() => {
          setRequesting(initialRequesting);
          setOpen(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, requesting, props.node, props.reduxRoot, props.registryVersion]);

  useEffect(() => {
    setDimensions({
      height: windowHeight - 128,
      width: windowWidth - 128,
    });
  }, [windowWidth, windowHeight]);

  const _setTargetNode = useCallback(
    (node) => {
      if (
        (node.nodeType === 1 || node.nodeType === 4) &&
        node.id !== props.node.id
      ) {
        setTargetNode(node);
      } else {
        setTargetNode(null);
      }
    },
    [props.node]
  );

  const tree = useMemo(
    () => (
      <NodeTree
        search={true}
        width={dimensions.width - 2 - 48}
        height={dimensions.height - 2 - 72 - 80 - 40 - 20 - 20}
        treeRoot={props.treeRoot}
        reduxRoot={props.treeRoot + "_move"}
        nodeIdPrefix={"move"}
        setSelectedNode={_setTargetNode}
        registryVersion={props.registryVersion}
        editable
        disableSettings
        disableURLHandling
      />
    ),
    [_setTargetNode, dimensions, props.treeRoot, props.registryVersion]
  );

  const handleClickOpen = () => {
    props.CLEAR_TREE({
      root: props.treeRoot + "_move",
      registryVersion: props.registryVersion,
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFormDialogClose = (_loading) => () => {
    if (!_loading) {
      setFormDialog({
        open: false,
      });
    }
  };

  // TODO autofocus first form field
  const handleNewNode = (nodeType, itemType) => () => {
    // itemType needed when adding work node, otherwise don't send itemType
    setFormDialog({
      open: true,
      form:
        nodeType === 1 || nodeType === 4
          ? newFolderForm
          : nodeType === 2
          ? newPackageForm
          : nodeType === 6
          ? newTableForm
          : itemType === 13
          ? newWorkForm
          : nodeType === 5
          ? newProductForm
          : newProductForm,
      handleSubmit: (values) => {
        setRequesting({
          loading: "new",
          params: { ...values, nodeType, itemType },
        });
        setFormDialog((cur) => ({ ...cur, loading: true }));
      },
    });
  };
  const handleDelete = () => {
    setDialog({
      title: t("alert"),
      text: t("deleteConfirmation") + " " + getName(props.node, props.treeRoot),
      visible: true,
      value: props.node.id,
      loadingProp: "delete",
      fn: () => setRequesting({ loading: "delete" }),
    });
  };
  const handleMove = (moveToRoot) => () => {
    setRequesting({ loading: "move", params: { moveToRoot } });
  };

  // TODO Table kopiointi, pitäisi toimia jos käyttää vain baseNodena toista tablea
  const nodeId = props.node?.id;
  const isRootNode = props.node?.isRootNode;
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ButtonGroup
        variant="contained"
        color="basic"
        aria-label="outlined button group"
        fullWidth
      >
        {nodeId &&
          props.treeRoot &&
          props.treeRoot !== "nomenclatures" &&
          props.node &&
          props.node?.nodeType === 1 && (
            <Button onClick={handleNewNode(1)} endIcon={<RiFolderAddFill />}>
              {t("newSubFolder")}
            </Button>
          )}
        {nodeId &&
          props.treeRoot &&
          ((props.treeRoot === "nomenclatures" &&
            props.node &&
            props.node.nodeType !== 5) ||
            props.node?.nodeType === 4) && (
            <Button onClick={handleNewNode(4)} endIcon={<RiFolderAddFill />}>
              {t("newSubFolder")}
            </Button>
          )}
        {nodeId &&
          props.treeRoot &&
          props.treeRoot === "packets" &&
          props.node?.nodeType === 1 && (
            <>
              <Button onClick={handleNewNode(2)} endIcon={<FaBox />}>
                {t("newPackage")}
              </Button>
              <Button
                onClick={() => setPackageImporter({ visible: true })}
                endIcon={<FaBox />}
              >
                {t("importPackages")}
              </Button>
            </>
          )}
        {nodeId &&
          props.treeRoot &&
          props.treeRoot === "tables" &&
          props.node?.nodeType === 1 && (
            <Button onClick={handleNewNode(6)} endIcon={<FaTable />}>
              {t("newTable")}
            </Button>
          )}
        {nodeId && props.treeRoot && props.node?.nodeType === 4 && (
          <Button onClick={handleNewNode(5, 13)} endIcon={<FaTools />}>
            {t("newJob")}
          </Button>
        )}
        {nodeId &&
          props.treeRoot &&
          props.treeRoot === "products" &&
          props.node?.nodeType === 1 && (
            <Button onClick={handleNewNode(5)} endIcon={<FaDotCircle />}>
              {t("newProduct")}
            </Button>
          )}
        {nodeId &&
          props.treeRoot &&
          props.node &&
          (props.node.nodeType !== 5 || props.treeRoot === "nomenclatures") &&
          !isRootNode && <Button onClick={handleClickOpen}>{t("move")}</Button>}
        {!nodeId ||
        !props.node ||
        isRootNode ||
        props.node?.nodeType === 5 ? null : (
          <Button onClick={handleDelete} endIcon={<FaTrashAlt />} color="error">
            {t("delete")}
          </Button>
        )}
        {(props.node?.nodeType === 5 ||
          props.node?.nodeType === 2 ||
          (props.node?.code && !props.node.nodeType)) && (
          <Button onClick={() => setReplaceModal({ visible: true })}>
            {t("replace")}
          </Button>
        )}
      </ButtonGroup>

      {props.node && (
        <DraggableDialog
          open={open}
          handleClose={handleClose}
          height={dimensions.height}
          width={dimensions.width}
          setDimensions={setDimensions}
          title={t("move")}
        >
          <>
            <Box
              sx={{
                height: 80,
                justifyContent: "space-around",
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textAlign: "left",
              }}
            >
              <>
                <Tooltip tip={getName(props.node, props.treeRoot)}>
                  <Typography variant="h6">
                    {t("nodeToMove") +
                      ": " +
                      getName(props.node, props.treeRoot)}
                  </Typography>
                </Tooltip>

                <Box sx={{ display: "flex", alignSelf: "center" }}>
                  <FaChevronDown size={"16px"} />
                </Box>

                <Tooltip
                  tip={targetNode ? getName(targetNode, props.treeRoot) : "-"}
                >
                  <Typography variant="h6">
                    {t("target") +
                      ": " +
                      (targetNode ? getName(targetNode, props.treeRoot) : "-")}
                  </Typography>
                </Tooltip>
                {open && <div className="tooltip" />}
              </>
            </Box>

            <Box
              sx={{
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: "#000",
              }}
            >
              {tree}
            </Box>

            <Box
              sx={{
                paddingTop: "20px",
                height: 40,
                justifyContent: "space-around",
                display: "flex",
              }}
            >
              {/* <Button onClick={handleMove(true)} variant="contained">
                {t("moveToRoot")}
              </Button> */}
              <Button
                disabled={!targetNode}
                onClick={handleMove(false)}
                variant="contained"
              >
                {t("moveToSelected")}
              </Button>
            </Box>
          </>
        </DraggableDialog>
      )}

      <Modal
        open={formDialog.open}
        onClose={handleFormDialogClose(formDialog.loading)}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Box
          sx={{
            maxWidth: 700,
            // marginLeft: "auto",
            // marginTop: "auto",
            // marginBottom: "auto",
          }}
        >
          <Form
            {...formDialog}
            saving={formDialog.loading}
            onClose={handleFormDialogClose(formDialog.loading)}
            t={t}
            onSave={formDialog.handleSubmit}
            editable
            disablePaths
            disableJSON
            disableEditButtons
          />
        </Box>
      </Modal>

      <ConfirmDialog
        title={dialog.title}
        open={dialog.visible}
        setOpen={setDialogVisible}
        value={dialog.value}
        onConfirm={dialog.fn}
        loading={request.loading === dialog.loadingProp}
      >
        {dialog.text}
      </ConfirmDialog>

      <NodeReplace
        open={replaceModal.visible}
        node={props.node}
        root={props.treeRoot}
        registryVersion={props.registryVersion}
        onClose={() => setReplaceModal({ visible: false })}
        REPLACE_NODES={props.REPLACE_NODES}
        SET_PATHS={props.SET_PATHS}
      />

      <PackageImporter
        open={packageImporter.visible}
        node={props.node}
        registryVersion={props.registryVersion}
        onClose={() => setPackageImporter({ visible: false })}
        reduxRoot={props.reduxRoot}
        ADD_NODES={props.ADD_NODES}
      />
    </Box>
  );
}

const mapStateToProps = (state) => {
  return {
    registryVersion: localStorage.getItem("registryVersion"),
  };
};

export default connect(mapStateToProps, {
  CLEAR_TREE,
  SET_CHILDREN,
  SET_REGISTRY_VERSIONS,
  REPLACE_NODES,
  DELETE_NODE,
  MOVE_NODE,
  ADD_NODES,
  SET_PATHS,
  SET_NODES,
})(EditTreeControls);
